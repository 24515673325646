import { Language } from "./other";
import { Base } from "./base";
import { ErAmenity, ErFactype } from "./facility";

export class ListOfValues {
  Amenities: ErAmenity;
  CMSpecialties: Specialty[];
  Commtypes: ErCommtype[];
  Contacttypes: ErContacttype[];
  Factypes: ErFactype;
  Languages: Language[];
  Notetypes: ErNotetype[];
  Roomtypes: ErRoomtype;
  Tasktypes: ErTasktype[];
  Timezones: Timezone[];
  OfferingCategories: AHIOfferingCategory[];
  OfferingSubcategories: AHIOfferingSubcategory[];
  Config: ErStaticListOfValuesConfig;
}

export class Specialty extends Base {
  Name: string;
}

export class ErCommtype extends Base {
  Name: string;
  Description: string;
}

export class ErContacttype extends Base {
  Name: string;
  Description: string;
  IsDoctor: boolean;
}

export class ErNotetype extends Base {
  Name: string;
  Description: string;
  IsMultiline: boolean;
}

export class ErTasktype extends Base {
  Name: string;
  Description: string;
}

export class EoLanguage extends Base {
  LanguageCode: string;
  LanguageName: string;
  Code: string;
  Name: string;
}

export class ErRoomtype extends Base {
  Name: string;
  Description: string;
}

export class Timezone {
  BaseUtcOffset: BaseUtcOffset;
  DaylightName: string;
  DisplayName: string;
  Id: string;
  StandardName: string;
  SupportsDaylightSavingTime: boolean;
}

export class BaseUtcOffset {
  Days: number;
  Hours: number;
  Milliseconds: number;
  Minutes: number;
  Seconds: number;
  Ticks: number;
  TotalDays: number;
  TotalHours: number;
  TotalMilliseconds: number;
  TotalMinutes: number;
  TotalSeconds: number;
}


export class AHIOfferingCategory extends Base {
  Name: string;
  DisplayOrder: number;
  IsAHI: boolean;
  IsCMD: boolean;
  IsNP: boolean;
  IsPI: boolean;
}

export class AHIOfferingSubcategory extends Base {
  Name: string;
  AHIOfferingCategoryId: number;
}




export class ErStaticListOfValuesConfig extends Base {
  CmdOfferingCategoryId: number;
  OrganizationIdUnclaimedBusiness: number;
}
