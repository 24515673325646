import { Base } from "./base";
import { ErEvent } from "./event";
import { ErCity, ErCounty, ErState, ErZipcode } from "./facility";
import { AHIOfferingSubcategory } from "./global";
import { ErImage } from "./image";


export class AHICompany extends Base {
  EoOrganizationId: number;

  Name: string;
  Email: string;
  AdminName: string;
  PhoneNumber: string;
  Website: string;
  Description: string;
  InternalNotes: string;
  ReviewsAverage: number;

  Address: string;
  BizZipcode: ErZipcode;
  BizCity: ErCity;
  BizCounty: ErCounty;
  BizState: ErState;

  City: ErCity;
  County: ErCounty;
  State: ErState;
  OfferingSubcategories: AHIOfferingSubcategory[];

  ImageGuid: string;
  Images: ErImage[];

  Offerings: AHIOffering[];

  Reviews: AHICompanyReview[];
  Events: ErEvent[];
}


export class AHICompanyReview extends Base {

  constructor(obj: AHICompanyReview = null) {
    super(obj);
    if (!obj) return;
    if (obj.Image)
      obj.Image = new ErImage(obj.Image)
  }

  AHICompanyId: number;
  PublishedAt: Date;
  PublishedBy: string;
  Stars: number;
  Description: string;

  // Avatar
  Image: ErImage;
}

export class AHIOffering extends Base {
  AHICompanyId: number;
  Name: string;
  Description: string;
  OfferingSubcategories: AHIOfferingSubcategory[];
  Cities: ErCity[];

  Images: ErImage[];
}
