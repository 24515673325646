import { environment } from "../../../environments/environment";
import { Base } from "./base";

export class ErImage extends Base {
  
  ImageGuid: string;
  Name: string;
  Description: string;
  Width: number;
  Height: number;
  Size: number;
  Type: EoImageType;

  get ImageUrl(): string {
    //return environment.baseUrlCare + "/api/content/image/" + this.ImageGuid;
    return ErImage.ImageUrl(this.ImageGuid);
  }

  public static ImageUrl(guid: string): string {
    return environment.baseUrlCare + "/api/content/image/" + guid;
  }
}

export enum EoImageType {
  JPG = "JPG",
  PNG = "PNG",
  GIF = "GIF"
}

export namespace EoImageType {
  export function keys() {
    return Object.keys(EoImageType).filter(
      (key) => isNaN(<any>key) && key !== 'keys'
    );
  }
}
