import { first } from "rxjs/operators";
import { ErSite } from "../../predefined/enums";
import { Base } from "../base";
import { ErCity, ErCounty, ErState, ErZipcode } from "../facility";
import { ErImage } from "../image";
import { ErFloor, ErPayInterval, ErScheduleType, ErUrgency, ErWantadType } from "./ErJobad";

export class ErWantad extends Base {

  constructor(obj: ErWantad = null) {
    super(obj);

    if (!obj) return;

    if (obj.Zipcode) this.Zipcode = new ErZipcode(obj.Zipcode);
    if (obj.City) this.City = new ErCity(obj.City);
    if (obj.County) this.County = new ErCounty(obj.County);
    if (obj.State) this.State = new ErState(obj.State);

    if (obj.Images) {
      this.Images = [];
      obj.Images.forEach(x => { this.Images.push(new ErImage(x)); });
    }
  }

  EoOrganizationId: number;

  Name: string;

  Type: ErWantadType

  Description: string;

  DescriptionShort: string;

  IsRestrictedToFacilities: boolean;

  EmailAddress: string;

  PhoneNumber: string;

  PriceMonthly: number;

  ZipCodeStr: string;

  ErZipcodeId: number;

  Floors: ErFloor[];

  IsAmbulatory: boolean;

  IsCatsOkay: boolean;

  IsDogsOkay: boolean;

  IsFurnished: boolean;

  IsNoSmoking: boolean;

  IsWheelchairAccessible: boolean;

  IsAirConditioned: boolean;

  ScheduleTypes: ErScheduleType[];

  HiresCount: number;

  Urgency: ErUrgency;

  PayMinimum: number;

  PayMaximum: number;

  PayInterval: ErPayInterval;

  Sites: ErSite[];



  //Computed properties
  ImageGuid: string;

  get ImageUrl(): string { return ErImage.ImageUrl(this.ImageGuid); }

  Images: ErImage[];

  Zipcode: ErZipcode;
  City: ErCity;
  County: ErCounty;
  State: ErState;

  get FullAddress() {
    const city = this.City ? this.City.Name : "";
    const county = this.County ? ", " + this.County.Name : "";
    const state = this.State ? ", " + this.State.Code : "";
    const zipcode = this.Zipcode ? ", " + this.Zipcode.Code : "";
    return city + county + state+zipcode;
  }

  get ScheduleTypesStr(): string[] {
    if (!this.ScheduleTypes) return null;
    let strs = [];
    this.ScheduleTypes.forEach(x => strs.push(x.replace(/_/g, " ")));
    return strs;
  }

  get UrgencyStr(): string {
    if (!this.Urgency) return "";
    return this.Urgency.replace(/_/g, " ");
  }

}


