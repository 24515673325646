import { Component, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CareManager } from 'app/_core/models';
import { CareManagerService } from 'app/_core/api-access/caremanager.service';
import { MediaService } from 'app/_core/api-access/media.service';

@Component({
  selector: 'app-caremanager-related',
  templateUrl: './caremanager-related.component.html'
})
export class CareManagerRelatedComponent extends BaseActComponent implements OnInit {

  public readonly careManagers$: Observable<CareManager[]>;

  constructor(
    private careManagerService: CareManagerService,
    private route: ActivatedRoute,
    private mediaService: MediaService,) {
    super();

    // Subscribe to entire collection
    this.careManagers$ = this.careManagerService.careManagersObs;
    // Page info
  }

  ngOnInit(): void {
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }
}
