import { Base } from "./base";
import { ErEvent } from "./event";
import { ErRoomtype } from "./global";
import { ErImage } from "./image";
import { Language } from "./other";

export class Facility extends Base {
  ErZipcodeId: number;
  ErFactypeId: number;
  Name: string;
  GovNumber: string;
  LicenseeName: string;
  AdminName: string;
  PhoneNumber: string;
  Address: string;
  ZipCodeStr: string;
  Capacity: number;
  LicenseStartDate: string;
  Sqft: number;
  FloorCount: number;
  HasDetails: boolean;
  PriceLow: number;
  PriceHigh: number;
  RoomCountTotal: number;
  RoomCountAvailable: number;
  RatingAverage: number;
  RatingCount: number;
  ImageGuid: string;
  Distance: number;
  ErRoomtypeIds: string;
  EoLanguageIds: string;
  GooglePlaceId: string;
  Distances: ErDistance;
  Amenities: ErAmenity[];
  AmenitiesFeatured: ErAmenity[]
  Details: ErFacilityDetail;
  Images: ErImage[];
  Rooms: ErFacilityRoom;
  Factype: ErFactype;
  Zipcode: ErZipcode;
  City: ErCity;
  County: ErCounty;
  State: ErState;
  Roomtypes: ErRoomtype[];
  Languages: Language[];
  GoogleMapsLink: string;
  Youtubevids: ErFacilityYoutubevid[];
  Events: ErEvent[];
  Reviews: ErFacilityReview[];
  Website: string;
  ReviewsAverage: number;
}

export class ErFacilityReview extends Base {

  constructor(obj: ErFacilityReview = null) {
    super(obj);
    if (!obj) return;
    if (obj.Image)
      obj.Image = new ErImage(obj.Image)
  }
  ErFacilityId: number;
  PublishedAt: Date;
  PublishedBy: string;
  Stars: number;
  Description: string;

  // Avatar
  Image: ErImage;
}

export class ErFacilityYoutubevid extends Base {
  ErFacilityId: number;
  YoutubeVideoId: string;
  DisplayIndex: number;
  YoutubeEmbedUrl: string;
  YoutubeMainUrl: string;
}

export class ErZipcode extends Base {
  ErCityId: number;
  Code: number;
  Latitude: number;
  Longitude: number;
  Population: number;
  Density: number;
  Name: string;
  FacilityCount: number;
  City: ErCity;

  // display only
  StateCode: string;
  StateName: string;
}

export class ErCity extends Base {
  Name: string;
  ErCountyId: number;
  FacilityCount: number;
  County: ErCounty;

  // display only
  StateCode: string;
  StateName: string;
}

export class ErCounty extends Base {
  Name: string;
  ErStateId: number;
  FacilityCount: number;
  State: ErState;

  // display only
  StateCode: string;
  StateName: string;
}

export class ErState extends Base {
  Code: string;
  Name: string;
  FacilityCount: number;
}

export class ErFactype extends Base {
  Name: string;
  Description: string;
}

export class ErFacilityRoom extends Base {
  ErFacilityId: number;
  ErRoomtypeId: number;
  Name: string;
  Description: string;
  MonthlyPrice: number;
  BedCount: number;
  Sqft: number;
  FloorNumber: number;
  IsAvailable: boolean;
  Amenities: ErAmenity[];
  AmenitiesFeatured: ErAmenity[];
  Images: ErImage[];
  Roomtype: ErRoomtype;
}




export class ErFacilityDetail extends Base {
  ErFacilityId: number;
  Description: string;
  DescMedical: string;
  DescPharmacy: string;
  DescSupermarket: string;
  DescPark: string;
  DescService: string;
  DescRules: string;
}

export class ErAmenity extends Base {
  Name: string;
  Description: string;
  FaCode: string;
}

export class ErDistance {
  Meters: number;
  Kilometers: number;
  Miles: number;
  Feet: number;
  Yards: number;
}


