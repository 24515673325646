import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-boolean',
  template: `
<span *ngIf="!useSymbols" [ngStyle]="{'color':getColour()}">{{getText()}}</span>

<span *ngIf="useSymbols" class = "status-icon" [ngStyle]="{'color':getColour()}"><span [innerHTML]="getSymbol()"></span></span>
  `,
})
export class BooleanComponent implements OnInit {

  @Input() val: boolean;
  @Input() useColours: boolean = true; //Whether or not to colour the text or useSymbols
  @Input() useSymbols: boolean = false; //Whether or not to use text "Yes/No" or useSymbols
  @Input() invertColours: boolean = false; //Whether or not to swap the true and false colours

  //public text: string;
  //public colour: string = "unset";
  //public symbol: string;

  private colourTrue: string = "rgb(19,186,0)";
  private colourFalse: string = "#6b727d";

  constructor() { }

  ngOnInit() {
    /*if (this.val) {
      this.text = "Yes";
      this.symbol = `<i class="fas fa-check"></i>`;
      if (this.useColours)
        this.colour = !this.invertColours ? this.colourTrue : this.colourFalse;
    } else {
      this.text = "No";
      this.symbol = `<i class="far fa-times-circle"></i>`;
      if (this.useColours)
        this.colour = !this.invertColours ? this.colourFalse : this.colourTrue;
    }*/
  }

  getText(): string {
    if (this.val)
      return "Yes";
    else
      return "No";
  }

  getSymbol(): string {
    if (this.val)
      return `<i class="fas fa-check"></i>`;
    else
      return `<i class="far fa-times-circle"></i>`;
  }

  getColour(): string {
    if (this.useColours)
      if (this.val)
        return !this.invertColours ? this.colourTrue : this.colourFalse;
      else
        return !this.invertColours ? this.colourFalse : this.colourTrue;
  }


}
