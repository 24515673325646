import { Injectable } from '@angular/core'
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'

// RxJS
import { BehaviorSubject, Observable, of } from 'rxjs'
import { BaseService } from './base.service'

// Models
import { AHICompany, AHIOffering } from '../models/company'
import { tap } from 'rxjs/operators'
import { CompanyFilter, PageInfo, Pagination } from '../models'
import { MiscService } from '../utilities/misc.service'

@Injectable({
  providedIn: 'root',
})
export class OfferingService extends BaseService {

  private companiesSubject = new BehaviorSubject<AHICompany[]>(null);
  private companiesData: { data: AHICompany[] } = { data: [] };
  public readonly offeringsObs = this.companiesSubject.asObservable();

  // Page info
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private pageInfoData: { data: PageInfo } = { data: null };
  public readonly pageInfoObs = this.pageInfoSubject.asObservable();

  constructor(
    private http: HttpClient,
    private miscService: MiscService,
  ) {
    super()
  }

  setPageObs(pageInfo: PageInfo) {
    this.pageInfoData.data = pageInfo;
    this.pageInfoSubject.next(Object.assign({}, this.pageInfoData).data);
  }

  setDataObs(data: AHICompany[] = []) {
    this.companiesData.data = data;
    this.companiesSubject.next(Object.assign({}, this.companiesData).data);
  }

  searchGeos(term: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('query', term);

    const apiUrl = this.baseUrlCare + `/search/geos`;
    return this.http.get<any>(apiUrl, { params: httpParams,withCredentials:true });
  }

  // Load data & populate
  load(offset: number = null, limit: number = null, filter: CompanyFilter) {
    this.getList(offset, limit, filter).subscribe();
  }
  
  loadCompanies(
    filter: CompanyFilter,
    pagination: Pagination,
  ): Observable<HttpResponse<AHICompany[]>> {
      const offset = pagination.offset;
      const limit = pagination.limit;

      const name = filter.Name || '';
      const geoId = filter.GeoId || 0;
      const geoType = filter.GeoType || "county";
      const distmi = filter.Distmi || 25;
      const categoryId = filter.CategoryId;
      const subcategoryId = filter.SubcategoryId;
  
      // Declare
      let httpParams = new HttpParams()
        .set('name', `${name}`)
        .set('categoryId', `${categoryId}`)
        .set('id', `${geoId}`)
        .set('geotype', `${geoType}`)
        .set('distmi', `${distmi}`)
        .set('subcategoryId', `${subcategoryId}`);
  
      // Pagination params: offset, limit
      if (offset) httpParams = httpParams.set('offset', `${offset}`);
  
      // Limit is Page size, items per page
      if (limit) httpParams = httpParams.set('limit', `${limit}`);
  
      const apiUrl = !filter ? this.baseUrlCare + `/public/ahi/companies` : this.baseUrlCare + `/search/ahicompanies`;
      
      return this.http.get<AHICompany[]>(apiUrl, { withCredentials: true, params: httpParams, observe: 'response' });
  }

  getList(offset: number = null, limit: number = null, filter: CompanyFilter) {
    const name = filter.Name || '';
    const geoId = filter.GeoId || 0;
    const geoType = filter.GeoType || "county";
    const distmi = filter.Distmi || 25;
    const categoryId = filter.CategoryId;
    const subcategoryId = filter.SubcategoryId;

    // Declare
    let httpParams = new HttpParams()
      .set('name', `${name}`)
      .set('categoryId', `${categoryId}`)
      .set('id', `${geoId}`)
      .set('geotype', `${geoType}`)
      .set('distmi', `${distmi}`)
      .set('subcategoryId', `${subcategoryId}`);

    // Pagination params: offset, limit
    if (offset) httpParams = httpParams.set('offset', `${offset}`);

    // Limit is Page size, items per page
    if (limit) httpParams = httpParams.set('limit', `${limit}`);

    const apiUrl = !filter ? this.baseUrlCare + `/public/ahi/companies` : this.baseUrlCare + `/search/ahicompanies`;
    
    return this.http.get<AHICompany[]>(apiUrl, { withCredentials: true, params: httpParams, observe: 'response' }).pipe(tap(response => {
      const totalRecords = +response.headers.get("Total-Records") || 0;
      const companies: AHICompany[] = response.body || [];
      const pageNumber = offset / limit + 1;
      console.log("totalrecords:" + totalRecords);
      const pageInfo: PageInfo = this.miscService.getPager(totalRecords, pageNumber, limit);

      this.setDataObs(companies);
      this.setPageObs(pageInfo);

      return response;
    }));
  }

  getById(id: number): Observable<AHICompany> {
    let params: HttpParams = new HttpParams()
      .set("viewType", "AutismHelpInfo");
    const apiUrl = this.baseUrlCare + `/public/ahi/company/${id}`;
    return this.http.get<AHICompany>(apiUrl, { params: params, withCredentials: true });
  }
}
