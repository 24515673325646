import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { BaseService } from './base.service'
import { Inquiry } from '../models'
import { tap } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'
import { ErEvent, ErEvents } from '../models/event'
import { ErEventRegistrant } from '../models/event-details/eventRegistrant'
import { ErSite } from '../predefined/enums'

@Injectable({
    providedIn: 'root',
})
export class EventService extends BaseService {

    constructor(private http: HttpClient) {
        super()
    }


    getEvent(eventId: number): Observable<ErEvent> {
        let params = new HttpParams()
            .set("site", ErSite.CareMomDad);
        const apiUrl = this.baseUrlCare + `/Event/${eventId}`;
        return this.http.get<ErEvent>(apiUrl, { params: params,withCredentials:true });
    }

    getEventsUpcoming(): Observable<ErEvents> {
        let params = new HttpParams()
            .set("isCMD", "true")
            .set("site", ErSite.CareMomDad);
        const apiUrl = this.baseUrlCare + `/Events/public`;
        return this.http.get<ErEvents>(apiUrl, { params: params, withCredentials: true});
    }

    sendRegistrant(registrant: ErEventRegistrant): Observable<ErEventRegistrant> {
        const apiUrl = this.baseUrlCare + `/Event/${registrant.ErEventId}/Registrant`;
        return this.http.post<ErEventRegistrant>(apiUrl, registrant, { withCredentials: true }).pipe(tap(obj => {

        }));
    }

}
