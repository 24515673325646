import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { NumberFormatPipe } from 'app/_core/pipes/number-format.pipe';
import { ToastService } from 'app/_core/utilities/toast.service';

@Component({
  selector: 'app-input-number',
  template: `
  <div class="custom-input-number" [ngClass]="{'disabled' : disabled}">
    <input
      #inputNumber
      type="number"
      [min]="min" [max]="max" [step]="jumpVal"
      class="form-control small-field"
      [ngModel]="value"
      (focusout)="onBlur()"
      (ngModelChange)="onValueChange($event)"/>
    <span tabindex="0" class="btn-number up-number" (focusout)="onBlur()" (click)="onUp()" ><i class="fas fa-plus"></i></span>
    <span tabindex="0" class="btn-number down-number" (focusout)="onBlur()" (click)="onDown()" ><i class="fas fa-minus"></i></span>
  </div>
  `,
})
export class InputNumberComponent implements OnChanges, AfterViewInit {

  @ViewChild('inputNumber', { static: false }) inputNumber: ElementRef;

  public value: number;
  @Input() numberValue: number;
  @Input() idRow: any;
  @Input() min: number = 0;
  @Input() max: number;
  @Input() disabled: boolean;
  @Input() jumpVal: number;
  @Input() updateImmediately: boolean;
  @Output() onChange = new EventEmitter<any>();

  @Input() format: string;

  constructor(
    private toastService: ToastService,
    private numberFormatPipe: NumberFormatPipe,
  ) { }

  ngOnChanges() {
    if (this.inputNumber)
      this.formatValue(this.numberValue);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.inputNumber)
        this.formatValue(this.numberValue);
    });
  }

  onBlur() {
    this.onChange.emit({
      value: this.value,
      idRow: this.idRow
    });
  }

  onValueChange(value: number) {
    setTimeout(() => {
      this.formatValue(value);

      // console.log('this.value :>> ', this.value);
      if (this.updateImmediately)
        this.onBlur();
    });
  }

  onUp() {
    // Check null or undefined
    if (!this.value)
      this.value = 0;

    if (this.max && this.value < this.max || !this.max) {
      this.value += this.jumpVal;
      // Reset value to input
      this.inputNumber.nativeElement.value = this.value;

      if (this.updateImmediately)
        this.onBlur();
    }
    else
      this.toastService.showAlert("Value must be less than or equal to " + this.max);
  }

  onDown() {
    // Check null or undefined
    if (!this.value)
      this.value = 0;

    if ((this.min || this.min === 0) && this.value > this.min || !this.min && this.min !== 0) {
      this.value -= this.jumpVal;
      // Reset value to input
      this.inputNumber.nativeElement.value = this.value;

      if (this.updateImmediately)
        this.onBlur();
    }
    else
      this.toastService.showAlert("Value must be greater than " + this.min);
  }


  private formatValue(value: number) {

    this.value = value;

    if(this.format) {
      // console.log('value :>> ', value);
      // Change value to format
      const regexReplace = /\,/gi;
      const valueFormat = this.numberFormatPipe.transform(value, this.format);
      // console.log('valueFormat :>> ', );
      this.value = +(valueFormat.replace(regexReplace, ""));
    };

    // Reset value to input
    this.inputNumber.nativeElement.value = this.value;
  }
}
