import { Base } from "./base";
import { ErEventQuestion } from "./event-details/eventQuestion";
import { ErImage } from "./image";
import { ErCareObjType } from "./other";

export class ErEvents{
  Upcoming: ErEvent[];
  Past:     ErEvent[];
}

export class ErEvent extends Base {

  EoOrganizationId: number;

  Name: string;

  //ErFacilityId: number;
  ErObjectType: ErCareObjType;

  ErObjectId: number;

  ErObjectName: string;

  ErEventtypeId: number;

  Description: string;

  StartedAt: Date;

  Timezone: string;

  DurationHours: number;

  Location: string;

  UrlLive: string;

  UrlRecording: string;

  RegistrantLimit: number;

  IsAutoConfirm: boolean;

  IsB2B: boolean;

  IsB2C: boolean;

  MaxQuantity: number;

  AdmissionPrice: number;

  ExpenseCost: number;


  //Computed properties
  ImageGuid: string;
  RegistrantsCount: number;
  RegistrantsConfirmedCount: number;
  RegistrantsTotal: number;
  RegistrantsConfirmedTotal: number;
  FacilityName: string;
  IsPast: boolean;


  Questions: ErEventQuestion[];

  Images: ErImage[];
}
