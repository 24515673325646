import { Input } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActForm } from 'app/_core/models';
import { BaseComponent } from './base.component';


export class BaseActComponent extends BaseComponent {
  @Input() id: number;
  @Input() act: ActForm;
  @Input() objAct: any;

  // Form act
  public objName: string = '';
  public formAct: FormGroup;
  public formValidations: any = {};
  public actForm = ActForm;
  public submitted: boolean = false;
  public resetForm: boolean = false;

  constructor() { super(); }

  public displayFieldCss(field: string, submitted: boolean = false, formGroup: FormGroup = null, resetForm: boolean = false) {
    if(!formGroup)
      formGroup = this.formAct;

    if(!formGroup) return;

    return this.displayFieldCssForm(formGroup, field, submitted, resetForm);
  }
  /**
 * makes the field required if the predicate function returns true
 */
  protected requiredIfValidator = (predicate): ValidationErrors => {
    return ((formControl: AbstractControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }
}
