import { environment } from 'environments/environment';
import { StorageKey } from '../models';
import { ErUser } from '../models/user';

export class BaseService {
    protected basePath: string = 'api';
    protected baseUrlCare: string = `${environment.baseUrlCare}/${this.basePath}`;
    protected baseUrlCentral: string = `${environment.baseUrlCentral}/${this.basePath}`;

    public CurrentUser: ErUser = null;
    public CurrentXsrfToken: string = localStorage.getItem(StorageKey.ErUserXsrfToken);
}
