import { CareGiver, ErEvent } from ".";
import { GeoType, InquiryPage } from "../predefined/enums";
import { Base } from "./base";
import { CareManager } from "./caremanager";
import { ErCity, ErCounty, ErZipcode, Facility } from "./facility";
import { Specialty } from './global';
import { SeniorPlacement } from "./senior-placement";

export const PhoneNumberCenter = '650-535-3070';

export enum Path {
  Home2 = '/home2',
  Home = '/home',
  Patient_Facility = '/patient-facility',
  Communities = '/communities',
  Facility_Detail = "/community",
  Care_Managers = "/caremanagers",
  Care_Managers_Extend = "/caremanagers-search",
  Care_Manager = "/caremanager",

  Care_Givers = "/caregivers",
  Care_Givers_Extend = "/caregivers-search",
  Care_Giver = "/caregiver",

  Senior_Placements = "/senior-placements",
  Senior_Placements_Extend = "/senior-placements-search",

  Inquiry_Additional = "/inquiry-additional",
  Events = "/events",
  Event = "/event",

  Directory = "/directory",
  Jobs = '/jobs',
  Wanted = '/wanted',
  Jobad = '/jobs',
  Wantad = '/wanted',
}

export enum SortDirection {
  ASC = 1,
  DESC
}
export enum ActForm {
  View = 'View',
  Add = 'Add',
  Edit = 'Edit',
  Del = 'Delete',
}

export enum StorageKey {
  ListOfValues = 'ListOfValues',
  Amenities = 'Amenities',
  Factypes = 'Factypes',
  Languages = 'Languages',
  Roomtypes = 'Roomtypes',
  ErUserXsrfToken = 'ErUserXsrfTokenXsrfToken',
}

export class PageInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  pages: number[];
}

export class Inquiry extends Base {
  TargetCustomerType: TargetCustomerType = TargetCustomerType.Loved_One;
  Name: string = null;
  PhoneNumber: string = null;
  EmailAddress: string = null;
  Notes: string = null;
  Location: string = null;
  MeetingReqFor: Date = null;
  ErFacilityId: number = null;
  ErCareManagerId: number = null;
  ErCaregiverId: number = null;
  ErSeniorPlacementId: number = null;
  ErAHICompanyId: number = null;
  ErJobadId: number = null;
  ErWantadId: number = null;
  InquiryGuid: string = null;
  UseWearableTech: boolean = null;
  HealthConditions: string = null;
  IsAmbulatory: boolean = null;
  ErRoomtypeId: number = null;
  Urgency: string = null;
  Languages: Language = null;
  InquiryPage: InquiryPage = InquiryPage.HomeCMD;
}

export class FacilityFilter {
  GeoId: number = 0;
  GeoType: string = '';
  GeoName: string = '';
  Distmi: number = 25;

  GeoCityId: number = 0;
  GeoCityName: string = '';
  GeoCountyId: number = 0;
  GeoCountyName: string = '';
  GeoStateId: number = 0;
  GeoStateName: string = '';

  PriceMin: number = 0;
  PriceMax: number = 0;
  Languages: Language[] = [];

  CapacityMin: number = null;
  CapacityMax: number = 0;
  RoomTypeId: number = 0;
  SpecialtiesId: number = 0;
  Company: string = '';

  FacilityTypeId: number = 0;
  FacilityName: string = '';
}

export class CareManagerFilter {
  // Search text
  Name: string = '';

  // Search geo
  GeoId: number = null;
  GeoType: string = null;
  GeoName: string = null;
  Distmi: number = null;

  GeoCityId: number = null;
  GeoCityName: string = null;
  GeoCountyId: number = null;
  GeoCountyName: string = null;
  GeoStateId: number = null;
  GeoStateName: string = null;

  Languages: Language[] = null;

  SpecialtyId: number = null;
  Specialties: Specialty[];
}

export class SeniorPlacementFilter {
  // Search text
  Name: string = '';

  // Search geo
  GeoId: number = null;
  GeoType: string = null;
  GeoName: string = null;
  Distmi: number = null;

  GeoCityId: number = null;
  GeoCityName: string = null;
  GeoCountyId: number = null;
  GeoCountyName: string = null;
  GeoStateId: number = null;
  GeoStateName: string = null;

  Languages: Language[] = null;
}

export class CareGiverFilter {
  // Search text
  Name: string = '';

  // Search geo
  GeoId: number = null;
  GeoType: string = null;
  GeoName: string = null;
  Distmi: number = null;

  GeoCityId: number = null;
  GeoCityName: string = null;
  GeoCountyId: number = null;
  GeoCountyName: string = null;
  GeoStateId: number = null;
  GeoStateName: string = null;

  Languages: Language[] = null;
}


export class CompanyFilter {
  SubcategoryId: number = null;
  CategoryId: number = null;
  GeoId: number = null;
  GeoType: string = null;
  GeoName: string = null;
  Distmi: number = null;
  Name: string = null;

  GeoCityId: number = null;
  GeoCityName: string = null;
  GeoCountyId: number = null;
  GeoCountyName: string = null;
  GeoStateId: number = null;
  GeoStateName: string = null;
}

export class JobadFilter {

}


/*
export class FacilitySearchParamter {
  PriceMin: number;
  PriceMax: number;
  CapacityMin: number;
  CapacityMax: number;
  Languages: Language[];
}
export class CareManagerSearchParamter {
  CMSpecialties: Specialty[];
  Languages: Language[];
}
*/
export interface Capacity {
  label: string;
  min: number;
  max: number;
}

export class Language {
  Id: number;
  Code: string;
  Name: string;
  LanguageCode: string;
  LanguageName: string;
}

export enum ListUrgency {
  Immediately = "Immediately",
  Months_1_3 = "1 to 3 months",
  Months_4_12 = "4 to 12 months",
  More_than_a_year = "More than a year",
}

export namespace ListUrgency {
  export function keys() {
    return Object.keys(ListUrgency).filter(
      (key) => isNaN(<any>key) && key !== 'keys'
    );
  }
}

export enum TargetCustomerType {
  Loved_One = "Looking Loved One",
  Myself = "Looking - Myself",
  Patient = "Looking - Patient"
}

export namespace TargetCustomerType {
  export function keys() {
    return Object.keys(TargetCustomerType).filter(
      (key) => isNaN(<any>key) && key !== 'keys'
    );
  }
}





//ErCareObjType to display name
export enum ErCareObjType {
  Facility = "Facility",
  FacilityRoom = "Room",
  ErPatient = "Patient",
  ErPatientAssessment = "Assessment",
  ErContactCommunication = "Communication",
  ErPatientMedication = "Medication",
  //ErPatientTask = "Task",
  //ErPatientVisit = "Visit",
  ErPatientTest = "Test",
  ErPatientJobTask = "Task",
  ErPatientJob = "Job",
  ErPatientVaccination = "Vaccination",
  CareManager = "CareManager",
  Caregiver = "Caregiver",
  AHICompany = "AHICompany"
}

export namespace ErCareObjType {
  export function rootParentNames() {
    return [ErCareObjType.Facility.toString(), ErCareObjType.CareManager.toString(), ErCareObjType.Caregiver.toString(), ErCareObjType.AHICompany.toString()];
  }

  export function keys() {
    return Object.keys(ErCareObjType).filter(
      (key) => isNaN(<any>key) && key !== 'keys'
    );
  }

  //Used by Minisite and Event
  export function rootParentKeys() {
    return Object.keys(ErCareObjType).filter(
      (key) => isNaN(<any>key) && key !== 'keys' && ErCareObjType.rootParentNames().includes(key)
    );
  }
}

export enum ErSeniorityLevel {
  Basic = "Basic",
  Intermediate = "Intermediate",
  Advanced = "Advanced"
}

export enum ErEmploymentStatus {
  PartTime = "Part-Time",
  FullTime = "Full-Time",
  OnCall = "On-Call"
}

export class TermSearch {
  text: string;
  geoType: GeoType;
  geo: ErCounty | ErCity | ErZipcode;
}

export class HomeSearching {
  CareManagers: CareManager[];
  Caregivers: CareGiver[];
  Communities: Facility[];
  SeniorPlacements: SeniorPlacement[];
  Events: ErEvent[];
}


const PAGE_SIZE_DEFAULT = 25;
const PAGE_SIZES: number[] = [PAGE_SIZE_DEFAULT, 50, 100];

class PageQuery {
  Page: number;
  PageSize: number;
  IncludeCount?: boolean = true;

  get offset() {
    return (this.Page - 1) * this.PageSize;
  }

  get limit() {
    return this.PageSize;
  }

  constructor(_page?: number, _pageSize?: number, _includeCount?: boolean) {
    this.Page = _page || 1;
    this.PageSize = _pageSize || PAGE_SIZE_DEFAULT;
    this.IncludeCount = _includeCount || true;
  }
}

class Pagination {
  Page: number;
  PageSize: number;
  TotalRecords: number;
  TotalPages: number;

  // For keep other params same like
  // - Sort field, direction
  // - etc
  extendParams: any;

  // Allow user chose page size
  PageSizes: number[];

  constructor(page?: number, pageSizes?: number[]) {
    this.Page = page || 1;
    this.PageSizes = pageSizes || PAGE_SIZES;

    // Set default page size
    const [firstPageSize] = this.PageSizes;
    this.PageSize = firstPageSize;
  }

  get totalPages() {
    return Math.ceil(this.TotalRecords / this.PageSize);
  }

  get offset() {
    return (this.Page - 1) * this.PageSize;
  }

  get limit() {
    return this.PageSize;
  }
}


export {
  PAGE_SIZE_DEFAULT,
  PAGE_SIZES,
  PageQuery,
  Pagination,
};