import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], value: string, label: string): any[] {
    // console.log('items:', items);
    // console.log('value:', value);
    // console.log('label:', label);
    if (!items) return [];
    if (!value) return items;
    if (value == '' || value == null) return [];

    const itemsFilter = items.filter(e => e[label].toLowerCase().indexOf(value.toLowerCase()) > -1);

    return itemsFilter;

  }
}