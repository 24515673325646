import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { AuthService } from 'app/_core/authenticate/auth.service';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html'
})
export class SignInComponent extends BaseActComponent implements OnInit {

    // Tabs
    public tabIndexCurrent: number;
    public tabNames: string[] = [
        "Log In",
        // "Sign Up"
    ]

    public Username: string;
    public Password: string;

    constructor(
        private activeModal: NgbActiveModal,
        private authService: AuthService,
    ) {
        super();
    }

    ngOnInit() {
        this.tabIndexCurrent = this.id || 0;
    }

    onViewTab(tabIndex: number) {
        this.tabIndexCurrent = tabIndex;
    }

    onClose() {
        this.activeModal.close();
    }

    onLogIn() {
        this.authService.login(this.Username, this.Password).subscribe(response => {
            this.activeModal.close(response);
        });
    }
}
