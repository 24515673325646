import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormat extends CurrencyPipe implements PipeTransform {
  transform(value: any, replaceNull: string = '$0', code: string = 'USD'): string {
    return value && !isNaN(value) ? super.transform(value, code, 'symbol', '1.0-2' ) : replaceNull;
  }
}
