import { Component, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';

import { ListOfValues } from '../../../_core/models';
import { MediaService } from 'app/_core/api-access/media.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../../_core/api-access/global.service';
import { ErWantad } from '../../../_core/models/ads/ErWantad';
import { WantadService } from '../../../_core/api-access/wantad.service';
import { ErWantadType } from '../../../_core/models/ads/ErJobad';

@Component({
  selector: 'app-wantad',
  templateUrl: './wantad.component.html'
})

export class WantadComponent extends BaseActComponent implements OnInit {

  get LOVs(): ListOfValues { return this.globalService.getCacheGlobal(); }

  public wantadsHelp: ErWantad[];
  public wantadsHousing: ErWantad[];

  constructor(
    private globalService: GlobalService,
    private wantadService: WantadService,
    private mediaService: MediaService,
    private router: Router,
  ) {
    super();

  }

  ngOnInit(): void {
    this.getCurrentAds();
  };

  private getCurrentAds() {
    this.wantadService.getCurrent(ErWantadType.Help).subscribe(result => this.wantadsHelp = result);
    this.wantadService.getCurrent(ErWantadType.Housing).subscribe(result => this.wantadsHousing = result);
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }

  redirectTo(uri: string, params: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri])
    );
  }

}
