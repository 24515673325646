import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErEvent } from '../../_core/models/event';
import { EventService } from '../../_core/api-access/event.service';
import { ErEventRegistrant } from '../../_core/models/event-details/eventRegistrant';
import { ErEventRegistrantDetail } from '../../_core/models/event-details/eventRegistrantDetail';
import { ErEventQuestion } from '../../_core/models/event-details/eventQuestion';
import { ErEventRegistrantAnswer } from '../../_core/models/event-details/eventRegistrantAnswer';
import { SignInComponent } from '../../system/sign-in.component';
import { DialogService } from '../../_core/utilities/dialog.service';
import { AuthService } from '../../_core/authenticate/auth.service';
import { MediaService } from '../../_core/api-access/media.service';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ListOfValues } from '../../_core/models/global';
import { GlobalService } from '../../_core/api-access/global.service';
import { ErUser } from 'app/_core/models';
import { MiscService } from 'app/_core/utilities/misc.service';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'app/_core/utilities/toast.service';

@Component({
  selector: 'app-t2-event-view-page',
  templateUrl: './event-view-page.component.html'
})
export class EventDetailsPageComponent extends BaseActComponent implements OnInit {

  public Event: ErEvent;

  public globalData: ListOfValues;
  public timezone: string;

  public answers: ErEventRegistrantAnswer[] = [];
  public eventQuestions: ErEventQuestion[] = [];

  constructor(
    private mediaService: MediaService,
    private eventService: EventService,
    private dialogService: DialogService,
    private authService: AuthService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private miscService: MiscService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.params['id'] != null) {
      this.id = +this.activatedRoute.snapshot.params['id'];
      
      // Get event info
      this.eventService.getEvent(this.id).subscribe(result => {
        this.Event = new ErEvent();
        // Assign result to object Event
        Object.assign(this.Event, result);
        if(this.Event){
          // Form register
          this.buildFormRegistrant();
          this.setFormRegistrant(this.initObjRegistrant(this.Event));

          this.Event.StartedAt = this.miscService.dateUtcToLocal(this.Event.StartedAt);

          this.loadGlobal();

          // Check questions on this Event
          this.eventQuestions = [];
          if (!this.Event.Questions) this.Event.Questions = [];
          for (let i = 0; i < this.Event.Questions.length; i++)
            this.eventQuestions.push(new ErEventQuestion(this.Event.Questions[i]));

          this.setFormAnswers(this.eventQuestions);
        }
      }); 
    }
  }

  setTimezone(){
    if (this.Event.Timezone && this.globalData) {
      this.timezone = this.globalData.Timezones.find(x => x.Id === this.Event.Timezone).StandardName;
    }
  }

  loadGlobal() {
    if (this.globalService.getCacheGlobal() && this.globalService.getCacheGlobal().Timezones) {
      this.globalData = this.globalService.getCacheGlobal();
      this.setTimezone();
      console.log("has timezones");
    }
    else {
      this.globalService.getGlobal().pipe(first()).subscribe(global => {
        this.globalData = global;
        this.setTimezone();
        this.globalService.setCacheGlobal(global);
      });
      console.log("get global");
    }
  }

  // Getter method to access formcontrols
  get formControls() {
    return this.formAct.controls;
  }

  get formRegistrant() {
    return (this.formAct.controls.RegistrantDetail as FormGroup);
  }

  get formAnswers() {
    return (this.formAct.controls.Answers as FormArray);
  }

  private initObjRegistrant(event: ErEvent): ErEventRegistrant {
    const obj: ErEventRegistrant = new ErEventRegistrant();
    obj.ErEventId = event.Id;
    obj.Quantity = 1;

    // Set default if user is not login
    obj.ErUserId = null;
    obj.RegistrantDetail = new ErEventRegistrantDetail();

    // If user login
    if (this.CurrentUser && this.CurrentUser.Id) {
      obj.ErUserId = this.CurrentUser.Id;
      obj.RegistrantDetail = null;
    }

    return obj;
  }

  private setFormAnswers(questions: ErEventQuestion[]) {
    // Add new
    let controls: FormGroup[] = [];

    if (questions) {
      controls = questions.map((q) => {
        return this.formBuilder.group({
          Answer: [null],
        });
      });
    }

    this.formAct.setControl(
      'Answers',
      this.formBuilder.array(controls)
    );
  }

  private setFormRegistrant(obj: ErEventRegistrant) {
    if (!obj) return;

    this.formAct.patchValue({
      Quantity: obj.Quantity
    });
  }

  private setFormControl(obj: ErUser){
    this.formRegistrant.controls.FirstName.setValue(obj ? obj.FirstName : null);
    this.formRegistrant.controls.LastName.setValue(obj ? obj.LastName : null);
    this.formRegistrant.controls.CompanyName.setValue(obj ? obj.CompanyName : null);
    this.formRegistrant.controls.EmailAddress.setValue(obj ? obj.EmailAddress : null);
    this.formRegistrant.controls.PhoneMobile.setValue(obj ? obj.PhoneMobile : null);
  }

  private getFormRegistrant(): ErEventRegistrant {
    const obj: ErEventRegistrant = this.initObjRegistrant(this.Event);

    // For contact
    if (obj.RegistrantDetail) {
      obj.RegistrantDetail.FirstName = this.formRegistrant.controls.FirstName.value;
      obj.RegistrantDetail.LastName = this.formRegistrant.controls.LastName.value;
      obj.RegistrantDetail.CompanyName = this.formRegistrant.controls.CompanyName.value;
      obj.RegistrantDetail.EmailAddress = this.formRegistrant.controls.EmailAddress.value;
      obj.RegistrantDetail.PhoneMobile = this.formRegistrant.controls.PhoneMobile.value;
    }

    // Tickets
    obj.Quantity = +this.formAct.controls.Quantity.value;

    // Answers
    obj.Answers = [];
    for (let i = 0; i < this.eventQuestions.length; i++) {
      const form = (this.formAnswers.controls[i] as FormGroup);
      const answer = form.controls.Answer.value;
      if (!answer) continue;

      const eventRegistrantAnswer = new ErEventRegistrantAnswer();
      eventRegistrantAnswer.Answer = answer.toString();
      eventRegistrantAnswer.ErEventQuestionId = this.eventQuestions[i].Id;
      obj.Answers.push(eventRegistrantAnswer);
    }

    return obj;
  }

  // Build form action
  private buildFormRegistrant() {
    const obj: ErEventRegistrant = this.initObjRegistrant(this.Event);

    this.formAct = this.formBuilder.group({
      // For contact
      RegistrantDetail: this.formBuilder.group({}),

      // Tickets
      Quantity: [null, [Validators.required, Validators.max(this.Event.MaxQuantity), Validators.min(1)]],

      // Answers
      Answers: new FormArray([])
    }, {});


    if (obj.RegistrantDetail)
      this.formAct.setControl(
        'RegistrantDetail',
        this.formBuilder.group({
          FirstName: [null, [Validators.required, Validators.maxLength(200)]],
          LastName: [null, [Validators.required, Validators.maxLength(200)]],
          CompanyName: [null, [Validators.maxLength(200)]],
          EmailAddress: [null, [Validators.required, Validators.email]],
          PhoneMobile: [null, [Validators.maxLength(200)]]
        }),
      );

    // Validations message
    this.formValidations = {
      FirstName: [
        { type: 'required', message: 'Name is required' },
        { type: 'maxlength', message: 'Name can\'t be more than 200 characters long' },
      ],
      LastName: [
        { type: 'required', message: 'Name is required' },
        { type: 'maxlength', message: 'Name can\'t be more than 200 characters long' },
      ],
      CompanyName: [
        { type: 'required', message: 'Name is required' },
        { type: 'maxlength', message: 'Name can\'t be more than 200 characters long' },
      ],
      EmailAddress: [
        { type: 'required', message: 'Email Address is required' },
        { type: 'email', message: 'Email Address is invalid format' },
      ],
      PhoneMobile: [
        { type: 'required', message: 'Mobile is required' },
        { type: 'maxlength', message: 'Mobile can\'t be more than 50 characters long' },
      ],
      Quantity: [
        { type: 'required', message: 'Quantity is required' },
        { type: 'min', message: 'Quantity can\'t smaller than 1' },
      ]
    }

    // Check max quantity when event have max quantity condition
    if (this.Event.MaxQuantity)
      this.formValidations.Quantity.push({
        type: 'max',
        message: `Quantity can\'t bigger than event max quantity ${this.Event.MaxQuantity}`
      });
  }

  onClose() {
    this.activeModal.dismiss();
  }

  onRegister() {
    this.submitted = true;

    // Form invalid
    if (!this.formAct.valid) return;

    this.eventService.sendRegistrant(this.getFormRegistrant()).pipe(first()).subscribe((result) => {
      this.activeModal.close(result);
      this.formAct.reset();
      this.submitted = false;
      this.resetForm = true;
      this.toastService.showSuccess('Register successfully!');
    });
  }

  onSignIn() {
    let modalRef: NgbModalRef;
    modalRef = this.dialogService.openStatic(SignInComponent, false);
    //modalRef.componentInstance.Event = erEvent;
    modalRef.result.then((result) => {
      this.CurrentUser = result;
      this.setFormControl(result);
      // this.ngOnInit();
    }, () => { });
  }

  onSignOut() {
    this.setFormControl(null);
    this.authService.logout(false);
    // this.ngOnInit();
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }
}
