// Core
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from "@angular/router";
import { DatePipe } from '@angular/common';

// Ngx-Bootstrap
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';

// Ng-Bootstrap
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Libraries
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from '@ng-select/ng-select';

// Application
import { FilterPipe } from './_core/pipes/filter.pipe';
import { AppRoutingModule, routes } from "./app.routing";
import { LoaderInterceptor } from './_core/interceptors/loader.interceptor';
import { ErrorInterceptor } from './_core/interceptors/error.interceptor';
import { DateFormatPipe } from './_core/pipes/date-format.pipe';
import { DateISO8601Pipe } from './_core/pipes/date-iso-8601.pipe';
import { TrimFirstPipe } from './_core/pipes/trim-first.pipe';

// Components
import { LoaderComponent } from './_controls/others/loader.component';
import { ToastComponent } from './_controls/others/toast.component';
import { NotFoundComponent } from "./not-found/not-found.component";
import { AppComponent } from "./app.component";
import { BlankContainerComponent } from "./_controls/container/blank-container.component";
import { FooterComponent } from './_controls/parts/footer.component';
import { ConfirmDialogComponent } from './_controls/dialogs/confirm-dialog.component';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { DateTimeFormatPipe } from './_core/pipes/date-time-format.pipe';
import { FormDirective } from './_core/directives/form.directive';
import { DateAgoPipe } from './_core/pipes/date-ago.pipe';
import { SortPipe } from './_core/pipes/sort.pipe';
import { MainContainerComponent } from './_controls/container/main-container.component';
import { HeaderComponent } from './_controls/parts/header.component';
import { SignUpComponent } from './system/sign-up.component';
import { SignInComponent } from './system/sign-in.component';
import { HomeComponent } from './pages/home.component';
import { FacilityInformationComponent } from './pages/facility/facility-detail-tabs/facility-information.component';
import { FacilityRoomComponent } from './pages/facility/facility-detail-tabs/facility-room.component';
import { FacilityLocationComponent } from './pages/facility/facility-detail-tabs/facility-location.component';
import { FacilityServiceComponent } from './pages/facility/facility-detail-tabs/facility-service.component';
import { FacilityReviewComponent } from './pages/facility/facility-detail-tabs/facility-review.component';
import { FieldErrorDisplayComponent } from './_controls/field-error-display.component';
import { CurrencyFormat } from './_core/pipes/currency-format.pipe';
import { NumberFormatPipe } from './_core/pipes/number-format.pipe';
import { NumberInteger } from './_core/pipes/number-integer.pipe';
import { SafePipe } from './_core/pipes/safe.pipe';

import { InquiryAdditionalComponent } from './pages/inquiry/inquiry-additional.component';
import { FacilityEventComponent } from './pages/facility/facility-detail-tabs/facility-event.component';
import { EventDetailsDialogComponent } from './pages/event/event-view-dialog.component';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { PatientFacilityComponent } from './pages/facility/patient-facility.component';
import { SeniorCareListComponent } from './pages/facility/senior-care-list.component';
import { FacilityDetailComponent } from './pages/facility/facility-details.component';
import { FacilityCareManagerComponent } from './pages/caremanager/caremanager-review.component';
import { EventListComponent } from './pages/event/event-list.component';
import { CareGiverReviewsComponent } from './pages/caregiver/caregiver-review.component';
import { CareGiverDetailComponent } from './pages/caregiver/caregiver-details.component';
import { CareGiverListExtendComponent } from './pages/caregiver/caregiver-list-extend.component';
import { CareGiverListComponent } from './pages/caregiver/caregiver-list.component';
import { CareGiverFilterExtendComponent } from './pages/caregiver/caregiver-filter-extend.component';
import { CareGiverFilterComponent } from './pages/caregiver/caregiver-filter.component';
import { CareManagerRelatedComponent } from './pages/caremanager/caremanager-related.component';
import { CareManagerDetailComponent } from './pages/caremanager/caremanager-details.component';
import { CareManagerListExtendComponent } from './pages/caremanager/caremanager-list-extend.component';
import { CareManagerListComponent } from './pages/caremanager/caremanager-list.component';
import { CareManagerFilterExtendComponent } from './pages/caremanager/caremanager-filter-extend.component';
import { CareManagerFilterComponent } from './pages/caremanager/caremanager-filter.component';
import { FacilityFilterComponent } from './pages/facility/facility-filter.component';
import { CareManagerEventComponent } from './pages/caremanager/caremanager-event.component';
import { EventItemComponent } from './pages/event/event-item.component';
import { CareGiverEventComponent } from './pages/caregiver/caregiver-event.component';
import { CarouselImagesComponent } from './_controls/carousel/carousel.component';
import { CarouselModule } from 'ngx-bootstrap';
import { LinkHttpPipe } from './_core/pipes/link-http.pipe';
import { SeniorPlacementFilterComponent } from './pages/senior-placement/senior-placement-filter.component';
import { SeniorPlacementFilterExtendComponent } from './pages/senior-placement/senior-placement-filter-extend.component';
import { SeniorPlacementListComponent } from './pages/senior-placement/senior-placement-list.component';
import { SeniorPlacementListExtendComponent } from './pages/senior-placement/senior-placement-list-extend.component';
import { SeniorPlacementDetailComponent } from './pages/senior-placement/senior-placement-details.component';
import { SeniorPlacementReviewsComponent } from './pages/senior-placement/senior-placement-review.component';
import { SeniorPlacementEventComponent } from './pages/senior-placement/senior-placement-event.component';
import { HomeVer2Component } from './pages/home-ver2.component';
import { DirectoryContainerComponent } from './pages/directory/directory-container.component';
import { DirectoryComponent } from './pages/directory/list/directory.component';
import { CompanyFilterComponent } from './pages/directory/filter/company-filter.component';
import { DirectoryListComponent } from './pages/directory/list/directory-list.component';
import { DirectoryCategoriesComponent } from './pages/directory/categories/directory-categories.component';
import { CompanyDetailsComponent } from './pages/directory/details/company-details.component';
import { CompanyReviewComponent } from './pages/directory/details/company-review.component';
import { CompanyEventComponent } from './pages/directory/details/company-event.component';
import { CareGiverRelatedComponent } from './pages/caregiver/caregiver-related.component';
import { FacilityRelatedComponent } from './pages/facility/facility-related.component';
import { EventDetailsPageComponent } from './pages/event/event-view-page.component';
import { JobadComponent } from './pages/job/list/jobad.component';
import { JobadDetailsComponent } from './pages/job/details/jobad-details.component';
import { JobadListComponent } from './pages/job/list/jobad-list.component';
import { WantadComponent } from './pages/wanted/list/wantad.component';
import { WantadListComponent } from './pages/wanted/list/wantad-list.component';
import { WantadDetailsComponent } from './pages/wanted/details/wantad-details.component';
import { BooleanComponent } from './_controls/boolean.component';
import { JobadRelatedListComponent } from './pages/job/related-list/jobad-related-list.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { InputNumberComponent } from './_controls/custom fields/inout-number.component';
import { FacilityRelatedCarouselComponent } from './pages/facility/facility-related-carousel.component';

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule.forRoot(routes, { useHash: true }),
    NgbModule,
    NgxSpinnerModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    CarouselModule.forRoot(),
    NgSelectModule
  ],
  declarations: [
    AppComponent,
    BlankContainerComponent,
    MainContainerComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    BooleanComponent,
    HomeComponent,
    HomeVer2Component,
    PatientFacilityComponent,
    SeniorCareListComponent,
    FacilityDetailComponent,
    FacilityInformationComponent,
    FacilityRoomComponent,
    FacilityLocationComponent,
    FacilityServiceComponent,
    FacilityReviewComponent,
    FacilityCareManagerComponent,
    FacilityEventComponent,
    FacilityRelatedComponent,
    FacilityRelatedCarouselComponent,
    JobadRelatedListComponent,

    EventDetailsDialogComponent,
    SignInComponent,
    ToastComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    FormDirective,
    BackToTopComponent,

    // Dialogs
    SignUpComponent,

    // Components
    FieldErrorDisplayComponent,
    FacilityFilterComponent,
    CareManagerFilterComponent,
    CareManagerFilterExtendComponent,
    CareManagerListComponent,
    CareManagerListExtendComponent,
    CareManagerDetailComponent,
    CareManagerRelatedComponent,
    CareManagerEventComponent,

    SeniorPlacementFilterComponent,
    SeniorPlacementFilterExtendComponent,
    SeniorPlacementListComponent,
    SeniorPlacementListExtendComponent,
    SeniorPlacementDetailComponent,
    SeniorPlacementReviewsComponent,
    SeniorPlacementEventComponent,

    CareGiverFilterComponent,
    CareGiverFilterExtendComponent,
    CareGiverListComponent,
    CareGiverListExtendComponent,
    CareGiverDetailComponent,
    CareGiverReviewsComponent,
    CareGiverEventComponent,
    CareGiverRelatedComponent,

    EventListComponent,
    EventItemComponent,
    EventDetailsPageComponent,

    //Directory (from AHI Company)
    DirectoryContainerComponent,
    DirectoryComponent,
    CompanyFilterComponent,
    DirectoryListComponent,
    DirectoryCategoriesComponent,
    CompanyDetailsComponent,
    CompanyEventComponent,
    CompanyReviewComponent,

    //Ads
    JobadDetailsComponent,
    JobadListComponent,
    JobadComponent,
    WantadComponent,
    WantadListComponent,
    WantadDetailsComponent,

    // inquiry, caremanager/caregiver
    InquiryAdditionalComponent,

    CarouselImagesComponent,

    // Pipes
    SortPipe,
    FilterPipe,
    DateFormatPipe,
    DateAgoPipe,
    DateTimeFormatPipe,
    DateISO8601Pipe,
    TrimFirstPipe,
    CurrencyFormat,
    NumberFormatPipe,
    NumberInteger,
    SafePipe,
    LinkHttpPipe,

    CartComponent,
    CheckoutComponent,
    InputNumberComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    DatePipe,
    DateFormatPipe,
    NgbActiveModal,
    DateAgoPipe,
    DateTimeFormatPipe,
    DateISO8601Pipe,
    TrimFirstPipe,
    SortPipe,
    CurrencyFormat,
    NumberFormatPipe,
    NumberInteger,
    SafePipe,
    LinkHttpPipe
  ],
  entryComponents: [
    ConfirmDialogComponent,
    SignInComponent,
    EventDetailsDialogComponent,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
