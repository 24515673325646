import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';
import { CareManager } from 'app/_core/models';

@Component({
  selector: 'app-caremanager-event',
  templateUrl: './caremanager-event.component.html'
})
export class CareManagerEventComponent extends BaseComponent implements OnInit {
  @Input() careManager: CareManager;

  constructor() {
    super();
  }

  // Methods implement
  ngOnInit() { }
}
