import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'app/_controls/base.component';
import { MediaService } from 'app/_core/api-access/media.service';
import { MiscService } from 'app/_core/utilities/misc.service';
import { ErEvent } from '../../_core/models/event';
import { DialogService } from '../../_core/utilities/dialog.service';
import { EventDetailsDialogComponent } from './event-view-dialog.component';

@Component({
    selector: 'app-event-item',
    templateUrl: './event-item.component.html'
})
export class EventItemComponent extends BaseComponent implements OnInit {
  @Input() event: ErEvent;

  constructor(
    private mediaService: MediaService,
    private dialogService: DialogService,
    private miscService: MiscService,
    private router:Router,
  ) {
    super();
  }

  // Methods implement
  ngOnInit() {
    if(this.event)
    this.event.StartedAt = this.miscService.dateUtcToLocal(this.event.StartedAt);
  }

  getImageUrl(guid: string) {
      return this.mediaService.getImageUrl(guid);
  }

  onView(erEvent: ErEvent): void {
    const pathname = window.location.pathname;
    window.open(`${pathname}#${this.path.Event}/${erEvent.Id}`, '_blank','toolbar=0,width=1000,height=600');
    /*
    const modalRef: NgbModalRef = this.dialogService.openStatic(EventDetailsDialogComponent, true);
    modalRef.componentInstance.Event = erEvent;
      modalRef.result.then((result) => {
        if(result && this.event.IsAutoConfirm)
          this.event.RegistrantsConfirmedTotal = this.event.RegistrantsConfirmedTotal + 1;
    }, () => { });
    */
  }

}
