import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ErCity, ErCounty, ErZipcode, HomeSearching, TermSearch } from '../models';
import { GeoType } from '../predefined/enums';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseService {

  // Data term search
  private termSearchSubject = new BehaviorSubject<TermSearch>(null);
  private termSearchData: { data: TermSearch } = { data: null };
  public readonly termSearch$ = this.termSearchSubject.asObservable();

  constructor(
    private http: HttpClient
  ) { super() }

  setTermSearch(text: string, geoType: GeoType, geo: ErCounty | ErCity | ErZipcode) {
    this.termSearchData.data = null;

    if(text || geoType || geo)
      this.termSearchData.data = {
        text: text,
        geoType: geoType,
        geo: geo
      };

    this.termSearchSubject.next(Object.assign({}, this.termSearchData).data);
  }

  clearTermSearch() {
    this.setTermSearch(null, null, null);
  }

  searchHome(termSearch: TermSearch, limit: number = 6): Observable<HomeSearching> {
    //console.log('termSearch :>> ', termSearch);
    let httpParams = new HttpParams();
    if (termSearch && termSearch.geo && termSearch.geo.Id)
      httpParams = httpParams.set('id', `${termSearch.geo.Id}`);
    if (termSearch && termSearch.geoType)
      httpParams = httpParams.set('geotype', `${termSearch.geoType}`);
    if (termSearch && termSearch.text)
      httpParams = httpParams.set('name', `${termSearch.text}`);
    if(limit)
      httpParams = httpParams.set('limit', `${limit}`);

    const apiUrl = this.baseUrlCare + `/search/home`;
    return this.http.get<HomeSearching>(apiUrl, { params: httpParams, withCredentials: true });
  }
}
