import { Component, Input, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';

import { MediaService } from 'app/_core/api-access/media.service';
import { ActivatedRoute } from '@angular/router';
import { ErJobad } from '../../../_core/models/ads/ErJobad';

@Component({
  selector: 'app-jobad-list',
  templateUrl: './jobad-list.component.html'
})

export class JobadListComponent extends BaseActComponent implements OnInit {

  @Input() jobads: ErJobad[];
  public subcategoryId: number = 0;

  constructor(
    private mediaService: MediaService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.subcategoryId = params['subcategoryId'];
    })
  };

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }
}
