import { Component, Input, OnInit } from '@angular/core';
import { MediaService } from 'app/_core/api-access/media.service';
import { ErImage } from 'app/_core/models';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html'
})
export class CarouselImagesComponent extends BaseComponent implements OnInit {
  @Input() listImages: ErImage[];
  @Input() itemsPerSlide: number;

  activeSliderId: string = 'ngb-slide-0';
  singleSlideOffset = true;

  constructor(
    private mediaService: MediaService
  ) {
    super();
  }

  ngOnInit() {}

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }
  cycleToSlide(index) {
    this.activeSliderId = `ngb-slide-${index}`;
  }
  onCheckSelect(index){
    return (+this.activeSliderId.replace("ngb-slide-", "")) === index;
  }
  onSlide(event) {
    const imageIndex = parseInt(event.current.replace("ngb-slide-", ""), 10);
    this.activeSliderId = `ngb-slide-${imageIndex}`;
  }
}
