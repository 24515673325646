import { Base } from "../base";
import { ErEventRegistrantAnswer } from "./eventRegistrantAnswer";
import { ErEventRegistrantDetail } from "./eventRegistrantDetail";

export class ErEventRegistrant extends Base {

    ErEventId: number;

    ErRegistrantDetailId: number;

    ErUserId: number;

    Quantity: number;

    IsConfirmed: boolean;

    IsAttended: boolean;


    //Computed
    Name: string;
    EmailAddress: string;
    PhoneMobile: string;
    CompanyName: string;


    RegistrantDetail: ErEventRegistrantDetail;
    Answers: ErEventRegistrantAnswer[];
}

