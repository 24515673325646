import { Component, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CareGiver } from 'app/_core/models';
import { CareGiverService } from 'app/_core/api-access/caregiver.service';
import { MediaService } from 'app/_core/api-access/media.service';

@Component({
  selector: 'app-caregiver-related',
  templateUrl: './caregiver-related.component.html'
})
export class CareGiverRelatedComponent extends BaseActComponent implements OnInit {

  public readonly careGivers$: Observable<CareGiver[]>;

  constructor(
    private careGiverService: CareGiverService,
    private route: ActivatedRoute,
    private mediaService: MediaService,) {
    super();

    // Subscribe to entire collection
    this.careGivers$ = this.careGiverService.careGiversObs;
    // Page info
  }

  ngOnInit(): void {
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }
}
