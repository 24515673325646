import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';
import { MediaService } from 'app/_core/api-access/media.service';
import { Facility } from 'app/_core/models';


@Component({
  selector: 'app-facility-review',
  templateUrl: './facility-review.component.html'
})
export class FacilityReviewComponent  extends BaseComponent implements OnInit {
  @Input() facility: Facility;

  public starNumbers = [5,4,3,2,1]

  constructor(
    private mediaService: MediaService) {
    super();
  }

  // Methods implement
  ngOnInit() {}

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }
}
