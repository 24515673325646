import { Component, Input, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';

import { AHICompany } from '../../../_core/models/company'
import { MediaService } from 'app/_core/api-access/media.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-directory-list',
  templateUrl: './directory-list.component.html'
})

export class DirectoryListComponent extends BaseActComponent implements OnInit {

  @Input() companies: AHICompany[];
  public subcategoryId: number = 0;

  constructor(
    private mediaService: MediaService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.subcategoryId = params['subcategoryId'];
    })
  };

  getImageUrl(company: AHICompany) {
    let guid = "";
    if(company && company.ImageGuid)
      guid = company.ImageGuid;
    else if(company && company.Images && company.Images.length > 0){
      const index = company.Images.findIndex(x => x);
      guid = company.Images[index].ImageGuid;
    }

    return this.mediaService.getImageUrl(guid);
  }
}
