import { Component, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';

// RxJs
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoType } from 'app/_core/predefined/enums';
import { CareManagerFilter, PageInfo, CareManager, ListOfValues} from 'app/_core/models';
import { GlobalService } from 'app/_core/api-access/global.service';
import { CareManagerService } from 'app/_core/api-access/caremanager.service';
import { MediaService } from 'app/_core/api-access/media.service';

@Component({
  selector: 'app-caremanager-list',
  templateUrl: './caremanager-list.component.html'
})

export class CareManagerListComponent extends BaseActComponent implements OnInit {

  public readonly careManagers$: Observable<CareManager[]>;
  public readonly pageInfo$: Observable<PageInfo>;
  public readonly geoType = GeoType;

  // Keep form value
  public careManagerFilter: CareManagerFilter = new CareManagerFilter();

  // Pagination
  public pageOffset: number;
  public pageLimit: number;
  public pageNumberSelected: number;

  public globalData: ListOfValues;

  constructor(
    private careManagerService: CareManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private mediaService: MediaService,) {
    super();

    // Subscribe to entire collection
    this.careManagers$ = this.careManagerService.careManagersObs;
    // Page info
    this.pageInfo$ = this.careManagerService.pageInfoObs;
  }

  loadGlobal(loadNew: boolean = false) {
    if (this.globalService.getCacheGlobal() && !loadNew)
      this.globalData = this.globalService.getCacheGlobal();
    else
      this.globalService.getGlobal().pipe(first()).subscribe(global => {
        this.globalData = global;
        this.globalService.setCacheGlobal(global);
        this.careManagerFilter.Languages = this.globalData.Languages.filter(language => language.Name === "English");
      });
  }

  ngOnInit(): void {
    this.loadGlobal();
    // Process
    this.route.queryParamMap.subscribe(params => {
      // Get filter values from params
      this.careManagerFilter.GeoId = +params.get('geo_id');
      this.careManagerFilter.GeoType = params.get('geo_type');
      this.careManagerFilter.GeoName = params.get('geo_name');

      // TODO: should get it from api, get detail for geotype and id above, or return on result together.
      this.careManagerFilter.GeoCityId = +params.get("geo_city_id");
      this.careManagerFilter.GeoCityName = params.get("geo_city_name");
      this.careManagerFilter.GeoCountyId = +params.get("geo_county_id");
      this.careManagerFilter.GeoCountyName = params.get("geo_county_name");
      this.careManagerFilter.GeoStateId = +params.get("geo_state_id");
      this.careManagerFilter.GeoStateName = params.get("geo_state_name");

      //set default language
      if(this.globalData && this.globalData.Languages){
        this.careManagerFilter.Languages = this.globalData.Languages.filter(language => language.Name === "English");
      }else{
        this.loadGlobal(true);
      }

      // Pagination
      const offset: number = +params.get("page_offset");
      this.pageOffset = offset ? offset : 0;

      const limit: number = +params.get("page_limit");
      this.pageLimit = limit ? limit : 4;

      // Load data
      this.careManagerService.load(this.pageOffset, this.pageLimit, this.careManagerFilter);
    });
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }

  onPaginationChange(pageNumber: number, totalPage: number) {
    if (pageNumber < 1 || pageNumber > totalPage) {
      return;
    }

    this.pageNumberSelected = pageNumber;
    this.pageOffset = (pageNumber - 1) * this.pageLimit;

    // Load data with new page and current condition
    this.careManagerService.load(this.pageOffset, this.pageLimit, this.careManagerFilter);
  }

  redirectTo(uri: string, params: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri])
    );
  }

  getFilter(filter: CareManagerFilter) {
    // Redirect to page with filter on side
    const params = {};

    if(filter.GeoId) {
      params['geo_id'] = filter.GeoId;
      params['geo_type'] = filter.GeoType;
      params['geo_name'] = filter.GeoName;
    }

    if(filter.GeoCityId) {
      params['geo_city_id'] = filter.GeoCityId;
      params['geo_city_name'] = filter.GeoCityName;
    }

    if(filter.GeoCityId) {
      params['geo_county_id'] = filter.GeoCountyId;
      params['geo_county_name'] = filter.GeoCountyName;
    }

    if(filter.GeoStateId) {
      params['geo_state_id'] = filter.GeoStateId;
      params['geo_state_name'] = filter.GeoStateName;
    }

    if(filter.Distmi)
      params['distmi'] = filter.Distmi;

    if(filter.SpecialtyId)
      params['specialty_id'] = filter.SpecialtyId;

    if(filter.Languages)
      params['language_ids'] = filter.Languages.map(l => l.Id).join();


    this.redirectTo(this.path.Care_Managers_Extend, params);
  }
}
