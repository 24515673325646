import { Pipe, PipeTransform } from '@angular/core';
import { SortDirection } from '../models';
@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(items: any[], field: string, direction: SortDirection, type?: string): any[] {
    if (!items) return [];

    const itemsSorted = items.sort((itemLeft: any, itemRight: any) => {
      let valueLeft = itemLeft[field];
      let valueRight = itemRight[field];

      if((typeof valueLeft === 'string' && typeof valueRight === 'string' || (valueLeft instanceof String && valueRight instanceof String))
      || type === 'string') {
        valueLeft = valueLeft.toLowerCase().trim();
        valueRight = valueRight.toLowerCase().trim();
      } else if (type === 'Date') {
        valueLeft = new Date(valueLeft);
        valueRight = new Date(valueRight);
      }

      if (valueLeft < valueRight) {
        return direction === SortDirection.ASC ? -1 : 1;
      } else if (valueLeft > valueRight) {
        return direction === SortDirection.ASC ? 1 : -1;
      } else {
        if(!valueLeft && valueRight)
          return direction === SortDirection.ASC ? -1 : 1;
        else if (valueLeft && !valueRight)
          return direction === SortDirection.ASC ? 1 : -1;
        else
          return 0;
      }
    });

    return itemsSorted;
  }
}