import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';
import { Facility } from 'app/_core/models';


@Component({
  selector: 'app-facility-information',
  templateUrl: './facility-information.component.html'
})
export class FacilityInformationComponent  extends BaseComponent implements OnInit {
  @Input() facility: Facility;

  constructor() {
    super();
  }
  // Methods implement
  ngOnInit() {}
}
