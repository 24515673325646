import { Component, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';

// RxJs
import { first } from 'rxjs/operators';
import { ListOfValues } from 'app/_core/models/global';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoType } from 'app/_core/predefined/enums';
import { GlobalService } from 'app/_core/api-access/global.service';
import { ErEvent } from 'app/_core/models/event';
import { EventService } from 'app/_core/api-access/event.service';
import { MediaService } from 'app/_core/api-access/media.service';
import { DialogService } from 'app/_core/utilities/dialog.service';
@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html'
})

export class EventListComponent extends BaseActComponent implements OnInit {

  public readonly geoType = GeoType;

  public globalData: ListOfValues;
  public events: ErEvent[];
  public eventsPast: ErEvent[];

  // Tabs
  public tabIndexCurrent: number = 0;
  public tabNames: string[] = [
    "All",
    "Consumer",
    "Business",
    "Past"
  ]

  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private mediaService: MediaService,
    private dialogService: DialogService,
  ) {
    super();
  }

  loadGlobal(loadNew: boolean = false) {
    if (this.globalService.getCacheGlobal() && !loadNew)
      this.globalData = this.globalService.getCacheGlobal();
    else
      this.globalService.getGlobal().pipe(first()).subscribe(global => {
        this.globalData = global;
        this.globalService.setCacheGlobal(global);
      });
  }

  loadEvents() {
    this.eventService.getEventsUpcoming().subscribe(result => { 
      this.events = result.Upcoming;
      this.eventsPast = result.Past;
    })
  }

  ngOnInit(): void {
    this.loadGlobal();
    this.loadEvents();
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }

  redirectTo(uri: string, params: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri])
    );
  }

  getFilterOnTab(events: ErEvent[], tabIndex: number): ErEvent[] {
    // Set empty array if null
    if(!events)
      events = [];

    // Tab index = 0 return all
    if(tabIndex === 0) return events;

    // Tab index = 1 return event.IsB2B = false
    if(tabIndex === 1) return events.filter(e => e.IsB2C === true);

    // Tab index = 2 return event.IsB2B = true
    if(tabIndex === 2) return events.filter(e => e.IsB2B === true);

    // Tab index = 3 return Past
    if(tabIndex === 3) return this.eventsPast;
  }

  onViewTab(tabIndex: number) {
    this.tabIndexCurrent = tabIndex;
  }
}
