import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-field-error-display',
  template: `
  <div class="invalid-feedback" *ngIf="validations && validations.length && fieldControl && fieldControl.errors">
    <ng-container *ngFor="let validation of validations">
      <div *ngIf="fieldControl.errors[validation.type] && (fieldControl.dirty || fieldControl.touched)">{{validation.message}}</div>
    </ng-container>
  </div>
  `
})
export class FieldErrorDisplayComponent {
  @Input() validations: any = {};
  @Input() fieldControl: AbstractControl;
}
