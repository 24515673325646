import { Component, OnInit } from '@angular/core';
import { CompanyFilter, ErCity, ErCounty, ErZipcode, HomeSearching, ListOfValues, TermSearch } from 'app/_core/models';
import { SearchService } from 'app/_core/api-access/search.service';
import { tap } from 'rxjs/operators';
import { BaseComponent } from 'app/_controls/base.component';
import { Observable } from 'rxjs';
import { MediaService } from 'app/_core/api-access/media.service';
import { GeoType } from 'app/_core/predefined/enums';
import { GlobalService } from 'app/_core/api-access/global.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home-ver2',
  templateUrl: './home-ver2.component.html'
})

export class HomeVer2Component extends BaseComponent implements OnInit {

  resultHomeSearching$ = new Observable<HomeSearching>();
  termSearchCurrent: TermSearch;
  public companyFilter: CompanyFilter = new CompanyFilter();

  constructor(
    private searchService: SearchService,
    private mediaService: MediaService,
    private route: ActivatedRoute,
    private globalService: GlobalService,
  ) { super(); }

  ngOnInit(): void {
    this.registerSearch(this.searchService, this.processResultSearch.bind(this));
    this.getCategoriesFilter();
  }


  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }

  onNavigateList() {
    // Clear term search header
    this.searchService.clearTermSearch();
  }

  getParams() {
    if (!this.termSearchCurrent) return null;

    const queryParams: { [k: string]: any; } = {};

    if (this.termSearchCurrent.geo) {
      const geo = this.termSearchCurrent.geo;
      const geoType = this.termSearchCurrent.geoType;

      queryParams["geo_id"] = geo.Id;
      queryParams["geo_name"] = geo.Name;

      // Params county
      if (geoType === GeoType.County) {
        const county: ErCounty = geo as ErCounty;

        queryParams["geo_type"] = GeoType.County;
        if (county.State) {
          queryParams["geo_state_id"] = county.State.Id;
          queryParams["geo_state_name"] = county.State.Name;
        }
      }

      // Params city
      if (geoType === GeoType.City) {
        const city: ErCity = geo as ErCity;

        queryParams["geo_type"] = GeoType.City;
        if (city.County) {
          queryParams["geo_county_id"] = city.County.Id
          queryParams["geo_county_name"] = city.County.Name
          if (city.County.State) {
            queryParams["geo_state_id"] = city.County.State.Id
            queryParams["geo_state_name"] = city.County.State.Name
          }
        }
      }

      // Params zip code
      if (geoType === GeoType.Zip_Code) {
        const zipCode: ErZipcode = geo as ErZipcode;
        queryParams["geo_type"] = GeoType.Zip_Code;
        if (zipCode.City) {
          queryParams["geo_city_id"] = zipCode.City.Id;
          queryParams["geo_city_name"] = zipCode.City.Name;
          if (zipCode.City.County) {
            queryParams["geo_county_id"] = zipCode.City.County.Id;
            queryParams["geo_county_name"] = zipCode.City.County.Name;
            if (zipCode.City.County.State) {
              queryParams["geo_state_id"] = zipCode.City.County.State.Id;
              queryParams["geo_state_name"] = zipCode.City.County.State.Name;
            }
          }
        }
      }
    }

    if (this.termSearchCurrent.text) {
      const name = this.termSearchCurrent.text;
      // console.log("name:" + name);
      queryParams["name"] = this.termSearchCurrent.text;
    }

    return queryParams;
  }

  private processResultSearch = (termSearch: TermSearch) => {
    // console.log(termSearch);
    this.termSearchCurrent = termSearch;
    this.resultHomeSearching$ = this.searchService.searchHome(termSearch).pipe(tap(res => {
      // console.log('res :>> ', res);
    }));
  }

  getCategoriesFilter(){
    if (!this.globalService.getCacheGlobal())
      this.globalService.getGlobal().subscribe(() => { this.companyFilter.CategoryId = this.LOVs.Config.CmdOfferingCategoryId; });

    this.route.queryParamMap.subscribe(params => {
      this.companyFilter.Name = params.get('name');

      // Get filter values from params
      this.companyFilter.GeoId = +params.get('geo_id');
      this.companyFilter.GeoType = params.get('geo_type');
      this.companyFilter.GeoName = params.get('geo_name');

      // TODO: should get it from api, get detail for geotype and id above, or return on result together.
      this.companyFilter.GeoCityId = +params.get("geo_city_id");
      this.companyFilter.GeoCityName = params.get("geo_city_name");
      this.companyFilter.GeoCountyId = +params.get("geo_county_id");
      this.companyFilter.GeoCountyName = params.get("geo_county_name");
      this.companyFilter.GeoStateId = +params.get("geo_state_id");
      this.companyFilter.GeoStateName = params.get("geo_state_name");

      this.companyFilter.Distmi = +params.get("Distmi");

      if (this.LOVs) this.companyFilter.CategoryId = this.LOVs.Config.CmdOfferingCategoryId;
      this.companyFilter.SubcategoryId = +params.get("subcategoryId");
    });
  }

  get LOVs(): ListOfValues {
    return this.globalService.getCacheGlobal();
  }
}
