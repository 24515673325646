import { Base } from "../base";

export class ErEventRegistrantAnswer extends Base {

  ErEventRegistrantId: number;

  ErEventQuestionId: number;

  Answer: string;

}
