import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { CareManagerService } from 'app/_core/api-access/caremanager.service';
import { FormService } from 'app/_core/api-access/form.service';
import { GlobalService } from 'app/_core/api-access/global.service';
import { MediaService } from 'app/_core/api-access/media.service';
import { CareManager, ErEmploymentStatus, ErSeniorityLevel, Inquiry, TargetCustomerType, ListOfValues } from 'app/_core/models';
import { GeoType, InquiryPage } from 'app/_core/predefined/enums';
import { MiscService } from 'app/_core/utilities/misc.service';
import { ToastService } from 'app/_core/utilities/toast.service';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-caremanager-details',
  templateUrl: './caremanager-details.component.html',
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})

export class CareManagerDetailComponent extends BaseActComponent implements OnInit {
  // Enums, readonly variables
  public readonly targetCustomerType = TargetCustomerType;
  public readonly geoType = GeoType;

  // Vars
  public minDate: Date;
  public maxDate: Date;
  public globalData: ListOfValues;
  public careManager: CareManager;

  // Time ranges
  public hoursRange: string[];

  public employmentStatus = ErEmploymentStatus;
  public seniorityLevel = ErSeniorityLevel;
  public tabIndexCurrent: number = 0;
  public tabNames: string[] = [
    "Summary",
    "Experience",
    "Specialties",

    "Events",
    "Reviews"
  ];
  // "Rate",
  private obj: Inquiry = new Inquiry();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private formService: FormService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private careManagerService: CareManagerService,
    private mediaService: MediaService,
    private miscService: MiscService
  ) {
    super();
    this.minDate = new Date();
    this.maxDate = new Date('3000-01-01');
  }

  private getObjPropertyName(expression: Function): string {
    return this.miscService.getPropertyName(this.obj, expression);
  }

  private setFormData() {
    this.formAct.controls[this.getObjPropertyName((o: Inquiry) => o.TargetCustomerType)].setValue('Loved_One');
  }

  private objMapForm(): Inquiry {
    const obj: Inquiry = new Inquiry();
    obj.ErCareManagerId = this.careManager.Id;
    obj.TargetCustomerType = this.formAct.controls[this.getObjPropertyName((o: Inquiry) => o.TargetCustomerType)].value;
    obj.Name = this.formAct.controls.Name.value;
    obj.PhoneNumber = this.formAct.controls.PhoneNumber.value;
    obj.EmailAddress = this.formAct.controls.EmailAddress.value;
    obj.Notes = this.formAct.controls.Notes.value;
    // Populate schedule date with time range
    if (this.formAct.controls.MeetingReqFor.value) {
      const date = new Date(this.formAct.controls.MeetingReqFor.value);
      date.setMinutes(0);
      date.setSeconds(0);

      const hour = +this.formAct.controls.MeetingTimeRange.value;
      date.setHours(hour);

      // Final assign to schedule metting time
      obj.MeetingReqFor = this.miscService.dateLocalToUtc(date);
    }
    obj.InquiryPage = InquiryPage.Details;
    return obj;
  }

  // Build form action
  private buildForm() {
    const formGroup: FormGroup = this.formBuilder.group({});
    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.TargetCustomerType), new FormControl(null, Validators.required));

    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.Name), new FormControl(null, [Validators.required, Validators.maxLength(200)]));
    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.PhoneNumber), new FormControl(null, [Validators.required, Validators.maxLength(50)]));
    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.EmailAddress), new FormControl(null, [Validators.required, Validators.maxLength(200)]));
    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.Notes), new FormControl(null, Validators.required));
    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.MeetingReqFor), new FormControl(null, Validators.required));

    // Control name not wrap in object
    formGroup.addControl('MeetingTimeRange', new FormControl(null, Validators.required));

    // Assign form group variable
    this.formAct = formGroup;

    // Validations message
    this.formValidations = {};
    this.formValidations[this.getObjPropertyName((o: Inquiry) => o.Name)] = [
      { type: 'required', message: 'Name is required' },
      { type: 'maxlength', message: 'Name can\'t be more than 200 characters long' },
    ];

    this.formValidations[this.getObjPropertyName((o: Inquiry) => o.PhoneNumber)] = [
      { type: 'required', message: 'Mobile is required' },
      { type: 'pattern', message: 'Mobile is not valid' },
      { type: 'maxlength', message: 'Mobile can\'t be more than 50 characters long' },
    ];

    this.formValidations[this.getObjPropertyName((o: Inquiry) => o.EmailAddress)] = [
      { type: 'required', message: 'Email Address is required' },
      { type: 'pattern', message: 'Email Address is not valid' },
      { type: 'maxlength', message: 'Email Address can\'t be more than 200 characters long' },
    ];

    this.formValidations[this.getObjPropertyName((o: Inquiry) => o.Notes)] = [
      { type: 'required', message: 'Notes is required' },
      { type: 'maxlength', message: 'Notes can\'t be more than 200 characters long' },
    ];
  }

  private loadDetail() {
    this.careManagerService.getById(this.id).subscribe(obj => {
      this.careManager = obj;
      if (obj.Cities && obj.Cities.length)
        this.careManager.CityNames = obj.Cities.map(c => c.Name).join(", ");
    });
  }

  ngOnInit(): void {
    this.loadGlobal();
    // Load data
    this.hoursRange = this.miscService.getHours();

    this.buildForm();
    if (this.activatedRoute.snapshot.params['id'] != null) {
      this.id = +this.activatedRoute.snapshot.params['id'];
      this.loadDetail();
    }
    this.setFormData();
  }

  onSubmitContact() {
    this.submitted = true;

    // Form invalid
    if (!this.formAct.valid)
      return;

    // Form is valid
    this.formService.sendInquiry(this.objMapForm())
      .pipe(first())
      .subscribe(
        (obj) => {
          // Reset submit
          this.submitted = false;
          // this.toastService.showSuccess('Thank you! Someone will be in touch with you.');
          this.formAct.reset();
          this.router.navigate([this.path.Inquiry_Additional]);
          // this.setFormData();
        },
        () => {
          // Reset submit
          this.submitted = false;
        });
  }

  loadGlobal() {
    if (this.globalService.getCacheGlobal()) {
      this.globalData = this.globalService.getCacheGlobal();
    }
    else {
      this.globalService.getGlobal().pipe(first()).subscribe(global => {
        this.globalData = global;
        this.globalService.setCacheGlobal(global);
      });
    }
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }

  onViewTab(tabIndex: number) {
    this.tabIndexCurrent = tabIndex;
  }

  redirectTo(uri: string, params: any) {
    console.log("redirect" + uri, "params" + params);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri]));
  }
}
