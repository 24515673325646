import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'trimFirst'
})
export class TrimFirstPipe implements PipeTransform {
  transform(value: any, numberLast?: any): any {
    const numberLastCharacter = numberLast ? numberLast : 4;

    const valueString = new String(value);
    const length = valueString.length;
    if (length < numberLast)
      return valueString;

    return valueString.slice(length - numberLastCharacter);
  }
}