import { Component, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html'
})

export class CartComponent extends BaseActComponent implements OnInit {

  ngOnInit(): void {
    
  }

}
