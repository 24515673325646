import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe){}

  transform(value: any, hasTime?: boolean): any {
    let valueFormat: string;
    // Format date
    let format = 'MM/dd/yyyy';

    // Time
    if(hasTime) {
      format = `${format} h:mm a`;
      //format = `${format} HH:mm:ss a z`;
    }

    valueFormat = this.datePipe.transform(value, format);

    return valueFormat;
  }
}
