import { HostListener } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { SearchService } from 'app/_core/api-access/search.service';
import { Path, ErUser, TermSearch, PhoneNumberCenter } from 'app/_core/models';

export class BaseComponent {
  public path = Path;
  public googleAPI = "AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc";

  public emailPattern = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$";
  public phonePattern = "[- +()0-9]{6,}";

  public phoneNumberCenter = PhoneNumberCenter;

  constructor() { 
    // Populate screen width when init
    this.getScreenSize();
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  displayFieldCssForm(
    form: FormGroup,
    field: string,
    submitted: boolean = false,
    resetForm: boolean = false
  ) {
    const isFieldValid: boolean =
      (!form.get(field).valid && form.get(field).touched && !resetForm) ||
      (!form.get(field).valid && submitted);
    return {
      'is-invalid': isFieldValid,
      'has-feedback': isFieldValid,
    };
  }

  public getControls(form: FormGroup, field: string): AbstractControl[] {
    return (form.get(field) as FormArray).controls;
  }

  public getFieldControl(form: FormGroup, field: string) {
    return form.get(field);
  }
  onBack() {
    history.back();
  }

  decodeHtmlCharCodes(str) {
    if (!str)
      return "";

    return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
      return String.fromCharCode(charCode);
    });
  }

  stripHtml(text: string) {
    if (!text) return "";
    return this.decodeHtmlCharCodes(text.replace(/<[^>]+>/g, ''));
    return text.replace(/<style[^>]*>.*<\/style>/gm, '')
      // Remove script tags and content
      .replace(/<script[^>]*>.*<\/script>/gm, '')
      // Remove all opening, closing and orphan HTML tags
      .replace(/<[^>]+>/gm, '')
      // Remove leading spaces and repeated CR/LF
      .replace(/([\r\n]+ +)+/gm, '');
  }

  set CurrentUser(value: ErUser) { BaseComponent.CurrentUser = value; }
  get CurrentUser(): ErUser { return BaseComponent.CurrentUser; }

  public static CurrentUser: ErUser;

  registerSearch(searchService: SearchService, processFunction: Function) {
    searchService.termSearch$.subscribe((termSearch: TermSearch) => {
      if (!processFunction)
        return;
      processFunction(termSearch);
    });
  }

  /* --------------------------------- Screen --------------------------------- */
  /*
    Bootstrap 4 screen
    - Xs: Extra small < 576px
    - Sm: Small >= 576px
    - Md: Medium >= 768px
    - Lg: Large >= 992px
    - Xl: Extra Large >= 1200px
  */
  // Screen min size
  readonly minXs = 375;
  readonly minSm = 576;
  readonly minMd = 768;
  readonly minLg = 992;
  readonly minXl = 1200;

  // Screen max size
  readonly maxXs = this.minSm - 1;
  readonly maxSm = this.minMd - 1;
  readonly maxMd = this.minLg - 1;
  readonly maxLg = this.minXl - 1;

  protected screenHeight: any;
  protected screenWidth: any;

  /**
   * @description Screen size for: xs
   * @readonly
   */
  get isScreenMaxXs() {
    return this.screenWidth <= this.maxXs;
  }

  /**
   * @description Screen size for: xs, sm
   * @readonly
   */
  get isScreenMaxSm() {
    return this.screenWidth <= this.maxSm;
  }

  /**
   * @description Screen size for: xs, sm, md
   * @readonly
   */
  get isScreenMaxMd() {
    return this.screenWidth <= this.maxMd;
  }

  /**
   * @description Screen size for: xs, sm, md, lg
   * @readonly
   */
  get isScreenMaxLg() {
    return this.screenWidth <= this.maxLg;
  }

  /**
   * @description Screen size for: lg, xl
   * @readonly
   */
  get isScreenMinXs() {
    return this.screenWidth >= this.minXs;
  }

  /**
   * @description Screen size for: lg, xl
   * @readonly
   */
  get isScreenMinSm() {
    return this.screenWidth >= this.minSm;
  }

  /**
   * @description Screen size for: lg, xl
   * @readonly
   */
  get isScreenMinMd() {
    return this.screenWidth >= this.minMd;
  }

  /**
   * @description Screen size for: lg, xl
   * @readonly
   */
  get isScreenMinLg() {
    return this.screenWidth >= this.minLg;
  }

  /**
   * @description Screen size for: lg, xl
   * @readonly
   */
  get isScreenMinXl() {
    return this.screenWidth >= this.minXl;
  }

  // Browser resize
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  /* ------------------------------- Screen End ------------------------------- */
}
