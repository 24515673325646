import { Injectable } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(
    private modalService: NgbModal
  ) { }

  openStatic(component: any, isLarge: boolean = false): NgbModalRef {
    return this.modalService.open(component, {
      backdrop: 'static',
      backdropClass: 'custom-backdrop',
      windowClass : (isLarge) ? "modal-lg" : "custom-window",
      keyboard: false
    });
  }
}