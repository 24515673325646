import { Expression } from '@angular/compiler';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { PageInfo } from '../models';

@Injectable({
  providedIn: 'root'
})
export class MiscService {

  constructor() { }

  getHours(): string[] {
    let items = [];
    for (var hour = 0; hour < 24; hour++) {
      items.push([hour, 0]);
    }

    const formatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });

    const date = new Date();
    const date2 = new Date();

    const range = items.map(time => {
      const [hour, minute] = time;
      date.setHours(hour);
      date.setMinutes(minute);

      // Add 30 minutes to this hour
      date2.setHours(hour);
      date2.setMinutes(60);

      return `${formatter.format(date)} - ${formatter.format(date2)}`;
    });

    return range;
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10, maxPages: number = 8): PageInfo {
    // Total items is zero return null
    if(!totalItems) return null;

    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= maxPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      // Round up
      const haftMaxPages = Math.ceil(maxPages/2);
      if (currentPage <= haftMaxPages + 1) {
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + (haftMaxPages - 1) >= totalPages) {
        startPage = totalPages - maxPages - 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - haftMaxPages;
        endPage = currentPage + (haftMaxPages - 1);
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const length = endPage - startPage + 1;
    let pages = Array.from({ length }, (_, i) => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  /**
   * How to use:
   * let result = getPropertyName(person, o => o.address);
   * console.log(result); // Output: 'address'
   * @param obj object with property should be initial set null or default value
   * @param expression expression property
   */
  getPropertyName = (obj:any, expression: Function): string => {
    const res = {};
    if(!obj) {
      console.log(expression);
      return;
    }
    Object.keys(obj).map(k => { res[k] = () => k; });
    if(!res) {
      console.log(expression);
      return;
    }

    return expression(res)();
  }
/*
  dateUtcToLocal(date: any): Date {
    const dateObj = new Date((typeof date === "string" ? new Date(date) : date));
    return new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60 * 1000);
  }
*/
  dateUtcToLocal(dateUTC: any, dateUTCFormat: string = null): Date {
    return moment.utc(dateUTC, dateUTCFormat).toDate();
  }

  dateLocalToUtc(date: any) {
    const dateObj = new Date((typeof date === "string" ? new Date(date) : date));
    const dateUtc = new Date(Date.UTC(
      dateObj.getFullYear(),
      dateObj.getMonth(),
      dateObj.getDate(),
      dateObj.getHours(),
      dateObj.getMinutes()
    ));
    return dateUtc;
  }

  convertTimeZone = (date, tzString) => {
    // usage: Asia/Jakarta is GMT+7
    // convertTimeZone("2012/04/20 10:10:30 +0000", "Asia/Jakarta") // Tue Apr 20 2012 17:10:30 GMT+0700 (Western Indonesia Time)

    // Resulting value is regular Date() object
    // const convertedDate = convertTimeZone("2012/04/20 10:10:30 +0000", "Asia/Jakarta")
    // convertedDate.getHours(); // 17

    // Bonus: You can also put Date object to first arg
    // const date = new Date()
    // convertTimeZone(date, "Asia/Jakarta") // current date-time in jakarta.

    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }

  // Detecting a User’s Locale in a Web App
  getBrowserLocales = (options = {}) => {
    const defaultOptions = {
      languageCodeOnly: false,
    };

    const opt = {
      ...defaultOptions,
      ...options,
    };

    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;

    if (!browserLocales) {
      return undefined;
    }

    return browserLocales.map(locale => {
      const trimmedLocale = locale.trim();

      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
  }
}
