import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { MainContainerComponent } from './_controls/container/main-container.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeComponent } from './pages/home.component';

import { InquiryAdditionalComponent } from './pages/inquiry/inquiry-additional.component';
import { EventListComponent } from './pages/event/event-list.component';
import { FacilityDetailComponent } from './pages/facility/facility-details.component';
import { SeniorCareListComponent } from './pages/facility/senior-care-list.component';
import { CareGiverDetailComponent } from './pages/caregiver/caregiver-details.component';
import { CareGiverListExtendComponent } from './pages/caregiver/caregiver-list-extend.component';
import { CareGiverListComponent } from './pages/caregiver/caregiver-list.component';
import { CareManagerDetailComponent } from './pages/caremanager/caremanager-details.component';
import { CareManagerListExtendComponent } from './pages/caremanager/caremanager-list-extend.component';
import { CareManagerListComponent } from './pages/caremanager/caremanager-list.component';
import { PatientFacilityComponent } from './pages/facility/patient-facility.component';
import { SeniorPlacementListComponent } from './pages/senior-placement/senior-placement-list.component';
import { SeniorPlacementListExtendComponent } from './pages/senior-placement/senior-placement-list-extend.component';
import { SeniorPlacementDetailComponent } from './pages/senior-placement/senior-placement-details.component';
import { HomeVer2Component } from './pages/home-ver2.component';
import { DirectoryContainerComponent } from './pages/directory/directory-container.component';
import { CompanyDetailsComponent } from './pages/directory/details/company-details.component';
import { EventDetailsPageComponent } from './pages/event/event-view-page.component';
import { JobadComponent } from './pages/job/list/jobad.component';
import { JobadDetailsComponent } from './pages/job/details/jobad-details.component';
import { WantadComponent } from './pages/wanted/list/wantad.component';
import { WantadDetailsComponent } from './pages/wanted/details/wantad-details.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'event/:id',
    component: EventDetailsPageComponent,
    data: {
      title: 'Event Detail',
    }
  },
  {
    path: '',
    component: MainContainerComponent,
    data: {
      title: 'Care Mom Dad'
    },
    children: [
      {
        path: 'home2',
        component: HomeComponent,
        data: {
          title: 'Home2'
        }
      },
      {
        path: 'home',
        component: HomeVer2Component,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'patient-facility',
        component: PatientFacilityComponent,
        data: {
          title: 'Patient Facility'
        }
      },
      {
        path: 'inquiry-additional',
        component: InquiryAdditionalComponent,
        data: {
          title: 'Inquiry Additional Info'
        }
      },
      {
        path: 'caremanagers',
        component: CareManagerListComponent,
        data: {
          title: 'Care Managers'
        }
      },
      {
        path: 'jobs',
        component: JobadComponent,
        data: {
          title: 'Jobs'
        }
      },
      {
        path: 'jobs/:id',
        component: JobadDetailsComponent,
        data: {
          title: 'Details'
        }
      },

      {
        path: 'wanted',
        component: WantadComponent,
        data: {
          title: 'Wanted'
        }
      },
      {
        path: 'wanted/:id',
        component: WantadDetailsComponent,
        data: {
          title: 'Details'
        }
      },


      {
        path: 'caremanagers-search',
        component: CareManagerListExtendComponent,
        data: {
          title: 'Care Managers'
        }
      },
      {
        path: 'caremanager/:id',
        component: CareManagerDetailComponent,
        data: {
          title: 'Care Manager Details'
        }
      },

      {
        path: 'caregivers',
        component: CareGiverListComponent,
        data: {
          title: 'Caregivers'
        }
      },
      {
        path: 'caregivers-search',
        component: CareGiverListExtendComponent,
        data: {
          title: 'Caregivers'
        }
      },
      {
        path: 'caregiver/:id',
        component: CareGiverDetailComponent,
        data: {
          title: 'Care Giver Details'
        }
      },

      {
        path: 'senior-placements',
        component: SeniorPlacementListComponent,
        data: {
          title: 'Placement Agents'
        }
      },
      {
        path: 'senior-placements-search',
        component: SeniorPlacementListExtendComponent,
        data: {
          title: 'Placement Agents'
        }
      },
      {
        path: 'senior-placement/:id',
        component: SeniorPlacementDetailComponent,
        data: {
          title: 'Placement Agent Detail'
        }
      },

      {
        path: 'communities',
        component: SeniorCareListComponent,
        data: {
          title: 'Communities'
        }
      },
      {
        path: 'community/:id',
        component: FacilityDetailComponent,
        data: {
          title: 'Facility Detail',
        }
      },

      {
        path: 'events',
        component: EventListComponent,
        data: {
          title: 'Events'
        }
      },
      {
        path: 'directory',
        component: DirectoryContainerComponent,
        data: {
          title: 'Directory'
        },
      },
      {
        path: 'directory/:id',
        component: CompanyDetailsComponent,
        data: {
          title: 'Details'
        }
      },
      {
        path: 'cart',
        component: CartComponent,
        data: {
          title: 'Cart'
        },
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        data: {
          title: 'Checkout'
        },
      },
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}
