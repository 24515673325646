import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';
import { MediaService } from 'app/_core/api-access/media.service';
import { Facility } from 'app/_core/models';

@Component({
  selector: 'app-facility-room',
  templateUrl: './facility-room.component.html'
})
export class FacilityRoomComponent  extends BaseComponent implements OnInit {
  @Input() facility: Facility;

  constructor(
    private mediaService: MediaService,
  ) {
    super();
  }

  // Methods implement
  ngOnInit() {}

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }
}
