import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { BaseService } from './base.service'

@Injectable({
  providedIn: 'root',
})
export class MediaService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getImageUrl(guid: string): string {
    if(!guid)
      return "assets/images/temp/thumb-default.png"
    return this.baseUrlCare + `/content/image/${guid}`;
  }
}
