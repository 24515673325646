import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { FormService } from 'app/_core/api-access/form.service';
import { GlobalService } from 'app/_core/api-access/global.service';
import { FacilityFilter, Inquiry, TargetCustomerType } from 'app/_core/models';
import { ErCity, ErCounty, ErZipcode } from 'app/_core/models';
import { GeoType, InquiryPage } from 'app/_core/predefined/enums';
import { MiscService } from 'app/_core/utilities/misc.service';

// RxJs
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent extends BaseActComponent implements OnInit {
  public submitted = false;
  public listCustomerType = TargetCustomerType;
  minDate: Date;
  maxDate: Date;

  // Form search
  public readonly geoType = GeoType;
  public searchField: FormControl;
  public isShowLocationSuggestion: boolean = false;

  // Data counties
  private countiesSubject = new BehaviorSubject<ErCounty[]>([]);
  private countiesData: { data: ErCounty[] } = { data: [] };
  public readonly counties$ = this.countiesSubject.asObservable();

  // Data cities
  private citiesSubject = new BehaviorSubject<ErCity[]>([]);
  private citiesData: { data: ErCity[] } = { data: [] };
  public readonly cities$ = this.citiesSubject.asObservable();

  // Data zipCodes
  private zipCodesSubject = new BehaviorSubject<ErZipcode[]>([]);
  private zipCodesData: { data: ErZipcode[] } = { data: [] };
  public readonly zipCodes$ = this.zipCodesSubject.asObservable();

  // Time ranges
  public hoursRange: string[];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private globalService: GlobalService,
    private miscService: MiscService) {
    super();
    this.minDate = new Date();
    this.maxDate = new Date('3000-01-01');
  }

  ngOnInit(): void {
    // Load data
    this.hoursRange = this.miscService.getHours();

    // Process form
    this.buildForm();
    this.setFormData();

    // Form search
    this.searchField = new FormControl();
    this.searchField.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(term => {
        // Call api search geos
        this.globalService.searchGeos(term).subscribe(result => {
          if (result.Counties || result.Cities || result.Zipcodes)
            this.isShowLocationSuggestion = true;
          // Set data counties
          this.countiesData.data = result.Counties && result.Counties.length ? result.Counties : null;
          this.countiesSubject.next(Object.assign({}, this.countiesData).data);

          // Set data cities
          this.citiesData.data = result.Cities && result.Cities.length ? result.Cities : null;
          this.citiesSubject.next(Object.assign({}, this.citiesData).data);

          // Set data Zipcodes
          this.zipCodesData.data = result.Zipcodes && result.Zipcodes.length ? result.Zipcodes : null;
          this.zipCodesSubject.next(Object.assign({}, this.zipCodesData).data);
        });
      });
  }

  private objMapForm(): Inquiry {
    let obj: Inquiry = new Inquiry();
    obj.TargetCustomerType = this.formAct.controls["TargetCustomerType"].value;
    obj.Name = this.formAct.controls.Name.value;
    obj.PhoneNumber = this.formAct.controls.PhoneNumber.value;
    obj.EmailAddress = this.formAct.controls.EmailAddress.value;
    obj.Notes = this.formAct.controls.Notes.value;
    // obj.Location = this.formAct.controls.Location.value;

    // Populate schedule date with time range
    if(this.formAct.controls.MeetingReqFor.value) {
      const date = new Date(this.formAct.controls.MeetingReqFor.value);
      date.setMinutes(0);
      date.setSeconds(0);

      const hour = +this.formAct.controls.MeetingTimeRange.value;
      date.setHours(hour);

      // Final assign to schedule metting time
      obj.MeetingReqFor = this.miscService.dateLocalToUtc(date);
    }
    obj.InquiryPage = InquiryPage.HomeCMD;
    return obj;
  }

  private setFormData() {
    this.formAct.controls["TargetCustomerType"].setValue("Loved_One");
  }
  // Build form action
  private buildForm() {
    this.formAct = this.formBuilder.group({
      TargetCustomerType: ['', Validators.required],
      Name: [null, [Validators.required, Validators.maxLength(200)]],
      PhoneNumber: [null, [Validators.required, Validators.maxLength(50)]],
      EmailAddress: [null, [Validators.required, Validators.maxLength(200)]],
      Notes: [null, Validators.required],
      // Location: [null, [Validators.required, Validators.maxLength(200)]],
      MeetingReqFor: [null, Validators.required],
      MeetingTimeRange: [null, Validators.required]
    }, {});

    // Validations message
    this.formValidations = {
      'TargetCustomerType': [
        { type: 'required', message: 'You must select one option' },
      ],
      'Name': [
        { type: 'required', message: 'Name is required' },
        { type: 'maxlength', message: 'Name can\'t be more than 200 characters long' },
      ],
      'PhoneNumber': [
        { type: 'required', message: 'Phone Number is required' },
        { type: 'maxlength', message: 'Phone Number can\'t be more than 50 characters long' },
      ],
      'EmailAddress': [
        { type: 'required', message: 'Email Address is required' },
        { type: 'maxlength', message: 'Email Address can\'t be more than 200 characters long' },
      ],
      'Notes': [
        { type: 'required', message: 'Notes is required' },
        { type: 'maxlength', message: 'Notes can\'t be more than 200 characters long' },
      ],
      'MeetingReqFor': [
        { type: 'required', message: 'Schedule Meeting is required' },
      ],
      'MeetingTimeRange': [
        { type: 'required', message: 'Meeting Time Range is required' },
      ],
      /*
      'Location': [
        { type: 'required', message: 'Location is required' },
        { type: 'maxlength', message: 'Location can\'t be more than 200 characters long' },
      ]
      */
    }
  }

  onSubmitContact() {
    this.submitted = true;

    // Form invalid
    if (!this.formAct.valid)
      return;

    // Form is valid
    this.formService.sendInquiry(this.objMapForm())
      .pipe(first())
      .subscribe(
        (obj) => {
          this.formAct.reset();
          // Reset submit
          this.submitted = false;
          this.router.navigate([this.path.Patient_Facility]);
        },
        () => {
          // Reset submit
          this.submitted = false;
        });
  }

  onSearchLocations() {
    this.isShowLocationSuggestion = true;
  }

  fillDataForSearch(data: any, geoType: GeoType) {
    let facilityFilter: FacilityFilter = new FacilityFilter();
    facilityFilter.GeoType = geoType;
    facilityFilter.GeoId = data.Id;
    facilityFilter.GeoName = data.Name;

    if (geoType === this.geoType.County) {
      facilityFilter.GeoStateId = data.State ? data.State.Id : null;
      facilityFilter.GeoStateName = data.State ? data.State.Name : "";
    }

    if (geoType === this.geoType.City) {
      facilityFilter.GeoStateId = data.County ? data.County.State.Id : null;
      facilityFilter.GeoStateName = data.County ? data.County.State.Name : "";

      facilityFilter.GeoCountyId = data.County ? data.County.Id : null;
      facilityFilter.GeoCountyName = data.County ? data.County.Name : "";
    }

    if (geoType === this.geoType.Zip_Code) {
      facilityFilter.GeoStateId = data.City ? data.City.County.State.Id : null;
      facilityFilter.GeoStateName = data.City ? data.City.County.State.Name : "";

      facilityFilter.GeoCountyId = data.City ? data.City.County.Id : null;
      facilityFilter.GeoCountyName = data.City ? data.City.County.Name : "";

      facilityFilter.GeoCityId = data.City ? data.City.Id : null;
      facilityFilter.GeoCityName = data.City ? data.City.Name : "";
    }
  }
}
