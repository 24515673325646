import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'dateISO8601'
})
export class DateISO8601Pipe extends DatePipe implements PipeTransform {
  transform(value: any, format?: any): string {
    if(!format) {
      format = 'MM/dd/yyyy';
    }
    return super.transform(value, format);
 }
}
