import { Component, Input, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';

import { MediaService } from 'app/_core/api-access/media.service';
import { ActivatedRoute } from '@angular/router';
import { ErJobad } from '../../../_core/models/ads/ErJobad';
import { JobadService } from 'app/_core/api-access/jobad.service';

@Component({
  selector: 'app-jobad-related-list',
  templateUrl: './jobad-related-list.component.html'
})

export class JobadRelatedListComponent extends BaseActComponent implements OnInit {

  @Input() erObjectId: number;
  @Input() erObjectType: string;

  public jobads: ErJobad[];
  public subcategoryId: number = 0;

  constructor(
    private mediaService: MediaService,
    private activatedRoute: ActivatedRoute,
    private jobadService: JobadService,
  ) {
    super();
  }

  private getCurrentAds() {
    this.jobadService.getCurrent().subscribe(result => this.jobads = result.filter(job => job.ErObjectId === this.erObjectId && job.ErObjectType === this.erObjectType));
  }

  ngOnInit(): void {
    this.getCurrentAds();
    this.activatedRoute.queryParams.subscribe(params => {
      this.subcategoryId = params['subcategoryId'];
    })
  };

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }
}
