import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe extends DecimalPipe implements PipeTransform {
  transform(value: any, format?: any): string {
    if (!format) {
      format = '1.2-2';
    }
    return value && !isNaN(value) ? super.transform(value, format): '0';
  }

  toNumber(value: any, format?: any): number {
    const valueFormat: string = this.transform(value, format);
    return valueFormat ? +(valueFormat.replace(',', '')) : 0;
  }
}
