import { Component} from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';

@Component({
  templateUrl: './blank-container.component.html'
})
export class BlankContainerComponent extends BaseComponent {
  constructor() {
    super();
  }
}
