import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

// RxJS
import { Observable } from 'rxjs'
import { BaseService } from './base.service'

// Models
import { ErSite } from '../predefined/enums'
import { ErJobad } from '../models/ads/ErJobad'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})

export class JobadService extends BaseService {
  constructor(
    private http: HttpClient,
  ) {
    super()
  }

  getById(id: number): Observable<ErJobad> {
    let params: HttpParams = new HttpParams()
      .set("viewType", ErSite.CareMomDad);
    const apiUrl = this.baseUrlCare + `/jobad/${id}`;
    return this.http.get<ErJobad>(apiUrl, { params: params, withCredentials: true }).pipe(map(result => {
      return new ErJobad(result);
    }));
  }

  getCurrent(): Observable<ErJobad[]> {
    let params: HttpParams = new HttpParams()
      .set("site", ErSite.CareMomDad);
    const apiUrl = this.baseUrlCare + `/jobads/public`;
    return this.http.get<ErJobad[]>(apiUrl, { params: params, withCredentials: true }).pipe(map(result => {
      if (!result) return null;
      let jobads = [];
      result.forEach(x => jobads.push(new ErJobad(x)));
      return jobads;
    }));
  }
}
