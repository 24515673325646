import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { GlobalService } from 'app/_core/api-access/global.service';
import { ErCity, ErCounty, ErZipcode, ListOfValues, SeniorPlacementFilter, Language } from 'app/_core/models';
import { DISTANCES } from 'app/_core/predefined/consts';
import { GeoType } from 'app/_core/predefined/enums';
import { MiscService } from 'app/_core/utilities/misc.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
@Component({
  selector: 'app-senior-placement-filter-extend',
  templateUrl: './senior-placement-filter-extend.component.html',
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})

export class SeniorPlacementFilterExtendComponent extends BaseActComponent implements OnInit {
  @Input() obj: SeniorPlacementFilter;
  @Input() globalData: ListOfValues;
  @Output() filter = new EventEmitter<SeniorPlacementFilter>();

  // Form search
  public readonly geoType = GeoType;
  public isShowLocationSuggestion: boolean = false;

  private locationSubject = new BehaviorSubject<any>(null);
  private locationData: { Counties: ErCounty[], Cities: ErCity[], Zipcodes: ErZipcode[] } = null;
  public readonly location$ = this.locationSubject.asObservable();

  // Helper
  public readonly distances = DISTANCES;

  get languagesForm() {
    return this.formAct.get('languagesForm') as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private miscService: MiscService,
  ) {
    super();
  }

  private objMapForm() {
    let obj: SeniorPlacementFilter = new SeniorPlacementFilter();

    obj.Name = this.formAct.controls[this.miscService.getPropertyName(this.obj, (o: SeniorPlacementFilter) => o.Name)].value;

    obj.GeoId = +this.formAct.controls[this.miscService.getPropertyName(this.obj, (o: SeniorPlacementFilter) => o.GeoId)].value;
    obj.GeoName = this.formAct.controls[this.miscService.getPropertyName(this.obj, (o: SeniorPlacementFilter) => o.GeoName)].value;
    obj.GeoType = this.formAct.controls[this.miscService.getPropertyName(this.obj, (o: SeniorPlacementFilter) => o.GeoType)].value;
    obj.Distmi = this.formAct.controls[this.miscService.getPropertyName(this.obj, (o: SeniorPlacementFilter) => o.Distmi)].value;

    // Form array languages
    const languages: Language[] = [];
    const controlLanguages = this.languagesForm.controls;
    for (let i = 0; i < controlLanguages.length; i++) {
      if (controlLanguages[i].value)
        languages.push(this.globalData.Languages[i]);
    }
    obj.Languages = languages;

    return obj;
  }

  // Build form action
  private buildForm() {
    const formGroup: FormGroup = this.formBuilder.group({});

    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: SeniorPlacementFilter) => o.Name), new FormControl(null));

    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: SeniorPlacementFilter) => o.GeoId), new FormControl(null));
    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: SeniorPlacementFilter) => o.GeoType), new FormControl(null));
    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: SeniorPlacementFilter) => o.GeoName), new FormControl(null));
    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: SeniorPlacementFilter) => o.Distmi), new FormControl(null));

    // Form arrays
    formGroup.addControl('languagesForm', new FormArray([]));

    this.formAct = formGroup;
  }

  ngOnInit(): void {
    this.buildForm();
    this.setFormData(this.obj);

    this.formAct.controls.GeoName.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(term => {
        this.isShowLocationSuggestion = false;
        // Remove params filter selected
        if (!term || term.trim() === '') {
          this.obj.GeoId = null;
          this.obj.GeoType = null;
          this.obj.GeoName = null;
          this.formAct.controls['GeoId'].setValue(null);
          this.formAct.controls['GeoType'].setValue(null);
          this.formAct.controls['GeoName'].setValue(null);
          return;
        }
        // Call api search geos
        this.globalService.searchGeos(term).pipe(first()).subscribe(result => {
          if (result.Counties || result.Cities || result.Zipcodes)
            this.isShowLocationSuggestion = true;

          this.locationData = result;
          this.locationSubject.next(Object.assign({}, this.locationData));
        });
      });
  }


  private setFormData(obj: SeniorPlacementFilter = null) {
    // Stop when form is null
    if (!this.formAct || !obj) return;

    this.formAct.controls[this.miscService.getPropertyName(obj, (o: SeniorPlacementFilter) => o.Name)].setValue(obj.Name);

    this.formAct.controls[this.miscService.getPropertyName(obj, (o: SeniorPlacementFilter) => o.GeoId)].setValue(obj.GeoId);
    this.formAct.controls[this.miscService.getPropertyName(obj, (o: SeniorPlacementFilter) => o.GeoType)].setValue(obj.GeoType);
    this.formAct.controls[this.miscService.getPropertyName(obj, (o: SeniorPlacementFilter) => o.GeoName)].setValue(obj.GeoName);
    this.formAct.controls[this.miscService.getPropertyName(obj, (o: SeniorPlacementFilter) => o.Distmi)].setValue(obj.Distmi || 25);

    // Controls language
    const languages = this.globalData.Languages || [];
    for (let i = 0; i < languages.length; i++) {
      const indexFound = obj.Languages.findIndex(l => l.Id === languages[i].Id);
      const control = new FormControl(indexFound > -1);
      this.languagesForm.push(control);
    }
  }

  onFilter() {
    if (!this.formAct.valid) return;

    // Keep selected data
    const geoId = this.obj.GeoId;
    const geoType = this.obj.GeoType;
    const geoName = this.obj.GeoName;
    const geoCityId = this.obj.GeoCityId;
    const geoCityName = this.obj.GeoCityName;
    const geoCountyId = this.obj.GeoCountyId;
    const geoCountyName = this.obj.GeoCountyName;
    const geoStateId = this.obj.GeoStateId;
    const geoStateName = this.obj.GeoStateName;

    const objChanged = Object.assign(this.obj, this.objMapForm());
    objChanged.GeoId = geoId;
    objChanged.GeoType = geoType;
    objChanged.GeoName = geoName;
    objChanged.GeoCityId = geoCityId;
    objChanged.GeoCityName = geoCityName;
    objChanged.GeoCountyId = geoCountyId;
    objChanged.GeoCountyName = geoCountyName;
    objChanged.GeoStateId = geoStateId;
    objChanged.GeoStateName = geoStateName;

    this.filter.emit(objChanged);
  }

  onSearchLocations() {
    this.isShowLocationSuggestion = true;
  }

  fillDataForSearch(data: any, geoType: GeoType) {
    //To avoid the valuechanges to go off, when false: no events are emitted
    this.formAct.controls[this.miscService.getPropertyName(this.obj, (o: SeniorPlacementFilter) => o.GeoName)].setValue(data.Name, { emitEvent: false });

    this.obj = Object.assign(this.obj, this.objMapForm());

    // Keep selected data
    this.obj.GeoId = data.Id;
    this.obj.GeoType = geoType;
    this.obj.GeoName = data.Name;

    // Set related properties from selected
    if (geoType === this.geoType.County) {
      this.obj.GeoStateId = data.State ? data.State.Id : null;
      this.obj.GeoStateName = data.State ? data.State.Name : "";
    }

    if (geoType === this.geoType.City) {
      this.obj.GeoStateId = data.County ? data.County.State.Id : null;
      this.obj.GeoStateName = data.County ? data.County.State.Name : "";

      this.obj.GeoCountyId = data.County ? data.County.Id : null;
      this.obj.GeoCountyName = data.County ? data.County.Name : "";
    }

    if (geoType === this.geoType.Zip_Code) {
      this.obj.GeoStateId = data.City ? data.City.County.State.Id : null;
      this.obj.GeoStateName = data.City ? data.City.County.State.Name : "";

      this.obj.GeoCountyId = data.City ? data.City.County.Id : null;
      this.obj.GeoCountyName = data.City ? data.City.County.Name : "";

      this.obj.GeoCityId = data.City ? data.City.Id : null;
      this.obj.GeoCityName = data.City ? data.City.Name : "";
    }

    this.isShowLocationSuggestion = false;

    // Clear all data after selected
    this.locationSubject.next(null);
  }

  onFocusGeoName(event: any) {
    this.isShowLocationSuggestion = true;
  }

  onFocusOutGeoName(event: any) {
    this.isShowLocationSuggestion = false;
  }
}
