import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../utilities/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const errorInfo =  err.error ? err.error.message : err.statusText;
          if (err.error instanceof ErrorEvent) {
              // A client-side or network error occurred. Handle it accordingly.
              console.error('Error communicating with the server:', err.error.message);
              this.toastService.showError(err.error.message);
          } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              let explanation: string = `Backend returned code ${err.status} ${err.statusText}. Body was: ${JSON.stringify(err.error)}`;

              if ([0].indexOf(err.status) !== -1) {
                  // Unknown Error
                  this.toastService.showError('An unknown error occurred processing your request', explanation);
              } else if (err.error && [401].indexOf(err.status) !== -1) {
                  if (typeof err.error === 'object' && err.error.ErrorMessage)
                      this.toastService.showAlert('Authorization error: ' + err.error.ErrorMessage);
                  else
                      this.toastService.showAlert('There was a problem with a value you entered: ' + err.error);
                  // this.authService.logout();
              } else if (err.error && [400, 404].indexOf(err.status) !== -1) {
                  if (typeof err.error === 'object' && err.error.ErrorMessage)
                      this.toastService.showAlert('There was a problem with a value you entered: ' + err.error.ErrorMessage);
                  else if (typeof err.error === 'object' && err.error.title)
                      this.toastService.showAlert('There was a problem with a value you entered: ' + err.error.title);
                  else
                      this.toastService.showAlert('There was a problem with a value you entered: ' + err.error);
                  // Problem with the values provided by the user; let the UI handle it instead of showing an error.
              } else if (err.error && [403].indexOf(err.status) !== -1) {
                  if (typeof err.error === 'object' && err.error.ErrorMessage)
                      this.toastService.showError('Permission error: ' + err.error.ErrorMessage);
                  else
                      this.toastService.showError('Permission error: ' + err.error);
                  // Problem with the values provided by the user; let the UI handle it instead of showing an error.
              } else {
                  this.toastService.showError('An error occurred processing your request', explanation);
              }
          }

        return throwError(errorInfo);
      }))
  }
}
