//import { maxLength, minLength, minNumber, prop, required } from "@rxweb/reactive-form-validators";
import { Base } from "../base";

export class ErEventQuestion extends Base {

  constructor(obj: ErEventQuestion = null) {
    super(obj);

    if (!obj) return;

  }

  ErEventId: number;

  //@required()
  //@maxLength({value:200})
  Name: string;

  //@prop()
  Description: string;

  //@required()
  Type: ErEventQuestionDataType;

  //@prop()
  Options: string;

  get OptionsList(): string[] {
    if (!this.Options) return null;
    let optionList = this.Options.split(",");
    for (let i = 0; i < optionList.length; i++)
      optionList[i] = optionList[i].trim();
    return optionList;
    }

    //Used within the UI only
    Answer: string;
}


export enum ErEventQuestionDataType {
  Text = "Text",
  Checkbox = "Checkbox",
  Dropdown = "Drop-down Option Menu"
}
