import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { GlobalService } from 'app/_core/api-access/global.service';
import { CompanyFilter, ErCity, ErCounty, ErZipcode } from 'app/_core/models';
import { ListOfValues } from 'app/_core/models/global';
import { DISTANCES } from 'app/_core/predefined/consts';
import { GeoType } from 'app/_core/predefined/enums';
import { MiscService } from 'app/_core/utilities/misc.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
@Component({
  selector: 'app-company-filter',
  templateUrl: './company-filter.component.html',
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})

export class CompanyFilterComponent extends BaseActComponent implements OnInit {
  @Input() obj: CompanyFilter;
  @Output() filter = new EventEmitter<CompanyFilter>();

  // Helper
  public readonly distances = DISTANCES;

  // Form search
  public readonly geoType = GeoType;
  public isShowLocationSuggestion: boolean = false;

  private locationSubject = new BehaviorSubject<any>(null);
  private locationData: { Counties: ErCounty[], Cities: ErCity[], Zipcodes: ErZipcode[] } = null;
  public readonly location$ = this.locationSubject.asObservable();

  constructor(
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private miscService: MiscService,
    private router: Router,
  ) {
    super();
  }

  private objMapForm() {
    const obj: CompanyFilter = new CompanyFilter();

    obj.GeoId = +this.formAct.controls.GeoId.value;
    obj.GeoType = this.formAct.controls.GeoType.value;
    obj.GeoName = this.formAct.controls.GeoName.value;
    obj.Name = this.formAct.controls.Name.value;

    obj.Distmi = this.formAct.controls.Distmi.value;

    return obj;
  }

  // Build form action
  private buildForm() {
    const formGroup: FormGroup = this.formBuilder.group({});
    this.formAct = this.formBuilder.group({
      GeoId: [null],
      GeoType: [null],
      Name: [null],
      GeoName: [null],
      Distmi: [null]
    });
  }

  ngOnInit(): void {
    this.buildForm();
    this.setFormData(this.obj);

    this.formAct.controls.GeoName.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((term: string) => {
        this.isShowLocationSuggestion = false;

        // Remove params filter selected
        if (!term || term.trim() === '') {
          this.obj.GeoId = null;
          this.obj.GeoType = null;
          this.obj.GeoName = null;
          this.formAct.controls['GeoId'].setValue(null);
          this.formAct.controls['GeoType'].setValue(null);
          this.formAct.controls['GeoName'].setValue(null);
          return;
        }

        // Call api search geos
        this.globalService.searchGeos(term).pipe(first()).subscribe(result => {
          if (result.Counties || result.Cities || result.Zipcodes)
            this.isShowLocationSuggestion = true;

          this.locationData = result;
          this.locationSubject.next(Object.assign({}, this.locationData));
        });
      });
  }


  private setFormData(obj: CompanyFilter = null) {

    // Stop when form is null
    if (!this.formAct || !obj) return;

    this.formAct.controls['GeoId'].setValue(obj.GeoId);
    this.formAct.controls['GeoType'].setValue(obj.GeoType);
    this.formAct.controls['GeoName'].setValue(obj.GeoName);
    this.formAct.controls['Name'].setValue(obj.Name);

    this.formAct.controls['Distmi'].setValue(obj.Distmi || 25);
  }

  onFilter() {
    if (!this.formAct.valid) return;

    // console.log('this.obj :>> ', this.obj);
    // Keep selected data
    const geoId = this.obj.GeoId;
    const geoType = this.obj.GeoType;
    const geoName = this.obj.GeoName;
    const geoCityId = this.obj.GeoCityId;
    const geoCityName = this.obj.GeoCityName;
    const geoCountyId = this.obj.GeoCountyId;
    const geoCountyName = this.obj.GeoCountyName;
    const geoStateId = this.obj.GeoStateId;
    const geoStateName = this.obj.GeoStateName;
    const subcategoryId = this.obj.SubcategoryId;

    const objChanged = Object.assign(this.obj, this.objMapForm());
    objChanged.GeoId = geoId;

    // Set zero, undefined to null
    if(!objChanged.GeoId)
      objChanged.GeoId = null;

    // Reset geo selected if GeoId is null
    if(objChanged.GeoId) {
      objChanged.GeoType = geoType;
      objChanged.GeoName = geoName;
      objChanged.GeoCityId = geoCityId;
      objChanged.GeoCityName = geoCityName;
      objChanged.GeoCountyId = geoCountyId;
      objChanged.GeoCountyName = geoCountyName;
      objChanged.GeoStateId = geoStateId;
      objChanged.GeoStateName = geoStateName;
    }

    objChanged.SubcategoryId = subcategoryId;

    this.filter.emit(objChanged);
  }

  onSearchLocations() {
    this.isShowLocationSuggestion = true;
  }

  fillDataForSearch(data: any, geoType: GeoType) {
    //To avoid the valuechanges to go off, when false: no events are emitted
    this.formAct.controls.GeoName.setValue(data.Name, { emitEvent: false });

    this.obj = Object.assign(this.obj, this.objMapForm());

    // Keep selected data
    this.obj.GeoId = data.Id;
    this.obj.GeoType = geoType;
    this.obj.GeoName = data.Name;

    // Set related properties from selected
    if (geoType === this.geoType.County) {
      this.obj.GeoStateId = data.State ? data.State.Id : null;
      this.obj.GeoStateName = data.State ? data.State.Name : "";
    }

    if (geoType === this.geoType.City) {
      this.obj.GeoStateId = data.County ? data.County.State.Id : null;
      this.obj.GeoStateName = data.County ? data.County.State.Name : "";

      this.obj.GeoCountyId = data.County ? data.County.Id : null;
      this.obj.GeoCountyName = data.County ? data.County.Name : "";
    }

    if (geoType === this.geoType.Zip_Code) {
      this.obj.GeoStateId = data.City ? data.City.County.State.Id : null;
      this.obj.GeoStateName = data.City ? data.City.County.State.Name : "";

      this.obj.GeoCountyId = data.City ? data.City.County.Id : null;
      this.obj.GeoCountyName = data.City ? data.City.County.Name : "";

      this.obj.GeoCityId = data.City ? data.City.Id : null;
      this.obj.GeoCityName = data.City ? data.City.Name : "";
    }

    this.isShowLocationSuggestion = false;

    // Clear all data after selected
    this.locationSubject.next(null);
  }

  onFocusGeoName(event: any) {
    this.isShowLocationSuggestion = true;
  }

  onFocusOutGeoName(event: any) {
    this.isShowLocationSuggestion = false;
  }

  onClearFilter() {
    this.router.navigate([this.path.Directory]);
  }
}
