import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { FormService } from 'app/_core/api-access/form.service';
import { GlobalService } from 'app/_core/api-access/global.service';
import { Inquiry, ListUrgency, ListOfValues } from 'app/_core/models';
import { ToastService } from 'app/_core/utilities/toast.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-patient-facility',
  templateUrl: './patient-facility.component.html'
})

export class PatientFacilityComponent extends BaseActComponent implements OnInit {
  public inquiry: Inquiry;
  public listUrgency = ListUrgency;
  public inquiry$: Observable<Inquiry>;
  public globalData: ListOfValues;

  constructor(
    private formService: FormService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastService: ToastService,
    private globalService: GlobalService,
  ) {
    super();
  }

  get objMapForm() {
    let obj: Inquiry = new Inquiry();
    obj.InquiryGuid = this.inquiry.InquiryGuid;
    obj.ErFacilityId = this.inquiry.ErFacilityId;
    obj.TargetCustomerType = this.inquiry.TargetCustomerType;
    obj.Name = this.inquiry.Name;
    obj.PhoneNumber = this.inquiry.PhoneNumber;
    obj.EmailAddress = this.inquiry.EmailAddress;
    obj.Location = this.inquiry.Location;
    obj.MeetingReqFor = this.inquiry.MeetingReqFor;

    obj.UseWearableTech = this.formAct.controls.UseWearableTech.value;
    obj.HealthConditions = this.formAct.controls.HealthConditions.value;
    obj.Languages = this.formAct.controls.Languages.value;
    obj.IsAmbulatory = this.formAct.controls.IsAmbulatory.value;
    obj.ErRoomtypeId = this.formAct.controls.ErRoomtypeId.value;
    obj.Urgency = this.formAct.controls.Urgency.value;

    return obj;
  }

  // Build form action
  private buildForm() {
    this.formAct = this.formBuilder.group({
      UseWearableTech: [null],
      HealthConditions: [null, Validators.maxLength(1000)],
      Languages: [null],
      IsAmbulatory: [null],
      ErRoomtypeId: [null],
      Urgency: [null],
    }, {});

    // Validations message
    this.formValidations = {
    }
  }

  private setFormData() {
    this.formAct.controls['UseWearableTech'].setValue(true);
    this.formAct.controls['IsAmbulatory'].setValue(true);
  }

  loadGlobal() {
    if (this.globalService.getCacheGlobal())
      this.globalData = this.globalService.getCacheGlobal();
    else
      this.globalService.getGlobal().pipe(first()).subscribe(global => {
        this.globalData = global;
        this.globalService.setCacheGlobal(global);
      });
  }
  ngOnInit(): void {
    this.buildForm();
    this.inquiry$ = this.formService.inquiryObs;
    this.inquiry$.subscribe(result => {
      this.inquiry = result;
      if (!this.inquiry)
        this.router.navigate([this.path.Home])
    });
    this.loadGlobal();

    this.setFormData();
  }

  onSubmitContactP2() {
    this.submitted = true;

    // Form invalid
    if (!this.formAct.valid)
      return;

    // Form is valid
    this.formService.sendInquiryPart2(this.objMapForm)
      .pipe(first())
      .subscribe(
        () => {
          // Alert message success
          this.toastService.showSuccess('Inquiry sent successfully');

          if (this.objMapForm.ErFacilityId)
            this.router.navigate([this.path.Facility_Detail, this.objMapForm.ErFacilityId])
          else
            this.router.navigate([this.path.Home])
          // Reset submit
          this.submitted = false;
        },
        () => {
          // Reset submit
          this.submitted = false;
        });
  }
}
