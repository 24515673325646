// import { maxLength, minLength, minNumber, prop, required } from "@rxweb/reactive-form-validators";
import { Base } from "../base";

export class ErEventRegistrantDetail extends Base {

  //@required()
  //@maxLength({value:200})
  FirstName: string;

  //@required()
  //@maxLength({ value: 200 })
  LastName: string;

  //@maxLength({ value: 200 })
  CompanyName: string;

  //@required()
  //@maxLength({ value: 200 })
  EmailAddress: string;

  //@maxLength({ value: 200 })
  PhoneMobile: string;


  //Computed
  Name: string;


}

