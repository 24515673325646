export class Base {
    Id: number;
    CreatedAt: string;
    UpdatedAt: string;
    CreatedCcUserId: number;
    UpdatedCcUserId: number;

    constructor(obj = null) {
        if (obj) Object.assign(this, obj);
    }
}
