import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';
import { SeniorPlacement } from 'app/_core/models/senior-placement';

@Component({
  selector: 'app-senior-placement-event',
  templateUrl: './senior-placement-event.component.html'
})
export class SeniorPlacementEventComponent extends BaseComponent implements OnInit {
  @Input() seniorPlacement: SeniorPlacement;
  constructor() {
    super();
  }

  // Methods implement
  ngOnInit() { }
}
