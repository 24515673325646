import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

// RxJS
import { Observable } from 'rxjs'
import { BaseService } from './base.service'

// Models
import { ErSite } from '../predefined/enums'
import { ErWantad } from '../models/ads/ErWantad'
import { map } from 'rxjs/operators'
import { ErWantadType } from '../models/ads/ErJobad'

@Injectable({
  providedIn: 'root',
})

export class WantadService extends BaseService {
  constructor(
    private http: HttpClient,
  ) {
    super()
  }

  getById(id: number): Observable<ErWantad> {
    let params: HttpParams = new HttpParams()
      .set("viewType", ErSite.CareMomDad);
    const apiUrl = this.baseUrlCare + `/wantad/${id}`;
    return this.http.get<ErWantad>(apiUrl, { params: params, withCredentials: true }).pipe(map(result => {
      return new ErWantad(result);
    }));
  }

  getCurrent(type:ErWantadType): Observable<ErWantad[]> {
    let params: HttpParams = new HttpParams()
      .set("site", ErSite.CareMomDad)
      .set("type",type);
    const apiUrl = this.baseUrlCare + `/wantads/public`;
    return this.http.get<ErWantad[]>(apiUrl, { params: params, withCredentials: true }).pipe(map(result => {
      if (!result) return null;
      let wantads = [];
      result.forEach(x => wantads.push(new ErWantad(x)));
      return wantads;
    }));
  }
}
