import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

// RxJS
import { BehaviorSubject, Observable, of } from 'rxjs'
import { BaseService } from './base.service'

// Models
import { Facility } from '../models/facility'
import { tap } from 'rxjs/operators'
import { FacilityFilter, PageInfo } from '../models'
import { MiscService } from '../utilities/misc.service'

@Injectable({
  providedIn: 'root',
})
export class FacilityService extends BaseService {

  private facilitiesSubject = new BehaviorSubject<Facility[]>(null);
  private facilitiesData: { data: Facility[] } = { data: [] };
  public readonly facilitiesObs = this.facilitiesSubject.asObservable();

  // Page info
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private pageInfoData: { data: PageInfo } = { data: null };
  public readonly pageInfoObs = this.pageInfoSubject.asObservable();

  constructor(
    private http: HttpClient,
    private miscService: MiscService,
  ) {
    super()
  }

  setPageObs(pageInfo: PageInfo) {
    this.pageInfoData.data = pageInfo;
    this.pageInfoSubject.next(Object.assign({}, this.pageInfoData).data);
  }

  setDataObs(data: Facility[] = []) {
    this.facilitiesData.data = data;
    this.facilitiesSubject.next(Object.assign({}, this.facilitiesData).data);
  }

  searchGeos(term: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('query', term);

    const apiUrl = this.baseUrlCare + `/search/geos`;
    return this.http.get<any>(apiUrl, { params: httpParams, withCredentials: true });
  }

  // Load data & populate
  load(offset: number = null, limit: number = null, filter: FacilityFilter) {
    this.getList(offset, limit, filter).subscribe();
  }

  getList(offset: number = null, limit: number = null, filter: FacilityFilter) {
    const geoId = filter.GeoId;
    const geoType = filter.GeoType;
    const distmi = filter.Distmi || 25;

    const priceMin = filter.PriceMin;
    const priceMax = filter.PriceMax;
    const capacityMin = filter.CapacityMin;
    const capacityMax = filter.CapacityMax;
    const roomTypeId = filter.RoomTypeId;
    const facilityTypeId = filter.FacilityTypeId;
    const facilityName = filter.FacilityName;

    // Array to string comma separated
    const languages: any = filter.Languages ? filter.Languages.map(l => l.Id).join() : null;
    // console.log("getList");
    // console.log("geoId:" + geoId);
    // console.log("geoType:" + geoType);
    // Declare
    let httpParams = new HttpParams();
    if (geoId) {
      // console.log("did get called");
      httpParams = httpParams.set('id', `${geoId}`)
        .set('geotype', `${geoType}`)
        .set('distmi', `${distmi}`);
    }
    // console.log("id:" + httpParams.get('id'));
    // console.log("geotype:" + httpParams.get('geotype'));
    // Other params
    if (priceMin) httpParams = httpParams.set('priceMin', `${priceMin}`);
    if (priceMax) httpParams = httpParams.set('priceMax', `${priceMax}`);
    if (capacityMin) httpParams = httpParams.set('capacityMin', `${capacityMin}`)
    if (capacityMax) httpParams = httpParams.set('capacityMax', `${capacityMax}`);
    if (languages) httpParams = httpParams.set('languages', `${languages}`);
    if (roomTypeId) httpParams = httpParams.set('roomTypeId', `${roomTypeId}`);
    if (facilityTypeId) httpParams = httpParams.set('facilityTypeId', `${facilityTypeId}`);
    if (facilityName) httpParams = httpParams.set('facilityName', `${facilityName}`);

    // Pagination params: offset, limit
    if (offset) httpParams = httpParams.set('offset', `${offset}`);

    // Limit is Page size, items per page
    if (limit) httpParams = httpParams.set('limit', `${limit}`);

    const apiUrl = this.baseUrlCare + `/search/facilities`;
    return this.http.get<Facility[]>(apiUrl, { params: httpParams, withCredentials: true, observe: 'response' }).pipe(tap(response => {
      const totalRecords = +response.headers.get("Total-Records") || 0;
      const facilities: Facility[] = response.body || [];
      const pageNumber = offset / limit + 1;

      const pageInfo: PageInfo = this.miscService.getPager(totalRecords, pageNumber, limit);

      this.setDataObs(facilities);
      this.setPageObs(pageInfo);

      return response;
    }));
  }

  getById(id: number): Observable<Facility> {
    let params: HttpParams = new HttpParams()
      .set("viewType", "CareMomDad");
    const apiUrl = this.baseUrlCare + `/Facility/${id}`;
    return this.http.get<Facility>(apiUrl, { params: params, withCredentials: true });
  }
}
