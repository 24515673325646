import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageKey, Path } from 'app/_core/models';
import { BaseService } from '../api-access/base.service';
import { Router } from '@angular/router';
import { ErUser } from '../models/user';
import { BaseComponent } from '../../_controls/base.component';


@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    super();

    this.CurrentXsrfToken = localStorage.getItem(StorageKey.ErUserXsrfToken);
  }

  
  login(username: string, password: string) {
    
    const apiUrl = `${this.baseUrlCare}/UserPortal/Login`;

    const bodyLogin = {
      EmailAddress: username,
      Password: password
    };
    return this.http.post<ErUser>(apiUrl, bodyLogin, { withCredentials: true })
      .pipe(map(result => {
        if (result) {
          localStorage.setItem(StorageKey.ErUserXsrfToken, result.CurrentXsrfToken);
          this.CurrentXsrfToken = result.CurrentXsrfToken;
          this.CurrentUser = new ErUser(result);
        }
        return result;
      }));
  }

  logout(navigateToHome:boolean=true) {
    // Remove all storage
    localStorage.clear();
    this.CurrentXsrfToken = undefined;
    this.CurrentUser = undefined;
    BaseComponent.CurrentUser = null;
    if (navigateToHome)
      this.router.navigate([Path.Home]);  
  }
}
