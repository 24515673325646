import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'app/_controls/base.component';
import { GlobalService } from '../../_core/api-access/global.service';
import { OfferingService } from '../../_core/api-access/offering.service';
import { CompanyFilter, ListOfValues } from '../../_core/models';

@Component({
  templateUrl: './directory-container.component.html'
})
export class DirectoryContainerComponent extends BaseComponent implements OnInit {

  public companyFilter: CompanyFilter = new CompanyFilter();

  constructor(
    private globalService: GlobalService,
    private route: ActivatedRoute,
    private offeringService: OfferingService,

  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.globalService.getCacheGlobal())
      this.globalService.getGlobal().subscribe(() => { this.companyFilter.CategoryId = this.LOVs.Config.CmdOfferingCategoryId; });

    this.route.queryParamMap.subscribe(params => {
      this.companyFilter.Name = params.get('name');

      // Get filter values from params
      this.companyFilter.GeoId = +params.get('geo_id');
      this.companyFilter.GeoType = params.get('geo_type');
      this.companyFilter.GeoName = params.get('geo_name');

      // TODO: should get it from api, get detail for geotype and id above, or return on result together.
      this.companyFilter.GeoCityId = +params.get("geo_city_id");
      this.companyFilter.GeoCityName = params.get("geo_city_name");
      this.companyFilter.GeoCountyId = +params.get("geo_county_id");
      this.companyFilter.GeoCountyName = params.get("geo_county_name");
      this.companyFilter.GeoStateId = +params.get("geo_state_id");
      this.companyFilter.GeoStateName = params.get("geo_state_name");

      this.companyFilter.Distmi = +params.get("Distmi");

      if (this.LOVs) this.companyFilter.CategoryId = this.LOVs.Config.CmdOfferingCategoryId;
      this.companyFilter.SubcategoryId = +params.get("subcategoryId");

      /*
      // Pagination
      const offset: number = +params.get("page_offset");
      this.pageOffset = offset ? offset : 0;

      const limit: number = +params.get("page_limit");
      this.pageLimit = limit ? limit : 4;
      */
      
      // Load data
      //this.offeringService.load(0, 20, this.companyFilter);
    });
  };
  
  get LOVs(): ListOfValues {
    return this.globalService.getCacheGlobal();
  }

}
