import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';

import { Router } from '@angular/router';
import { AHIOfferingSubcategory, CompanyFilter } from '../../../_core/models';
import { GlobalService } from '../../../_core/api-access/global.service';
@Component({
  selector: 'app-directory-categories',
  templateUrl: './directory-categories.component.html'
})

export class DirectoryCategoriesComponent extends BaseActComponent implements OnInit {

  @Input() companyFilter: CompanyFilter;
  @ViewChild('sidebar', {static: false}) sidebar: ElementRef<HTMLDivElement>;
  public isCollapsed = false;

  constructor(
    private globalService: GlobalService,
    private router: Router,

  ) {
    super();
  }

  ngOnInit(): void {

  };

  get Subcategories(): AHIOfferingSubcategory[]{
    let lovs = this.globalService.getCacheGlobal(); //guaranteed populated by Container
    return lovs.OfferingSubcategories.filter(x => x.AHIOfferingCategoryId === lovs.Config.CmdOfferingCategoryId);
  }


  changeSubcategory(subcategoryId:number) {
    // Redirect to page with filter on side
    const params = {};

    if (this.companyFilter.Name)
      params['name'] = this.companyFilter.Name;

    if (this.companyFilter.GeoId) {
      params['geo_id'] = this.companyFilter.GeoId;
      params['geo_type'] = this.companyFilter.GeoType;
      params['geo_name'] = this.companyFilter.GeoName;
    }

    if (this.companyFilter.GeoCityId) {
      params['geo_city_id'] = this.companyFilter.GeoCityId;
      params['geo_city_name'] = this.companyFilter.GeoCityName;
    }

    if (this.companyFilter.GeoCityId) {
      params['geo_county_id'] = this.companyFilter.GeoCountyId;
      params['geo_county_name'] = this.companyFilter.GeoCountyName;
    }

    if (this.companyFilter.GeoStateId) {
      params['geo_state_id'] = this.companyFilter.GeoStateId;
      params['geo_state_name'] = this.companyFilter.GeoStateName;
    }

    if (this.companyFilter.Distmi)
      params['Distmi'] = this.companyFilter.Distmi;

    params['subcategoryId'] = subcategoryId;

    this.redirectTo(this.path.Directory, params);
    window.scrollTo(0, 0);
  }


  redirectTo(uri: string, params: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri])
    );
  }

  isSubcategoryActive(subcatId): boolean {
    if (!this.companyFilter.SubcategoryId && !subcatId) return true;
    if (this.companyFilter.SubcategoryId === subcatId) return true;
    return false;
  }
  // Change scroll
  onChangeScroll(isToBottom: boolean) {
    // Default is scroll to top
    let height: number = 0;
    if(isToBottom)
      height = this.sidebar.nativeElement.scrollHeight;

    this.sidebar.nativeElement.scrollTo({
      top: height,
      behavior: "smooth"
    });
  }
}
