import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';
import { MediaService } from 'app/_core/api-access/media.service';
import { SeniorPlacement } from 'app/_core/models/senior-placement';

@Component({
  selector: 'app-senior-placement-review',
  templateUrl: './senior-placement-review.component.html'
})
export class SeniorPlacementReviewsComponent  extends BaseComponent implements OnInit {
  @Input() seniorPlacement: SeniorPlacement;

  public starNumbers = [5,4,3,2,1]

  constructor(
    private mediaService: MediaService) {
    super();
  }

  // Methods implement
  ngOnInit() {

  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }
}
