import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { BaseService } from './base.service'
import { Inquiry } from '../models'
import { tap } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class FormService extends BaseService {
  // Data
  private inquirySubject = new BehaviorSubject<Inquiry>(null);
  private inquiryData: { data: Inquiry } = { data: null };
  readonly inquiryObs = this.inquirySubject.asObservable();

  constructor(private http: HttpClient) {
    super()
  }

  setInquiryData(data: Inquiry = null) {
    this.inquiryData.data = data;
    this.inquirySubject.next(Object.assign({}, this.inquiryData).data);
  }

  sendInquiry(inquiry: Inquiry): Observable<any> {
    const apiUrl = this.baseUrlCare + `/message/inquiry`;
      return this.http.post<Inquiry>(apiUrl, inquiry, { withCredentials: true }).pipe(tap(obj => {
      this.setInquiryData(obj);
    }));
  }

  sendInquiryPart2(inquiry: Inquiry): Observable<any> {
    const apiUrl = this.baseUrlCare + `/message/inquiry/${inquiry.InquiryGuid}`;
      return this.http.put<Inquiry>(apiUrl, inquiry, { withCredentials: true }).pipe(tap(obj => {
      console.log(obj);
    }));
  }
}
