import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';
import { CareGiver } from 'app/_core/models';

@Component({
  selector: 'app-caregiver-event',
  templateUrl: './caregiver-event.component.html'
})
export class CareGiverEventComponent extends BaseComponent implements OnInit {
  @Input() careGiver: CareGiver;
  constructor() {
    super();
  }

  // Methods implement
  ngOnInit() { }
}
