import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';
import { MediaService } from 'app/_core/api-access/media.service';
import { CareGiver } from 'app/_core/models';


@Component({
  selector: 'app-caregiver-review',
  templateUrl: './caregiver-review.component.html'
})
export class CareGiverReviewsComponent  extends BaseComponent implements OnInit {
  @Input() careGiver: CareGiver;

  public starNumbers = [5,4,3,2,1]

  constructor(
    private mediaService: MediaService) {
    super();
  }

  // Methods implement
  ngOnInit() {

  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }
}
