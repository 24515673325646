// toast.service.ts
import { Injectable, TemplateRef  } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: any[] = [];

  // Push new Toasts to array with content and options
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  // Callback method to remove Toast DOM element from view
  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  showSuccess(msg: string) {
    // Show message
    this.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
      autohide: true,
      headertext: 'Information'
    });
  }

  showError(msg: string, explanation?: string) {
    this.show(msg, {
      classname: 'bg-danger text-light',
      delay: 5000,
      autohide: true,
      headertext: "Something's Wrong...",
      msgDetail: explanation
    });
  }

  showAlert(msg: string) {
    // Show message
    this.show(msg, {
      classname: 'bg-info text-light',
      delay: 10000,
      autohide: true,
      headertext: 'Alert'
    });
  }
}
