import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { BaseActComponent } from 'app/_controls/base-act.component';
// import { CareManagerService } from 'app/_core/api-access/caremanager.service';
import { WantadService } from 'app/_core/api-access/wantad.service'


import { FormService } from 'app/_core/api-access/form.service';
import { GlobalService } from 'app/_core/api-access/global.service';
import { MediaService } from 'app/_core/api-access/media.service';
import { Inquiry, TargetCustomerType } from 'app/_core/models';
import { ErEmploymentStatus, ErSeniorityLevel } from 'app/_core/models';
import { ListOfValues } from 'app/_core/models/global';
import { GeoType, InquiryPage } from 'app/_core/predefined/enums';
import { MiscService } from 'app/_core/utilities/misc.service';
import { ToastService } from 'app/_core/utilities/toast.service';
import { first } from 'rxjs/operators';
import { ErWantad } from '../../../_core/models/ads/ErWantad';
import { environment } from '../../../../environments/environment';
import { ErWantadType } from 'app/_core/models/ads/ErJobad';

@Component({
  selector: 'app-wantad',
  templateUrl: './wantad-details.component.html',
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})

export class WantadDetailsComponent extends BaseActComponent implements OnInit {

  // Vars
  public minDate: Date;
  public maxDate: Date;
  public globalData: ListOfValues;
  // public careManagerDetails: CareManager;
  public wantad: ErWantad;
  public selectedOff: number = 0;
  public WantadTypes = ErWantadType;

  // Time ranges
  public hoursRange: string[];

  public employmentStatus = ErEmploymentStatus;
  public seniorityLevel = ErSeniorityLevel;


  private obj: Inquiry = new Inquiry();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private formService: FormService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    // private careManagerService: CareManagerService,
    private wantadService: WantadService,
    private mediaService: MediaService,
    private miscService: MiscService,
    private titleService: Title
  ) {
    super();
    this.minDate = new Date();
    this.maxDate = new Date('3000-01-01');
  }

  private getObjPropertyName(expression: Function): string {
    return this.miscService.getPropertyName(this.obj, expression);
  }

  private setFormData() {
    this.formAct.controls[this.getObjPropertyName((o: Inquiry) => o.TargetCustomerType)].setValue('Loved_One');
  }

  private objMapForm(): Inquiry {
    const obj: Inquiry = new Inquiry();
    obj.ErWantadId = this.wantad.Id;
    obj.TargetCustomerType = this.formAct.controls[this.getObjPropertyName((o: Inquiry) => o.TargetCustomerType)].value;
    obj.Name = this.formAct.controls.Name.value;
    obj.PhoneNumber = this.formAct.controls.PhoneNumber.value;
    obj.EmailAddress = this.formAct.controls.EmailAddress.value;
    obj.Notes = this.formAct.controls.Notes.value;
    // Populate schedule date with time range
    if (this.formAct.controls.MeetingReqFor.value) {
      const date = new Date(this.formAct.controls.MeetingReqFor.value);
      date.setMinutes(0);
      date.setSeconds(0);

      const hour = +this.formAct.controls.MeetingTimeRange.value;
      date.setHours(hour);

      // Final assign to schedule metting time
      obj.MeetingReqFor = this.miscService.dateLocalToUtc(date);
    }
    obj.InquiryPage = InquiryPage.Details;
    return obj;
  }

  // Build form action
  private buildForm() {
    const formGroup: FormGroup = this.formBuilder.group({});
    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.TargetCustomerType), new FormControl(null, Validators.required));

    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.Name), new FormControl(null, [Validators.required, Validators.maxLength(200)]));
    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.PhoneNumber), new FormControl(null, [Validators.required, Validators.maxLength(50)]));
    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.EmailAddress), new FormControl(null, [Validators.required, Validators.maxLength(200)]));
    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.Notes), new FormControl(null, [Validators.required]));
    formGroup.addControl(this.getObjPropertyName((o: Inquiry) => o.MeetingReqFor), new FormControl(null, [Validators.required]));

    // Control name not wrap in object
    formGroup.addControl('MeetingTimeRange', new FormControl(null, [Validators.required]));

    // Assign form group variable
    this.formAct = formGroup;

    // Validations message
    this.formValidations = {};
    this.formValidations[this.getObjPropertyName((o: Inquiry) => o.Name)] = [
      { type: 'required', message: 'Name is required' },
      { type: 'maxlength', message: 'Name can\'t be more than 200 characters long' },
    ];

    this.formValidations[this.getObjPropertyName((o: Inquiry) => o.PhoneNumber)] = [
      { type: 'required', message: 'Mobile is required' },
      { type: 'pattern', message: 'Mobile is not valid' },
      { type: 'maxlength', message: 'Mobile can\'t be more than 50 characters long' },
    ];

    this.formValidations[this.getObjPropertyName((o: Inquiry) => o.EmailAddress)] = [
      { type: 'required', message: 'Email Address is required' },
      { type: 'pattern', message: 'Email Address is not valid' },
      { type: 'maxlength', message: 'Email Address can\'t be more than 200 characters long' },
    ];

    this.formValidations[this.getObjPropertyName((o: Inquiry) => o.Notes)] = [
      { type: 'required', message: 'Notes is required' },
    ];
  }

  private loadDetail() {
    this.wantadService.getById(this.id).subscribe(obj => {
      this.wantad = obj;
      this.titleService.setTitle(obj.Name ? obj.Name : "Job Ad Details");

    });
  }

  ngOnInit(): void {
    // Load data
    this.hoursRange = this.miscService.getHours();

    this.buildForm();
    this.loadGlobal();

    if (this.activatedRoute.snapshot.params['id'] != null) {
      this.id = +this.activatedRoute.snapshot.params['id'];
      this.loadDetail();
    }
    this.setFormData();
  }

  onSubmitContact() {
    this.submitted = true;

    // Form invalid
    if (!this.formAct.valid)
      return;

    // Form is valid
    this.formService.sendInquiry(this.objMapForm())
      .pipe(first())
      .subscribe(
        (obj) => {
          // Reset submit
          this.submitted = false;
          this.toastService.showSuccess('Thank you! Someone will be in touch with you.');
          this.formAct.reset();
          //this.router.navigate([this.path.Inquiry_Additional]);
          // this.setFormData();
        },
        () => {
          // Reset submit
          this.submitted = false;
        });
  }

  loadGlobal() {
    if (this.globalService.getCacheGlobal())
      this.globalData = this.globalService.getCacheGlobal();
    else
      this.globalService.getGlobal().pipe(first()).subscribe(global => {
        this.globalData = global;
        this.globalService.setCacheGlobal(global);
      });
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }

  redirectTo(uri: string, params: any) {
    console.log("redirect" + uri, "params" + params);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri]));
  }

  public get EO360Url(): string {
    return environment.baseUrlEO360 + `others-want-ads/${this.wantad.Id}`
  }
}
