import { Component} from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html'
})
export class MainContainerComponent extends BaseComponent {
  constructor() {
    super();
  }
}