import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirm action!</h4>
  </div>
  <div class="modal-body">
    <strong>{{message}}</strong>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info mr-2" (click)="onOK()">OK</button>
    <button type="button" class="btn btn-info" (click)="onCancel()">Cancel</button>
  </div>
  `,
})
export class ConfirmDialogComponent implements OnInit {

  @Input() message: string;

  constructor(
    private activeModal: NgbActiveModal,){}

  ngOnInit() {}

  onCancel() {
    this.activeModal.close(false);
  }

  onOK() {
    this.activeModal.close(true);
  }

}
