import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
@Pipe({
  name: 'numberInteger'
})
export class NumberInteger extends DecimalPipe implements PipeTransform {
  transform(value: any): string {
    return value && !isNaN(value) ? super.transform(value, '1.0-0'): '0';
  }
}
