import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'app/_core/utilities/loader.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loader',
  template: `
  <ngx-spinner class="loader" size="default" type="ball-spin-clockwise">
    <p style="color:#fff">Wait loading...</p>
  </ngx-spinner>
  `,
})
export class LoaderComponent implements OnInit {

  // loading: boolean;
  constructor(
    private loaderService: LoaderService,
    private ngxSpinnerService: NgxSpinnerService) {
    this.loaderService.isLoading.subscribe((isLoading) => {
      if (isLoading) {
        // Start...
        setTimeout(() => this.ngxSpinnerService.show());
      } else {
        // End...
        setTimeout(() => this.ngxSpinnerService.hide());
      }
    });
  }
  ngOnInit() {
  }

}
