import { Component, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';

// RxJs
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoType } from 'app/_core/predefined/enums';
import { SeniorPlacementFilter, Language, PageInfo, ListOfValues } from 'app/_core/models';
import { GlobalService } from 'app/_core/api-access/global.service';
import { SeniorPlacementService } from 'app/_core/api-access/senior-placement.service';
import { MediaService } from 'app/_core/api-access/media.service';
import { SeniorPlacement } from 'app/_core/models/senior-placement';
@Component({
  selector: 'app-senior-placement-list-extend',
  templateUrl: './senior-placement-list-extend.component.html'
})

export class SeniorPlacementListExtendComponent extends BaseActComponent implements OnInit {

  public readonly seniorPlacements$: Observable<SeniorPlacement[]>;
  public readonly pageInfo$: Observable<PageInfo>;
  public readonly geoType = GeoType;

  // Keep form value
  public seniorPlacementFilter: SeniorPlacementFilter = new SeniorPlacementFilter();

  // Pagination
  public pageOffset: number;
  public pageLimit: number;
  public pageNumberSelected: number;

  public globalData: ListOfValues;

  constructor(
    private seniorPlacementService: SeniorPlacementService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private mediaService: MediaService,) {
    super();

    // Subscribe to entire collection
    this.seniorPlacements$ = this.seniorPlacementService.seniorPlacementsObs;
    // Page info
    this.pageInfo$ = this.seniorPlacementService.pageInfoObs;
  }

  loadGlobal() {
    if (this.globalService.getCacheGlobal())
      this.globalData = this.globalService.getCacheGlobal();
    else
      this.globalService.getGlobal().pipe(first()).subscribe(global => {
        this.globalData = global;
        this.globalService.setCacheGlobal(global);
      });
  }

  ngOnInit(): void {
    this.loadGlobal();
    // Process
    this.route.queryParamMap.subscribe(params => {
      this.seniorPlacementFilter.Name = params.get('name');

      // Get filter values from params
      this.seniorPlacementFilter.GeoId = +params.get('geo_id');
      this.seniorPlacementFilter.GeoType = params.get('geo_type');
      this.seniorPlacementFilter.GeoName = params.get('geo_name');

      // TODO: should get it from api, get detail for geotype and id above, or return on result together.
      this.seniorPlacementFilter.GeoCityId = +params.get("geo_city_id");
      this.seniorPlacementFilter.GeoCityName = params.get("geo_city_name");
      this.seniorPlacementFilter.GeoCountyId = +params.get("geo_county_id");
      this.seniorPlacementFilter.GeoCountyName = params.get("geo_county_name");
      this.seniorPlacementFilter.GeoStateId = +params.get("geo_state_id");
      this.seniorPlacementFilter.GeoStateName = params.get("geo_state_name");

      // Other params
      this.seniorPlacementFilter.Distmi = +params.get('distmi') || null;

      // Populate languages from ids
      const languageIdsText = params.get('language_ids');
      let languageIds: String[] = [];
      if (languageIdsText)
        languageIds = languageIdsText.split(',');

      let languages: Language[] = [];
      if (languageIds.length > 0)
        languages = languageIds.map(id => {
          const index = this.globalData.Languages.findIndex(l => l.Id === +id);
          if (index > -1)
            return this.globalData.Languages[index];
        });

      // Languages parameter
      this.seniorPlacementFilter.Languages = languages;

      // Pagination
      const offset: number = +params.get("page_offset");
      this.pageOffset = offset ? offset : 0;

      const limit: number = +params.get("page_limit");
      this.pageLimit = limit ? limit : 12;

      // Load data
      this.seniorPlacementService.load(this.pageOffset, this.pageLimit, this.seniorPlacementFilter);
    });
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }

  onPaginationChange(pageNumber: number, totalPage: number) {
    if (pageNumber < 1 || pageNumber > totalPage) {
      return;
    }

    this.pageNumberSelected = pageNumber;
    this.pageOffset = (pageNumber - 1) * this.pageLimit;

    // Load data with new page and current condition
    this.seniorPlacementService.load(this.pageOffset, this.pageLimit, this.seniorPlacementFilter);
  }

  redirectTo(uri: string, params: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri])
    );
  }

  getFilter(filter: SeniorPlacementFilter) {
    if (!filter)
      return;

    // Reset pageOffset, and load with new conditions
    this.pageOffset = 0;
    this.seniorPlacementService.load(this.pageOffset, this.pageLimit, filter);
  }
}
