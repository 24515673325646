import { ErEmploymentStatus, ErSeniorityLevel, Language } from "./other";
import { Base } from "./base";
import { ErEvent } from "./event";
import { ErCity, ErCounty, ErState, ErZipcode } from './facility';
import { ErImage } from "./image";

export class SeniorPlacement extends Base {
  EoOrganizationId: number;
  Name: string;
  EmailAddress: string;
  PhoneNumber: string;
  HourlyPrice: number;
  YearsExperience: number;
  SeniorityLevel: ErSeniorityLevel;
  EmploymentStatus: ErEmploymentStatus;
  DescSummary: string;
  DescExperience: string;
  DescCompensation: string;
  Images: ErImage[];
  ErImageId: number;
  ImageGuid: string;
  ReviewsAverage: number;

  // address of SeniorPlacement
  Address: string;
  ZipCodeStr: string;
  Zipcode: ErZipcode;
  BizCity: ErCity;
  BizCounty: ErCounty;
  BizState: ErState;

  Cities: ErCity[];
  City: ErCity;
  County: ErCounty;
  State: ErState;

  Languages: Language[];
  Events: ErEvent[];
  Reviews: ErSeniorPlacementReview[];

  ShowBizAddress: boolean;
  Website: string;

  // For display
  CityNames: string;
  BizZipcode: any;
}

export class ErSeniorPlacementReview extends Base {

  constructor(obj: ErSeniorPlacementReview = null) {
    super(obj);
    if (!obj) return;
    if (obj.Image)
      obj.Image = new ErImage(obj.Image)
  }

  PublishedAt: Date;
  PublishedBy: string;
  Stars: number;
  Description: string;

  // Avatar
  Image: ErImage;
}
