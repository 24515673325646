import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from 'app/_core/api-access/global.service';
import { SearchService } from 'app/_core/api-access/search.service';
import { ErCity, ErCounty, ErZipcode, TermSearch } from 'app/_core/models';
import { GeoType } from 'app/_core/predefined/enums';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent extends BaseComponent implements OnInit {
  currentPath: string;
  showMiniCart: boolean = false;
  showMenu: boolean = false;
  showAccount: boolean = false;

  // Search
  searchControlText: FormControl;
  searchControlGeo: FormControl;

  geoSearchResult$: Observable<any>;
  geoSearchSubject = new Subject<string>();

  private geo: ErCounty | ErCity | ErZipcode;
  private geoTypeSelected: GeoType;
  public geoNameAuto: boolean = false;

  // Form search
  public readonly geoType = GeoType;
  public isShowLocationSuggestion: boolean = false;

  // Data counties
  private countiesSubject = new BehaviorSubject<ErCounty[]>([]);
  private countiesData: { data: ErCounty[] } = { data: [] };
  public readonly counties$ = this.countiesSubject.asObservable();

  // Data cities
  private citiesSubject = new BehaviorSubject<ErCity[]>([]);
  private citiesData: { data: ErCity[] } = { data: [] };
  public readonly cities$ = this.citiesSubject.asObservable();

  // Data zipCodes
  private zipCodesSubject = new BehaviorSubject<ErZipcode[]>([]);
  private zipCodesData: { data: ErZipcode[] } = { data: [] };
  public readonly zipCodes$ = this.zipCodesSubject.asObservable();

  constructor(
    location: Location,
    private router: Router,
    private searchService: SearchService,
    private globalService: GlobalService,
  ) {
    super();
    this.currentPath = ''
    this.router.events.subscribe(() => {
      if (location.path() != "") {
        this.currentPath = location.path();
      }
    });
  }

  ngOnInit() {
    // Text search
    this.searchControlText = new FormControl();
    this.searchControlGeo = new FormControl();

    this.searchService.termSearch$.subscribe((termSearch: TermSearch) => {
      this.searchControlText.setValue(termSearch ? termSearch.text : null);
      this.searchControlGeo.setValue(termSearch && termSearch.geo ? termSearch.geo.Name : null);
    });

    // Geos search.
    this.geoSearchSubject.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(term => {
        if(!term) {
          this.setResultSearch(null);
          return;
        }

        // Call api search geos
        this.globalService.searchGeos(term).subscribe(result => {
          if (result.Counties || result.Cities || result.Zipcodes)
          {
            this.isShowLocationSuggestion = true;
            this.geoNameAuto = false;
          }

          this.setResultSearch(result);
        });
      });
  }

  onSearchGeoChange(evt: any) {
    // console.log('evt.target.value :>> ', evt.target.value);
    const searchText = evt.target.value;
    if(!searchText || searchText === '') {
      this.geoTypeSelected = null;
      this.geo = null;
    }

    this.geoSearchSubject.next(searchText);
  }

  fillDataForSearch(geo: ErCounty | ErCity | ErZipcode, geoType: GeoType) {
    this.isShowLocationSuggestion = false;
    this.searchControlGeo.setValue(geo.Name);

    this.geo = geo;
    this.geoTypeSelected = geoType;
  }

  onFind() {
    this.searchService.setTermSearch(this.searchControlText.value, this.geoTypeSelected, this.geo);
    this.geoNameAuto = true;
    const urlWithoutParams = this.router.url.split('?')[0] ;
    // Navigate to home from other page
    if(urlWithoutParams !== this.path.Home)
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>{
        return this.router.navigate([this.path.Home]);
      });
  }

  private setResultSearch(result: any) {
    // Set data counties
    this.countiesData.data = result && result.Counties && result.Counties.length ? result.Counties : null;
    this.countiesSubject.next(Object.assign({}, this.countiesData).data);

    // Set data cities
    this.citiesData.data = result && result.Cities && result.Cities.length ? result.Cities : null;
    this.citiesSubject.next(Object.assign({}, this.citiesData).data);

    // Set data Zipcodes
    this.zipCodesData.data = result && result.Zipcodes && result.Zipcodes.length ? result.Zipcodes : null;
    this.zipCodesSubject.next(Object.assign({}, this.zipCodesData).data);
  }

  clearSearch(){
    this.searchService.clearTermSearch();
  }
  closeMenu() {
    if(this.isScreenMaxMd)
      this.showMenu = false;
    
    window.scrollTo(0, 0);
  }
}
