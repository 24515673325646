import { Component, Input, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';

import { OfferingService } from '../../../_core/api-access/offering.service'
import { Observable } from 'rxjs';
import { AHIOfferingSubcategory, JobadFilter, ListOfValues, PageInfo } from '../../../_core/models';
import { Title } from '@angular/platform-browser';
import { MediaService } from 'app/_core/api-access/media.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../_core/api-access/global.service';
import { ErJobad } from '../../../_core/models/ads/ErJobad';
import { JobadService } from '../../../_core/api-access/jobad.service';

@Component({
  selector: 'app-jobad',
  templateUrl: './jobad.component.html'
})

export class JobadComponent extends BaseActComponent implements OnInit {
  
  get LOVs(): ListOfValues { return this.globalService.getCacheGlobal(); }

  public jobads: ErJobad[];

  constructor(
    private globalService: GlobalService,
    private jobadService: JobadService,
    private titleService: Title,
    private route: ActivatedRoute,
    private mediaService: MediaService,
    private router: Router,
  ) {
    super();

  }

  ngOnInit(): void {
    this.getCurrentAds();
  };

  private getCurrentAds() {
    this.jobadService.getCurrent().subscribe(result => this.jobads = result);
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }

  redirectTo(uri: string, params: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri])
    );
  }

}
