import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { BaseService } from './base.service'
import { ListOfValues } from '../models/global'
import { StorageKey } from '../models'
import { tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class GlobalService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  searchGeos(query: string): Observable<any> {
    // console.log("searchGeos:[" + query + "]");
    let httpParams = new HttpParams();
    httpParams = httpParams
      .set('query', `${query}`);

    const apiUrl = this.baseUrlCare + `/search/geos`;
    return this.http.get<any>(apiUrl, { params: httpParams });
  }

  getGlobal(): Observable<ListOfValues> {
    const apiURL: string = this.baseUrlCare + `/global/listofvalues`;
    return this.http.get<ListOfValues>(apiURL).pipe(tap(result=>this.setCacheGlobal(result)));
  }

  /*
  getCacheGlobal(): ListOfValues {
    // console.log('Read cache: Global');

    const globalData: string = localStorage.getItem(StorageKey.ListOfValues);

    if (globalData !== 'undefined' && globalData !== 'null')
      return JSON.parse(globalData);

    return null;
  }

  setCacheGlobal(data: ListOfValues) {
    // Store on local storage, for get the next times
    localStorage.setItem(StorageKey.ListOfValues, JSON.stringify(data));
  }
  */

  private static cache: any;

  getCacheGlobal(): ListOfValues {
    // console.log('Read cache: Global');

    if (GlobalService.cache)
      return GlobalService.cache;

    return null;
  }

  setCacheGlobal(data: ListOfValues) {
    // Do not store on local storage, so we always have up-to-date data.
    // The ListOfValues is a less than 25kB download
    GlobalService.cache = data;
  }
}
