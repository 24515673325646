import { Component, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MediaService } from 'app/_core/api-access/media.service';
import { Facility } from 'app/_core/models';
import { FacilityService } from 'app/_core/api-access/facility.service';

@Component({
  selector: 'app-facility-related-carousel',
  templateUrl: './facility-related-carousel.component.html'
})
export class FacilityRelatedCarouselComponent extends BaseActComponent implements OnInit {

  public readonly facilities$: Observable<Facility[]>;

  constructor(
    private facilityService: FacilityService,
    private route: ActivatedRoute,
    private mediaService: MediaService,) {
    super();

    // Subscribe to entire collection
    this.facilities$ = this.facilityService.facilitiesObs;
    // Page info
  }

  ngOnInit(): void {
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }
}
