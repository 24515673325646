// Cores
import { Component, OnInit } from '@angular/core';

// Components
import { BaseComponent } from 'app/_controls/base.component';

// RxJS
import { Observable } from 'rxjs';

// Models
import { FacilityFilter, PageInfo, ListOfValues, Facility, CareGiver, CareGiverFilter } from 'app/_core/models';
import { GeoType } from 'app/_core/predefined/enums';

// Services
import { FacilityService } from 'app/_core/api-access/facility.service';
import { MediaService } from 'app/_core/api-access/media.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { GlobalService } from 'app/_core/api-access/global.service';
import { CareGiverService } from 'app/_core/api-access/caregiver.service';

@Component({
  selector: 'app-senior-care-list',
  templateUrl: './senior-care-list.component.html'
})

export class SeniorCareListComponent extends BaseComponent implements OnInit {

  // Vars readonly, constants
  public readonly facilities$: Observable<Facility[]>;
  public readonly caregivers$: Observable<CareGiver[]>;
  public readonly pageInfo$: Observable<PageInfo>;
  public readonly geoType = GeoType;
  public readonly caregiverLoadOffset: number = 0;
  public readonly caregiverLoadLimit: number = 6;


  public globalData: ListOfValues;

  // Keep form value
  public facilityFilter: FacilityFilter = new FacilityFilter();
  public caregiverFilter: CareGiverFilter = new CareGiverFilter();

  // Pagination
  public pageOffset: number;
  public pageLimit: number;
  public pageNumberSelected: number;

  // Tab active
  public tabIndexCurrent: number = 0; // 0: Map, 1: List
  public tabNames: string[] = [
    "Map",
    "List"
  ]

  // Hover show map
  public facilityHover: Facility;

  constructor(
    private facilityService: FacilityService,
    private mediaService: MediaService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private caregiverService: CareGiverService
  ) {
    super();

    // Subscribe to entire collection
    this.facilities$ = this.facilityService.facilitiesObs;
    this.caregivers$ = this.caregiverService.careGiversObs;

    // Page info
    this.pageInfo$ = this.facilityService.pageInfoObs;
  }

  loadGlobal(loadNew: boolean = false) {
    if (this.globalService.getCacheGlobal() && !loadNew)
      this.globalData = this.globalService.getCacheGlobal();
    else
      this.globalService.getGlobal().pipe(first()).subscribe(global => {
        this.globalData = global;
        this.globalService.setCacheGlobal(global);
        this.facilityFilter.Languages = this.globalData.Languages.filter(language => language.Name === "English");
      });
  }

  ngOnInit(): void {
    this.loadGlobal();

    // Use only when pass url with params, this only use for initial filter variable
    this.route.queryParamMap.subscribe(params => {
      if (params.get('name'))
        this.facilityFilter.FacilityName = params.get('name');
      this.facilityFilter.GeoType = params.get('geo_type');
      this.facilityFilter.GeoId = +params.get('geo_id');
      // console.log("GeoId" + this.facilityFilter.GeoId);
      this.facilityFilter.GeoName = params.get('geo_name');

      // TODO: should get it from api, get detail for geotype and id above, or return on result together.
      this.facilityFilter.GeoCityId = +params.get("geo_city_id");
      this.facilityFilter.GeoCityName = params.get("geo_city_name");
      this.facilityFilter.GeoCountyId = +params.get("geo_county_id");
      this.facilityFilter.GeoCountyName = params.get("geo_county_name");
      this.facilityFilter.GeoStateId = +params.get("geo_state_id");
      this.facilityFilter.GeoStateName = params.get("geo_state_name");

      //set default language
      if (this.globalData && this.globalData.Languages) {
        this.facilityFilter.Languages = this.globalData.Languages.filter(language => language.Name === "English");
      } else {
        this.loadGlobal(true);
      }

      // Tab view
      this.tabIndexCurrent = +params.get("tab_view") || 0;

      // Pagination
      const offset: number = +params.get("page_offset");
      this.pageOffset = offset ? offset : 0;

      const limit: number = +params.get("page_limit");
      this.pageLimit = limit ? limit : 10;

      // console.log("facilityfilter:" + this.facilityFilter);
      // Load data
      this.facilityService.load(this.pageOffset, this.pageLimit, this.facilityFilter);

      // Load care givers related
      this.caregiverFilter.GeoType = this.geoType.County;
      this.caregiverFilter.GeoId = 0;
      this.caregiverService.load(this.caregiverLoadOffset, this.caregiverLoadLimit, this.caregiverFilter);
    });
  }

  getFilter(filter: FacilityFilter) {
    if (!filter)
      return;
    // console.log("getFilter");
    // Reset pageOffset, and load with new conditions
    this.pageOffset = 0;
    // console.log("GeoId:" + filter.GeoId);
    this.facilityService.load(this.pageOffset, this.pageLimit, filter);

    // Load care givers related
    this.caregiverFilter.GeoType = filter.GeoType;
    this.caregiverFilter.GeoId = filter.GeoId;
    this.caregiverFilter.GeoName = filter.GeoName;
    this.caregiverFilter.GeoCityId = filter.GeoCityId;
    this.caregiverFilter.GeoCityName = filter.GeoCityName;
    this.caregiverFilter.GeoCountyId = filter.GeoCountyId;
    this.caregiverFilter.GeoCountyName = filter.GeoCountyName;
    this.caregiverFilter.GeoStateId = filter.GeoStateId;
    this.caregiverFilter.GeoStateName = filter.GeoStateName;

    this.caregiverService.load(this.pageOffset, this.caregiverLoadLimit, this.caregiverFilter);
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }

  redirectTo(uri: string, params: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri])
    );
  }

  onPaginationChange(pageNumber: number, totalPage: number) {
    if (pageNumber < 1 || pageNumber > totalPage) {
      return;
    }

    this.pageNumberSelected = pageNumber;
    this.pageOffset = (pageNumber - 1) * this.pageLimit;

    // Load data with new page and current condition
    this.facilityService.load(this.pageOffset, this.pageLimit, this.facilityFilter);
  }

  onViewTab(tabIndex: number) {
    this.tabIndexCurrent = tabIndex;
  }

  onChangeMap(facility: Facility) {
    this.facilityHover = facility;
  }
}
