import { first } from "rxjs/operators";
import { ErCity, ErZipcode } from "..";
import { ErSite } from "../../predefined/enums";
import { Base } from "../base";
import { ErCounty, ErState } from "../facility";
import { ErImage } from "../image";

export class ErJobad extends Base {

  constructor(obj: ErJobad = null) {
    super(obj);

    if (!obj) return;

    if (obj.Zipcode) this.Zipcode = new ErZipcode(obj.Zipcode);
    if (obj.City) this.City = new ErCity(obj.City);
    if (obj.County) this.County = new ErCounty(obj.County);
    if (obj.State) this.State = new ErState(obj.State);

    if (obj.Images) {
      this.Images = [];
      obj.Images.forEach(x => { this.Images.push(new ErImage(x)); });
    }
  }

  EoOrganizationId: number;

  Name: string;

  Description: string;

  DescriptionShort: string;

  JobTitle: string;

  EmailAddress: string;

  PhoneNumber: string;

  Address: number;

  ZipCodeStr: string;

  ErZipcodeId: number;

  TimenessType: ErTimenessType;

  EmploymentTypes: ErEmploymentType[];

  ScheduleTypes: ErScheduleType[];

  HiresCount: number;

  Urgency: ErUrgency;

  PayMinimum: number;

  PayMaximum: number;

  PayInterval: ErPayInterval;

  Sites: ErSite[];

  ErObjectId: number;

  ErObjectType: string;



  //Computed properties
  ImageGuid: string;

  get ImageUrl(): string { return ErImage.ImageUrl(this.ImageGuid); }

  Images: ErImage[];

  Zipcode: ErZipcode;
  City: ErCity;
  County: ErCounty;
  State: ErState;

  get FullAddress() {
    const city = this.City ? ", "+this.City.Name : "";
    const county = this.County ? ", " + this.County.Name : "";
    const state = this.State ? ", " + this.State.Code : "";
    const zipcode = this.Zipcode ? ", " + this.Zipcode.Code : "";
    return this.Address + city + county + state + zipcode;
  }

  get ScheduleTypesStr(): string[] {
    if (!this.ScheduleTypes) return null;
    let strs = [];
    this.ScheduleTypes.forEach(x => strs.push(x.replace(/_/g, " ")));
    return strs;
  }

}





export enum ErWantadType {
  Help = "Help", Housing = "Housing "
}
// If want load to dropdown list
export namespace ErWantadType {
  export function Keys() {
    return Object.keys(ErWantadType).filter(
      (key) => isNaN(<any>key) && key !== 'Keys'
    );
  }
}

export enum ErFloor {
  Ground = "Ground", First = "First", Second = "Second", Third = "Third"
}
export namespace ErFloor {
  export function Keys() {
    return Object.keys(ErFloor).filter(
      (key) => isNaN(<any>key) && key !== 'Keys'
    );
  }
}

export enum ErPayInterval {
  Hour = "Hour", Day = "Day", Week = "Week", Month = "Month", Year = "Year"
}
export namespace ErPayInterval {
  export function Keys() {
    return Object.keys(ErPayInterval).filter(
      (key) => isNaN(<any>key) && key !== 'Keys'
    );
  }
}

export enum ErScheduleType {
  Eight_Hour_Shift = "Eight_Hour_Shift", Ten_Hour_Shift = "Ten_Hour_Shift", Twelve_Hour_Shift = "Twelve_Hour_Shift", Weekend_Availability = "Weekend_Availability", Monday_to_Friday = "Monday_to_Friday", On_Call = "On_Call", Holidays = "Holidays", Day_Shift = "Day_Shift", Night_Shift = "Night_Shift", Overtime = "Overtime", Other = "Other"
}
export namespace ErScheduleType {
  export function Keys() {
    return Object.keys(ErScheduleType).filter(
      (key) => isNaN(<any>key) && key !== 'Keys'
    );
  }
}

export enum ErEmploymentType {
  Contract = "Contract", Temporary = "Temporary", Internship = "Internship"
}
export namespace ErEmploymentType {
  export function Keys() {
    return Object.keys(ErEmploymentType).filter(
      (key) => isNaN(<any>key) && key !== 'Keys'
    );
  }
}

export enum ErTimenessType {
  Fulltime = "Fulltime", Parttime = "Parttime", Either = "Either"
}
export namespace ErTimenessType {
  export function Keys() {
    return Object.keys(ErTimenessType).filter(
      (key) => isNaN(<any>key) && key !== 'Keys'
    );
  }
}

export enum ErUrgency {
  ASAP = "ASAP", One_Week = "One_Week", One_Month = "One_Month"
}
export namespace ErUrgency {
  export function Keys() {
    return Object.keys(ErUrgency).filter(
      (key) => isNaN(<any>key) && key !== 'Keys'
    );
  }
}



