import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';
import { Facility } from 'app/_core/models';

@Component({
  selector: 'app-facility-service',
  templateUrl: './facility-service.component.html'
})
export class FacilityServiceComponent  extends BaseComponent implements OnInit {
  @Input() facility: Facility;

  constructor() {
    super();
  }

  // Methods implement
  ngOnInit() {}
}
