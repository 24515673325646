import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

// RxJS
import { Observable } from 'rxjs'
import { BaseService } from './base.service'

// Models
import { AHICompany } from '../models/company'

@Injectable({
  providedIn: 'root',
})

export class CompanyService extends BaseService {
  constructor(
    private http: HttpClient,
  ) {
    super()
  }

  getById(id: number): Observable<AHICompany> {
    let params: HttpParams = new HttpParams()
      .set("viewType", "AutismHelpInfo");
    const apiUrl = this.baseUrlCare + `/public/ahi/company/${id}`;
    return this.http.get<AHICompany>(apiUrl, { params: params, withCredentials: true });
  }
}
