import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

// RxJS
import { BehaviorSubject, Observable } from 'rxjs'
import { BaseService } from './base.service'

// Models
import { SeniorPlacement } from '../models/senior-placement'
import { tap } from 'rxjs/operators'
import { SeniorPlacementFilter, PageInfo } from '../models'
import { MiscService } from '../utilities/misc.service'


@Injectable({
  providedIn: 'root',
})
export class SeniorPlacementService extends BaseService {

  private seniorPlacementsSubject = new BehaviorSubject<SeniorPlacement[]>([]);
  private seniorPlacementsData: { data: SeniorPlacement[] } = { data: [] };
  public readonly seniorPlacementsObs = this.seniorPlacementsSubject.asObservable();

  // Page info
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private pageInfoData: { data: PageInfo } = { data: null };
  public readonly pageInfoObs = this.pageInfoSubject.asObservable();


  constructor(
    private http: HttpClient,
    private miscService: MiscService,) {
    super()
  }

  setDataObs(data: SeniorPlacement[] = []) {
    this.seniorPlacementsData.data = data;
    this.seniorPlacementsSubject.next(Object.assign({}, this.seniorPlacementsData).data);
  }

  setPageObs(pageInfo: PageInfo) {
    this.pageInfoData.data = pageInfo;
    this.pageInfoSubject.next(Object.assign({}, this.pageInfoData).data);
  }

  // Load data & populate
  load(offset: number = null, limit: number = null, filter: SeniorPlacementFilter) {
    this.getList(offset, limit, filter).subscribe();
  }

  getList(offset: number = null, limit: number = null, filter: SeniorPlacementFilter) {
    const name = filter.Name || '';
    const geoId = filter.GeoId || 0;
    const geoType = filter.GeoType || "county";
    const distmi = filter.Distmi || 25;

    // Array to string comma separated
    const languages: any = filter.Languages ? filter.Languages.map(l => l.Id).join() : null;

    // Declare
    let httpParams = new HttpParams()
      .set('name', `${name}`)
      .set('id', `${geoId}`)
      .set('geotype', `${geoType}`)
      .set('distmi', `${distmi}`);

    // Other params
    if (languages) httpParams = httpParams.set('languages', `${languages}`);

    // Pagination params: offset, limit
    if (offset) httpParams = httpParams.set('offset', `${offset}`);

    // Limit is Page size, items per page
    if (limit) httpParams = httpParams.set('limit', `${limit}`);

    const apiUrl = this.baseUrlCare + `/search/seniorplacements`;
    return this.http.get<SeniorPlacement[]>(apiUrl, { params: httpParams, observe: 'response',withCredentials:true}).pipe(tap(response => {
      const totalRecords = +response.headers.get("Total-Records") || 0;
      const facilities: SeniorPlacement[] = response.body || [];
      const pageNumber = offset / limit + 1;

      const pageInfo: PageInfo = this.miscService.getPager(totalRecords, pageNumber, limit);

      this.setDataObs(facilities);
      this.setPageObs(pageInfo);

      return response;
    }));
  }

  getById(id: number): Observable<SeniorPlacement> {
    const apiUrl = this.baseUrlCare + `/SeniorPlacement/${id}`;
      return this.http.get<SeniorPlacement>(apiUrl, { withCredentials: true });
  }
}
