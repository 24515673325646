import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent extends BaseComponent implements OnInit {
  public cookieStatus: boolean = false;
  public currentYear: number = new Date().getFullYear();
  public productionYear: number = 2020;

  constructor() {
    super();
  }

  ngOnInit() {}

  getCopyrightYear(){
    let copyrightYear: string = `${this.currentYear}`;
    if(this.currentYear !== this.productionYear)
      copyrightYear = `${this.productionYear}-Present`;
      
    return copyrightYear;
  }
}
