import { Base } from "./base";

export class ErUser extends Base {

    //@required()
    //@maxLength({value:200})
    FirstName: string;

    //@required()
    //@maxLength({ value: 200 })
    LastName: string;

    //@maxLength({ value: 200 })
    CompanyName: string;

    //@required()
    //@maxLength({ value: 200 })
    EmailAddress: string;

    //@maxLength({ value: 200 })
    PhoneMobile: string;

    IsAcpUser: boolean;

    IsIddHelperUser: boolean;

    CurrentXsrfToken: string;


    //Computed
    Name: string;
    IsSeniorUser: boolean;
    IsIddUser: boolean;

}
