import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { CareManagerService } from 'app/_core/api-access/caremanager.service';
import { FacilityService } from 'app/_core/api-access/facility.service';
import { FormService } from 'app/_core/api-access/form.service';
import { GlobalService } from 'app/_core/api-access/global.service';
import { CareManagerFilter, Inquiry, TargetCustomerType } from 'app/_core/models';
import { CareManager, Facility, ListOfValues } from 'app/_core/models';
import { GeoType, InquiryPage } from 'app/_core/predefined/enums';
import { MiscService } from 'app/_core/utilities/misc.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-facility-details',
  templateUrl: './facility-details.component.html',
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})

export class FacilityDetailComponent extends BaseActComponent implements OnInit {
  // Vars readonly, constants
  public readonly careManagers$: Observable<CareManager[]>;
  public readonly geoType = GeoType;
  public readonly careManagerLoadOffset: number = 0;
  public readonly careManagerLoadLimit: number = 6;

  public minDate: Date;
  public maxDate: Date;
  public globalData: ListOfValues;
  public listCustomerType = TargetCustomerType;
  public facilityDetails: Facility;
  public tabIndexCurrent: number = 0;
  public showfilter: boolean = false;
  public tabNames: string[] = [
    "Infomation",
    "Room Types",
    "Location",
    "Services - Amenities",
    "Events",
    "Jobs",
    "Reviews"
  ]
  public careManagerFilter: CareManagerFilter = new CareManagerFilter();

  // Time ranges
  public hoursRange: string[];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private formService: FormService,
    private activatedRoute: ActivatedRoute,
    private facilityService: FacilityService,
    private miscService: MiscService,
    private route: ActivatedRoute,
    private careManagerService: CareManagerService,
  ) {
    super();
    this.minDate = new Date();
    this.maxDate = new Date('3000-01-01');
    this.careManagers$ = this.careManagerService.careManagersObs;
  }

  private setFormData() {
    this.formAct.controls['TargetCustomerType'].setValue("Loved_One");
  }

  private objMapForm(): Inquiry {
    let obj: Inquiry = new Inquiry();
    obj.ErFacilityId = this.facilityDetails.Id;
    obj.TargetCustomerType = this.formAct.controls.TargetCustomerType.value;
    obj.Name = this.formAct.controls.Name.value;
    obj.PhoneNumber = this.formAct.controls.PhoneNumber.value;
    obj.EmailAddress = this.formAct.controls.EmailAddress.value;
    obj.Notes = this.formAct.controls.Notes.value;

    // Populate schedule date with time range
    if(this.formAct.controls.MeetingReqFor.value) {
      const date = new Date(this.formAct.controls.MeetingReqFor.value);
      date.setMinutes(0);
      date.setSeconds(0);

      const hour = +this.formAct.controls.MeetingTimeRange.value;
      date.setHours(hour);
      
      // Final assign to schedule metting time
      obj.MeetingReqFor = this.miscService.dateLocalToUtc(date);
    }
    obj.InquiryPage = InquiryPage.Details;
    return obj;
  }

  // Build form action
  private buildForm() {
    this.formAct = this.formBuilder.group({
      TargetCustomerType: ['',Validators.required],
      Name: [null, [Validators.required, Validators.maxLength(200)]],
      PhoneNumber: [null, [Validators.required, Validators.maxLength(50)]],
      EmailAddress: [null, [Validators.required, Validators.maxLength(200)]],
      Notes: [null, Validators.required],
      MeetingReqFor: ['',Validators.required],
      MeetingTimeRange: [null,Validators.required]
    }, { });

    // Validations message
    this.formValidations = {
      Name: [
        { type: 'required', message: 'Name is required' },
        { type: 'maxlength', message: 'Name can\'t be more than 200 characters long' },
      ],
      PhoneNumber: [
        { type: 'required', message: 'Mobile is required' },
        { type: 'pattern', message: 'Mobile is not valid' },
        { type: 'maxlength', message: 'Mobile can\'t be more than 50 characters long' },
      ],
      EmailAddress: [
        { type: 'required', message: 'Email Address is required' },
        { type: 'pattern', message: 'Email Address is not valid' },
        { type: 'maxlength', message: 'Email Address can\'t be more than 200 characters long' },
      ],
      Notes: [
        { type: 'required', message: 'Notes is required' },
        { type: 'maxlength', message: 'Notes can\'t be more than 200 characters long' },
      ],
      MeetingReqFor: [
        { type: 'required', message: 'Schedule Meeting is required' },
      ],
      MeetingTimeRange: [
        { type: 'required', message: 'Meeting Time Range is required' },
      ],
    }
  }

  private loadDetail() {
    this.facilityService.getById(this.id).subscribe(obj => {
      this.facilityDetails = obj;

      // Care managers related
      this.careManagerFilter.GeoType = this.geoType.County;
      this.careManagerFilter.GeoId = 0;
      this.careManagerService.load(0, this.careManagerLoadLimit, this.careManagerFilter);
    });
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.showfilter = params.get('showfilter') === "true" ? true : false;
    });
    // Load data
    this.hoursRange = this.miscService.getHours();

    this.buildForm();
    this.loadGlobal();
    if (this.activatedRoute.snapshot.params['id'] != null) {
      this.id = +this.activatedRoute.snapshot.params['id'];
      this.loadDetail();
    }
    this.setFormData();
  }

  onSubmitContact() {
    this.submitted = true;

    // Form invalid
    if(!this.formAct.valid)
      return;

    // Form is valid
    this.formService.sendInquiry(this.objMapForm())
      .pipe(first())
      .subscribe(
        (obj) => {
          // Reset submit
          this.submitted = false;
          this.formAct.reset();
          this.setFormData();

          // Redirect to part 2
          this.router.navigate([this.path.Patient_Facility]);
        },
        () => {
          // Reset submit
          this.submitted = false;
        });
  }
  redirectTo(uri: string, params: any) {
    console.log("redirect" + uri, "params" + params);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri])
    );
  }
  onViewTab(tabIndex: number) {
    this.tabIndexCurrent = tabIndex;
  }
  loadGlobal(){
    if(this.globalService.getCacheGlobal())
      this.globalData = this.globalService.getCacheGlobal();
    else
      this.globalService.getGlobal().pipe(first()).subscribe(global => {
        this.globalData = global;
        this.globalService.setCacheGlobal(global);
      });
  }
}
