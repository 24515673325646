import { Component, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoType } from 'app/_core/predefined/enums';
import { CareGiverFilter, PageInfo, CareGiver, ListOfValues, Facility, FacilityFilter, Pagination } from 'app/_core/models';
import { GlobalService } from 'app/_core/api-access/global.service';
import { CareGiverService } from 'app/_core/api-access/caregiver.service';
import { MediaService } from 'app/_core/api-access/media.service';
import { FacilityService } from 'app/_core/api-access/facility.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-caregiver-list',
  templateUrl: './caregiver-list.component.html'
})

export class CareGiverListComponent extends BaseActComponent implements OnInit {

  public readonly facilities$: Observable<Facility[]>;
  public readonly careGivers$: Observable<CareGiver[]>;
  public readonly pageInfo$: Observable<PageInfo>;
  public readonly geoType = GeoType;
  public careGivers: CareGiver[] = [];

  // Keep form value
  public careGiverFilter: CareGiverFilter = new CareGiverFilter();
  public facilityFilter: FacilityFilter = new FacilityFilter();

  // Pagination
  public globalData: ListOfValues;
  public readonly facilityLoadOffset: number = 0;
  public readonly facilityLoadLimit: number = 6;

  // Pagination, set it default
  public pagination = new Pagination(1, [20, 40, 60, 100]);
  public formPagination = new FormGroup({
    page: new FormControl(this.pagination.Page),
    pageSize: new FormControl(this.pagination.PageSize)
  });

  constructor(
    private careGiverService: CareGiverService,
    private facilityService: FacilityService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private mediaService: MediaService,) {
    super();

    this.facilities$ = this.facilityService.facilitiesObs;
    // Subscribe to entire collection
    this.careGivers$ = this.careGiverService.careGiversObs;
    // Page info
    this.pageInfo$ = this.careGiverService.pageInfoObs;
  }

  loadGlobal(loadNew: boolean = false) {
    if (this.globalService.getCacheGlobal() && !loadNew)
      this.globalData = this.globalService.getCacheGlobal();
    else
      this.globalService.getGlobal().pipe(first()).subscribe(global => {
        this.globalData = global;
        this.globalService.setCacheGlobal(global);
        this.careGiverFilter.Languages = this.globalData.Languages.filter(language => language.Name === "English");
      });
  }

  ngOnInit(): void {
    this.loadGlobal();
    // Process
    this.route.queryParamMap.subscribe(params => {
      // Get filter values from params
      this.careGiverFilter.GeoId = +params.get('geo_id');
      this.careGiverFilter.GeoType = params.get('geo_type');
      this.careGiverFilter.GeoName = params.get('geo_name');

      // TODO: should get it from api, get detail for geotype and id above, or return on result together.
      this.careGiverFilter.GeoCityId = +params.get("geo_city_id");
      this.careGiverFilter.GeoCityName = params.get("geo_city_name");
      this.careGiverFilter.GeoCountyId = +params.get("geo_county_id");
      this.careGiverFilter.GeoCountyName = params.get("geo_county_name");
      this.careGiverFilter.GeoStateId = +params.get("geo_state_id");
      this.careGiverFilter.GeoStateName = params.get("geo_state_name");

       //set default language
      if(this.globalData && this.globalData.Languages){
        this.careGiverFilter.Languages = this.globalData.Languages.filter(language => language.Name === "English");
      }else{
        this.loadGlobal(true);
      }

      // Pagination change
    this.formPagination.valueChanges.subscribe(
      (value: { page: number; pageSize: number }) => {
        if (this.pagination.Page === value.page
          && this.pagination.PageSize === value.pageSize) {
          return;
        }

        this.pagination.Page = value.page;
        this.pagination.PageSize = value.pageSize;

        this.loadData();
        
      },
    );

    this.route.queryParamMap.subscribe(() => {
      this.loadData();
    });

    // Load Facilities related
    this.facilityFilter.GeoType = this.geoType.County;
    this.facilityFilter.GeoId = 0;
      this.facilityService.load(this.facilityLoadOffset, this.facilityLoadLimit, this.facilityFilter);
    });
  }

  private loadData(isPageReset: boolean = false) {
    if (isPageReset) {
      this.pagination.Page = 1;
    }

    this.careGiverService
      .loadCaregivers(this.careGiverFilter, this.pagination)
      .pipe(first())
      .subscribe((httpResponse) => {
        // Pagination total records
        this.pagination.TotalRecords = +httpResponse.headers.get('Total-Records') || 0;

         // Return data direct
         const careGiversRaw = (httpResponse.body as CareGiver[]) || [];
         const careGiversResult: CareGiver[] = [];
         careGiversRaw.forEach(iCareceiver => {
          careGiversResult.push(new CareGiver(iCareceiver));
         });
 
         // Process careceivers
         this.careGivers = careGiversResult;
      });
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }

  redirectTo(uri: string, params: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri])
    );
  }

  getFilter(filter: CareGiverFilter) {
    // Redirect to page with filter on side
    const params = {};

    if(filter.GeoId) {
      params['geo_id'] = filter.GeoId;
      params['geo_type'] = filter.GeoType;
      params['geo_name'] = filter.GeoName;
    }

    if(filter.GeoCityId) {
      params['geo_city_id'] = filter.GeoCityId;
      params['geo_city_name'] = filter.GeoCityName;
    }

    if(filter.GeoCityId) {
      params['geo_county_id'] = filter.GeoCountyId;
      params['geo_county_name'] = filter.GeoCountyName;
    }

    if(filter.GeoStateId) {
      params['geo_state_id'] = filter.GeoStateId;
      params['geo_state_name'] = filter.GeoStateName;
    }

    if(filter.Distmi)
      params['distmi'] = filter.Distmi;

    if(filter.Languages)
      params['language_ids'] = filter.Languages.map(l => l.Id).join();


    this.redirectTo(this.path.Care_Givers_Extend, params);
  }
}
