import { Component, Input, OnInit } from '@angular/core';
import { BaseActComponent } from 'app/_controls/base-act.component';

import { OfferingService } from '../../../_core/api-access/offering.service'
import { AHICompany } from '../../../_core/models/company'
import { Observable } from 'rxjs';
import { AHIOfferingSubcategory, CompanyFilter, ListOfValues, PageInfo, Pagination } from '../../../_core/models';
import { Title } from '@angular/platform-browser';
import { MediaService } from 'app/_core/api-access/media.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../_core/api-access/global.service';
import { FormControl, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html'
})

export class DirectoryComponent extends BaseActComponent implements OnInit {
  
  public companies: AHICompany[];
  
  // Pagination, set it default
  public pagination = new Pagination(1, [20, 40, 60, 100]);
  public formPagination = new FormGroup({
    page: new FormControl(this.pagination.Page),
    pageSize: new FormControl(this.pagination.PageSize)
  });

  public categoryName: string = "Categories";
  public filterClick: boolean = false;

  // Keep form value
  //public companyFilter: CompanyFilter = new CompanyFilter();
  @Input() companyFilter: CompanyFilter; //Moved to parent container so changing categories keeps the filter

  get LOVs(): ListOfValues { return this.globalService.getCacheGlobal(); }

  constructor(
    private globalService: GlobalService,
    private offeringService: OfferingService,
    private titleService: Title,
    private route: ActivatedRoute,
    private mediaService: MediaService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    // Pagination change
    this.formPagination.valueChanges.subscribe(
      (value: { page: number; pageSize: number }) => {
        if (this.pagination.Page === value.page
          && this.pagination.PageSize === value.pageSize) {
          return;
        }

        this.pagination.Page = value.page;
        this.pagination.PageSize = value.pageSize;

        this.loadData();
        
      },
    );
    
    this.route.queryParamMap.subscribe(() => {
      this.loadData();
    });
    this.route.queryParams.subscribe(params => {
      const subcategoryId = + params['subcategoryId'];
      this.filterClick = params['filterClick'] === 'true' ? true : false;

      const lovs = this.globalService.getCacheGlobal();
      let category: AHIOfferingSubcategory = new AHIOfferingSubcategory();
      if(subcategoryId && lovs)
        category = lovs.OfferingSubcategories.find(cat => cat.Id === subcategoryId);
      if(category)
        this.categoryName = category.Name;
    })
    
  };

  private loadData(isPageReset: boolean = false) {
    if (isPageReset) {
      this.pagination.Page = 1;
    }

    this.offeringService
      .loadCompanies(this.companyFilter, this.pagination)
      .pipe(first())
      .subscribe((httpResponse) => {
        // Pagination total records
        this.pagination.TotalRecords = +httpResponse.headers.get('Total-Records') || 0;

         // Return data direct
         const companiesRaw = (httpResponse.body as AHICompany[]) || [];
         const companiesResult: AHICompany[] = [];
         companiesRaw.forEach(iCareceiver => {
          companiesResult.push(new AHICompany(iCareceiver));
         });
 
         // Process careceivers
         this.companies = companiesResult;
      });
  }

  getImageUrl(guid: string) {
    return this.mediaService.getImageUrl(guid);
  }

  redirectTo(uri: string, params: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Query params
      params ? this.router.navigate([uri], { queryParams: params }) : this.router.navigate([uri])
    );

    this.filterClick = true;
  }

  getFilter(filter: CompanyFilter) {
    // Redirect to page with filter on side
    const params = {};

    if(filter.Name)
      params['name'] = filter.Name;

    if(filter.GeoId) {
      params['geo_id'] = filter.GeoId;
      params['geo_type'] = filter.GeoType;
      params['geo_name'] = filter.GeoName;
    }

    if(filter.GeoCityId) {
      params['geo_city_id'] = filter.GeoCityId;
      params['geo_city_name'] = filter.GeoCityName;
    }

    if(filter.GeoCountyId) {
      params['geo_county_id'] = filter.GeoCountyId;
      params['geo_county_name'] = filter.GeoCountyName;
    }

    if(filter.GeoStateId) {
      params['geo_state_id'] = filter.GeoStateId;
      params['geo_state_name'] = filter.GeoStateName;
    }

    if(filter.Distmi)
      params['Distmi'] = filter.Distmi;

    if (filter.SubcategoryId)
      params['subcategoryId'] = filter.SubcategoryId;

    params['filterClick'] = 'true';

    this.companyFilter.CategoryId = this.LOVs.Config.CmdOfferingCategoryId;
    Object.assign(this.companyFilter, filter);

    this.redirectTo(this.path.Directory, params);
    
  }
}
