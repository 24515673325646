import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/_controls/base.component';
import { AHICompany } from 'app/_core/models/company';

@Component({
  selector: 'app-company-event',
  templateUrl: './company-event.component.html'
})
export class CompanyEventComponent extends BaseComponent implements OnInit {
  @Input() company: AHICompany;

  constructor() {
    super();
  }

  // Methods implement
  ngOnInit() { }
}
