import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

// RxJS
import { BehaviorSubject, Observable } from 'rxjs'
import { BaseService } from './base.service'

// Models
import { CareManager } from '../models/caremanager'
import { tap } from 'rxjs/operators'
import { CareManagerFilter, PageInfo } from '../models'
import { MiscService } from '../utilities/misc.service'


@Injectable({
  providedIn: 'root',
})
export class CareManagerService extends BaseService {

  private careManagersSubject = new BehaviorSubject<CareManager[]>([]);
  private careManagersData: { data: CareManager[] } = { data: [] };
  public readonly careManagersObs = this.careManagersSubject.asObservable();

  // Page info
  private pageInfoSubject = new BehaviorSubject<PageInfo>(null);
  private pageInfoData: { data: PageInfo } = { data: null };
  public readonly pageInfoObs = this.pageInfoSubject.asObservable();


  constructor(
    private http: HttpClient,
    private miscService: MiscService,) {
    super()
  }

  setDataObs(data: CareManager[] = []) {
    this.careManagersData.data = data;
    this.careManagersSubject.next(Object.assign({}, this.careManagersData).data);
  }

  setPageObs(pageInfo: PageInfo) {
    this.pageInfoData.data = pageInfo;
    this.pageInfoSubject.next(Object.assign({}, this.pageInfoData).data);
  }

  // Load data & populate
  load(offset: number = null, limit: number = null, filter: CareManagerFilter) {
    this.getList(offset, limit, filter).subscribe();
  }

  getList(offset: number = null, limit: number = null, filter: CareManagerFilter) {
    const name = filter.Name || '';
    const geoId = filter.GeoId || 0;
    const geoType = filter.GeoType || "county";
    const distmi = filter.Distmi || 25;

    const specialtyId = filter.SpecialtyId;

    // Array to string comma separated
    const languages: any = filter.Languages ? filter.Languages.map(l => l.Id).join() : null;
    const specialties: any = filter.Specialties ? filter.Specialties.map(l => l.Id).join() : null;

    // Declare
    let httpParams = new HttpParams()
      .set('name', `${name}`)
      .set('id', `${geoId}`)
      .set('geotype', `${geoType}`)
      .set('distmi', `${distmi}`);

    // Other params
    if (specialtyId) httpParams = httpParams.set('specialtyId', `${specialtyId}`);
    if (languages) httpParams = httpParams.set('languages', `${languages}`);
    if (specialties) httpParams = httpParams.set('specialties', `${specialties}`);

    // Pagination params: offset, limit
    if (offset) httpParams = httpParams.set('offset', `${offset}`);

    // Limit is Page size, items per page
    if (limit) httpParams = httpParams.set('limit', `${limit}`);

    const apiUrl = this.baseUrlCare + `/search/caremanagers`;
      return this.http.get<CareManager[]>(apiUrl, { params: httpParams, observe: 'response', withCredentials: true}).pipe(tap(response => {
      const totalRecords = +response.headers.get("Total-Records") || 0;
      const facilities: CareManager[] = response.body || [];
      const pageNumber = offset / limit + 1;

      const pageInfo: PageInfo = this.miscService.getPager(totalRecords, pageNumber, limit);

      this.setDataObs(facilities);
      this.setPageObs(pageInfo);

      return response;
    }));
  }

  getById(id: number): Observable<CareManager> {
    const apiUrl = this.baseUrlCare + `/CareManager/${id}`;
      return this.http.get<CareManager>(apiUrl, { withCredentials: true });
  }
}
