export enum GeoType {
	State = 'state',
	County = 'county',
	City = 'city',
	Zip_Code = 'zipcode',
}

export enum ErSite {
  Unknown = 'Unknown',
  CareMomDad = 'CareMomDad',
  Minisite = 'Minisite',
  EOps360 = 'EOps360',
  AutismHelpInfo = 'AutismHelpInfo',
  EmployeePortal = 'EmployeePortal',
  ClientPortal = 'ClientPortal',
  ApexSure = "ApexSure",
  StartDelta = "StartDelta",
}



export enum InquiryPage {
  HomeCMD, HomeAHI, Details, Website, Page
}
