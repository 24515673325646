import { Base } from "./base";
import { ErEvent } from "./event";
import { ErCity, ErCounty, ErState, ErZipcode } from './facility';
import { ErImage } from "./image";
import { ErEmploymentStatus, ErSeniorityLevel, Language } from "./other";

export class CareManager extends Base {
  EoOrganizationId: number;
  Name: string;
  EmailAddress: string;
  PhoneNumber: string;
  HourlyPrice: number;
  YearsExperience: number;
  SeniorityLevel: ErSeniorityLevel;
  EmploymentStatus: ErEmploymentStatus;
  DescSummary: string;
  DescExperience: string;
  DescCompensation: string;
  ErImageId: number;
  ImageGuid: string;
  ReviewsAverage: number;

  // address of care manager
  Address: string;
  ZipCodeStr: string;
  Zipcode: ErZipcode;
  ErZipcodeId: number;
  BizCity: ErCity;
  BizCounty: ErCounty;
  BizState: ErState;

  ShowBizAddress: boolean;
  Website: string;

  Cities: ErCity[];
  City: ErCity;
  County: ErCounty;
  State: ErState;

  Languages: Language[];
  Events: ErEvent[];
  CMSpecialties: ErCMSpecialty[];
  Reviews: ErCareManagerReview[];
  BizZipcode: any;

  // For display
  CityNames: string;

}

export class ErCareManagerReview extends Base {

  constructor(obj: ErCareManagerReview = null) {
    super(obj);
    if (!obj) return;
    if (obj.Image)
      obj.Image = new ErImage(obj.Image)
  }

  ErCareManagerId: number;
  PublishedAt: Date;
  PublishedBy: string;
  Stars: number;
  Description: string;

  // Avatar
  Image: ErImage;
}

export class ErCMSpecialty extends Base {
  Name: string;
  FaCode: any;
}
