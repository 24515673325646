// toast.component.ts
import { Component, TemplateRef } from '@angular/core';
import { ToastService } from 'app/_core/utilities/toast.service';

@Component({
    selector: 'app-toasts',
    template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [header]="toast.headertext"
      [class]="toast.classname"
      [autohide]="toast.autohide"
      [delay]="toast.delay || 5000"
      (hide)="toastService.remove(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>

      <button *ngIf="toast.msgDetail" type="button" class="btn btn-link btn-sm" (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
        View Details
      </button>
      <div *ngIf="toast.msgDetail" id="collapseExample" [ngbCollapse]="isCollapsed">
        <div class="card bg-transparent">
          <code class="card-body text-white">{{ toast.msgDetail }}</code>
        </div>
      </div>
    </ngb-toast>
  `,
    host: { '[class.ngb-toasts]': 'true' }
})
export class ToastComponent {
    public isCollapsed = true;
    constructor(public toastService: ToastService) { }

    isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
