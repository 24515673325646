import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { BaseActComponent } from 'app/_controls/base-act.component';
import { GlobalService } from 'app/_core/api-access/global.service';
import { Capacity, FacilityFilter, Language } from 'app/_core/models';
import { ErCity, ErCounty, ErZipcode, ListOfValues } from 'app/_core/models';
import { DISTANCES } from 'app/_core/predefined/consts';
import { GeoType } from 'app/_core/predefined/enums';
import { MiscService } from 'app/_core/utilities/misc.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';

@Component({
  selector: 'app-facility-filter',
  templateUrl: './facility-filter.component.html',
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})

export class FacilityFilterComponent extends BaseActComponent implements OnInit {
  @Input() obj: FacilityFilter;
  @Output() filter = new EventEmitter<FacilityFilter>();
  @Input() globalData: ListOfValues;
  public geoNameAuto: boolean = false;

  // Helper
  public readonly distances = DISTANCES;

  public facilityOptions$: Observable<FacilityFilter>;
  public submitted: boolean = false;
  public capacities: Capacity[] = [
    {
      label: "1 - 6",
      min: 1,
      max: 6,
    },
    {
      label: "7 - 15",
      min: 7,
      max: 15,
    },
    {
      label: "16+",
      min: 16,
      max: null,
    }
  ];

  // Form search
  public readonly geoType = GeoType;
  public isShowLocationSuggestion: boolean = false;

  private locationSubject = new BehaviorSubject<any>(null);
  private locationData: { Counties: ErCounty[], Cities: ErCity[], Zipcodes: ErZipcode[] } = null;
  public readonly location$ = this.locationSubject.asObservable();

  constructor(
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private miscService: MiscService,
  ) {
    super();
  }

  private objMapForm(): FacilityFilter {
    const obj: FacilityFilter = new FacilityFilter();
    obj.GeoName = this.formAct.controls.GeoName.value;

    obj.PriceMin = this.formAct.controls.PriceMin.value ? + this.formAct.controls.PriceMin.value : null;
    obj.PriceMax = this.formAct.controls.PriceMax.value ? + this.formAct.controls.PriceMax.value : null;

    const capacityMin: number = this.formAct.controls.CapacityMin.value;
    if (capacityMin) {
      obj.CapacityMin = capacityMin;
      obj.CapacityMax = this.capacities.find(c => c.min === capacityMin).max;
    }

    obj.RoomTypeId = this.formAct.controls.RoomTypeId.value;
    obj.FacilityName = this.formAct.controls.FacilityName.value;

    obj.GeoType = this.formAct.controls.GeoType.value;
    obj.GeoId = +this.formAct.controls.GeoId.value;

    obj.FacilityTypeId = this.formAct.controls.FacilityTypeId.value;
    obj.Distmi = this.formAct.controls.Distmi.value;

    // Read language from control
    const languages: Language[] = [];
    const languageIds: number[] = this.formAct.controls["languageIds"].value;
    if (languageIds && languageIds.length)
      for (let i = 0; i < this.globalData.Languages.length; i++) {
        const indexLanguageId = languageIds.findIndex(id => id === this.globalData.Languages[i].Id);
        if (indexLanguageId > -1)
          languages.push(this.globalData.Languages[i]);
      }
    obj.Languages = languages;

    return obj;
  }

  // Build form action
  private buildForm() {
    const formGroup: FormGroup = this.formBuilder.group({});
    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.GeoId), new FormControl(null));
    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.GeoType), new FormControl(null));
    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.GeoName), new FormControl(null));

    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.Distmi), new FormControl(null));

    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.PriceMin), new FormControl(null));
    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.PriceMax), new FormControl(null));
    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.CapacityMin), new FormControl(null));
    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.RoomTypeId), new FormControl(null));
    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.FacilityTypeId), new FormControl(null));
    formGroup.addControl(this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.FacilityName), new FormControl(null));

    formGroup.addControl("languageIds", new FormControl(null));

    this.formAct = formGroup;

    const controlFacilityType = this.formAct.get(this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.FacilityTypeId));
    if (!this.obj.FacilityTypeId)
      controlFacilityType.setValidators([]);
    else
      controlFacilityType.setValidators(null);
  }

  ngOnInit(): void {
    this.buildForm();
    this.setFormData(this.obj);

    this.formAct.controls.GeoName.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(term => {
        // console.log("value change");
        this.isShowLocationSuggestion = false;

        // Remove params filter selected
        if (!term || term.trim() === '') {
          this.obj.GeoId = null;
          this.obj.GeoType = null;
          this.obj.GeoName = null;
          this.formAct.controls['GeoId'].setValue(null);
          this.formAct.controls['GeoType'].setValue(null);
          this.formAct.controls['GeoName'].setValue(null);
          return;
        }        
        
        // Call api search geos
        this.globalService.searchGeos(term).pipe(first()).subscribe(result => {
          if (result.Counties || result.Cities || result.Zipcodes)
          {
            this.isShowLocationSuggestion = true;
            this.geoNameAuto = false;
          }

          this.locationData = result;
          this.locationSubject.next(Object.assign({}, this.locationData));
        });
      });
  }


  private setFormData(obj: FacilityFilter) {
    // Stop when form is null
    if (!this.formAct || !obj) return;

    this.formAct.controls[this.miscService.getPropertyName(obj, (o: FacilityFilter) => o.Distmi)].setValue(obj.Distmi ? obj.Distmi : 25);
    this.formAct.controls[this.miscService.getPropertyName(obj, (o: FacilityFilter) => o.FacilityTypeId)].setValue(obj.FacilityTypeId ? obj.FacilityTypeId : null);

    this.formAct.controls[this.miscService.getPropertyName(obj, (o: FacilityFilter) => o.GeoId)].setValue(obj.GeoId);
    this.formAct.controls[this.miscService.getPropertyName(obj, (o: FacilityFilter) => o.GeoType)].setValue(obj.GeoType);
    this.formAct.controls[this.miscService.getPropertyName(obj, (o: FacilityFilter) => o.GeoName)].setValue(obj.GeoName);

    this.formAct.controls[this.miscService.getPropertyName(obj, (o: FacilityFilter) => o.FacilityName)].setValue(obj.FacilityName);
    this.formAct.controls[this.miscService.getPropertyName(obj, (o: FacilityFilter) => o.CapacityMin)].setValue(obj.CapacityMin);

    this.formAct.controls[this.miscService.getPropertyName(obj, (o: FacilityFilter) => o.RoomTypeId)].setValue(obj.RoomTypeId ? obj.RoomTypeId : null);

    // Set value language ids
    const languageIds: number[] = [];
    if (obj.Languages)
      obj.Languages.forEach(l => languageIds.push(l.Id));
    this.formAct.controls["languageIds"].setValue(languageIds);

  }

  resetGeoName(objChanged: any){
    this.formAct.controls['GeoName'].setValue(objChanged.GeoName);
    this.geoNameAuto = true;
  }

  onFilter() {
    if (!this.formAct.valid) return;

    // Keep selected data
    const geoId = this.obj.GeoId;
    const geoType = this.obj.GeoType;
    const geoName = this.obj.GeoName;
    const geoCityId = this.obj.GeoCityId;
    const geoCityName = this.obj.GeoCityName;
    const geoCountyId = this.obj.GeoCountyId;
    const geoCountyName = this.obj.GeoCountyName;
    const geoStateId = this.obj.GeoStateId;
    const geoStateName = this.obj.GeoStateName;

    const objChanged = Object.assign(this.obj, this.objMapForm());
    objChanged.GeoId = geoId;
    objChanged.GeoType = geoType;
    objChanged.GeoName = geoName;
    objChanged.GeoCityId = geoCityId;
    objChanged.GeoCityName = geoCityName;
    objChanged.GeoCountyId = geoCountyId;
    objChanged.GeoCountyName = geoCountyName;
    objChanged.GeoStateId = geoStateId;
    objChanged.GeoStateName = geoStateName;
    // console.log("onfilter" + objChanged.GeoId);
    this.resetGeoName(objChanged);
    this.filter.emit(objChanged);
  }

  onSearchLocations() {
    this.isShowLocationSuggestion = true;
  }

  fillDataForSearch(data: any, geoType: GeoType) {
    //To avoid the valuechanges to go off, when false: no events are emitted
    this.formAct.controls[this.miscService.getPropertyName(this.obj, (o: FacilityFilter) => o.GeoName)].setValue(data.Name, { emitEvent: false });

    this.obj = Object.assign(this.obj, this.objMapForm());

    // Keep selected data
    this.obj.GeoId = data.Id;
    this.obj.GeoType = geoType;
    this.obj.GeoName = data.Name;

    // Set related properties from selected
    if (geoType === this.geoType.County) {
      this.obj.GeoStateId = data.State ? data.State.Id : null;
      this.obj.GeoStateName = data.State ? data.State.Name : "";
    }

    if (geoType === this.geoType.City) {
      this.obj.GeoStateId = data.County ? data.County.State.Id : null;
      this.obj.GeoStateName = data.County ? data.County.State.Name : "";

      this.obj.GeoCountyId = data.County ? data.County.Id : null;
      this.obj.GeoCountyName = data.County ? data.County.Name : "";
    }

    if (geoType === this.geoType.Zip_Code) {
      this.obj.GeoStateId = data.City ? data.City.County.State.Id : null;
      this.obj.GeoStateName = data.City ? data.City.County.State.Name : "";

      this.obj.GeoCountyId = data.City ? data.City.County.Id : null;
      this.obj.GeoCountyName = data.City ? data.City.County.Name : "";

      this.obj.GeoCityId = data.City ? data.City.Id : null;
      this.obj.GeoCityName = data.City ? data.City.Name : "";
    }

    this.isShowLocationSuggestion = false;

    // Clear all data after selected
    this.locationSubject.next(null);
  }

  onFocusGeoName(event: any) {
    this.isShowLocationSuggestion = true;
    this.geoNameAuto = false;
  }

  onFocusOutGeoName(event: any) {
    this.isShowLocationSuggestion = false;
  }
}
